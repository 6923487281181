import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { SolarVendorModel } from "../../rest-api/rest-api.model";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent {
  @Input() vendorInfo: SolarVendorModel;
}
