import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { LearnMoreRequestModel } from "./learn-more.model";
import { DropDownModel } from "../../rest-api/rest-api.model";
import { TimeFrameEnum } from "./learn-more.enum";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import {
  HelpMeChooseModel,
  SolarDashboardUserConsModel,
} from "../overview/model/dashboard.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Injectable()
export class LearnMoreService {
  baseUrl: string;
  detailsAPI: string;
  updateDetailsAPI: string;
  thermostatMnfModel: string;
  helpMeChooseDetails: HelpMeChooseModel;
  roofShadeList: DropDownModel[] = [
    {
      value: "noShade",
      viewValue: "No Shade",
    },
    {
      value: "light",
      viewValue: "Light",
    },
    {
      value: "medium",
      viewValue: "Medium",
    },
    {
      value: "heavy",
      viewValue: "Heavy",
    },
    {
      value: "dontKnow",
      viewValue: "Don't Know",
    },
  ];
  roofSlopeList: DropDownModel[] = [
    {
      value: "flat",
      viewValue: "Flat",
    },
    {
      value: "low",
      viewValue: "Low",
    },
    {
      value: "moderate",
      viewValue: "Moderate",
    },
    {
      value: "steep",
      viewValue: "Steep",
    },
  ];
  financeList: DropDownModel[] = [
    {
      value: "cash",
      viewValue: "Cash",
    },
    {
      value: "loan",
      viewValue: "Loan",
    },
    {
      value: "lease",
      viewValue: "Lease",
    },
  ];
  timeSegmentList: DropDownModel[] = [
    {
      value: TimeFrameEnum.OneMonth,
      viewValue: this.renderTimeFrame(TimeFrameEnum.OneMonth),
    },
    {
      value: TimeFrameEnum.OneYear,
      viewValue: this.renderTimeFrame(TimeFrameEnum.OneYear),
    },
    {
      value: TimeFrameEnum.TenYear,
      viewValue: this.renderTimeFrame(TimeFrameEnum.TenYear),
    },
    {
      value: TimeFrameEnum.TwentyYear,
      viewValue: this.renderTimeFrame(TimeFrameEnum.TwentyYear),
    },
  ];
  sortByList: DropDownModel[] = [
    {
      value: "alpha",
      viewValue: "Alphabetical",
    },
    {
      value: "price_lh",
      viewValue: "Low to High",
    },
    {
      value: "price_hl",
      viewValue: "High to Low",
    },
  ];
  typeOfGas: DropDownModel[] = [
    { value: "Natural gas", viewValue: "Natural gas" },
    { value: "Electric", viewValue: "Standard electric" },
    { value: "Propane", viewValue: "Propane" },
  ];
  hpwhTypes: DropDownModel[] = [
    { value: "Water Heater", viewValue: "Water Heater" },
    { value: "Cooking Range", viewValue: "Cooking range" },
    { value: "Clothes Dryer", viewValue: "Clothes dryer" },
  ];
  hpscType: DropDownModel[] = [
    { value: "Natural gas", viewValue: "Natural gas" },
    { value: "Electric", viewValue: "Standard electric" },
    { value: "Propane", viewValue: "Propane" },
    { value: "Fuel oil", viewValue: "Fuel oil" },
    { value: "Dont know", viewValue: "Don't know" },
  ];
  ducts: DropDownModel[] = [
    { value: "have", viewValue: "Yes" },
    { value: "dontHave", viewValue: "No" },
    { value: "notSure", viewValue: "I'm not sure" },
  ];
  energyChoiceType: DropDownModel[] = [
    { value: "Natural gas", viewValue: "Natural gas" },
    { value: "Electric", viewValue: "Standard electric" },
    { value: "Propane", viewValue: "Propane" },
    { value: "Fuel oil", viewValue: "Fuel oil" },
  ];
  cooktopTypeList: DropDownModel[] = [
    { value: "standard", viewValue: "STANDARD" },
    { value: "portable", viewValue: "PORTABLE" },
  ];
  constructor(
    private httpClient: HttpClient,
    private dashboardAPI: DashboardAPIService,
    private snackBar: MatSnackBar
  ) {
    this.baseUrl = `${this.dashboardAPI.API_END_POINT}`;
    this.detailsAPI = `${this.baseUrl}ho/dashboard/`;
    this.updateDetailsAPI = `${this.baseUrl}ho/dashboard/lmUpdate/`;
  }

  learnMoreInfo<Model>(tech: string, techIndex?: number): Observable<Model> {
    let learnMoreUrl = `${this.detailsAPI}${tech}SavingsDetails/${
      this.dashboardAPI.getUserDetails().userName
    }`;
    if (techIndex) {
      learnMoreUrl = `${learnMoreUrl}?ev=ev${techIndex}`;
    } else if (tech === "ic") {
      learnMoreUrl = `${learnMoreUrl}?type=${this.dashboardAPI.icType}`;
    }
    return this.httpClient.get<Model>(learnMoreUrl);
  }

  updateLearnMoreInfo(
    tech: string,
    request: LearnMoreRequestModel
  ): Observable<LearnMoreRequestModel> {
    return this.httpClient.put<LearnMoreRequestModel>(
      `${this.updateDetailsAPI}${
        this.dashboardAPI.getUserDetails().userName
      }?tech=${tech}`,
      request
    );
  }

  renderTimeFrame(time: TimeFrameEnum): string {
    switch (time) {
      case TimeFrameEnum.TwentyYear:
        return "20 Year";
      case TimeFrameEnum.TenYear:
        return "10 Year";
      case TimeFrameEnum.OneYear:
        return "1 Year";
      case TimeFrameEnum.OneMonth:
        return "1 Month";
      default:
        return time;
    }
  }

  getAgeList(type: string): DropDownModel[] {
    if (type === "Electric") {
      return this.dashboardAPI.electricTypeRange;
    } else {
      return this.dashboardAPI.nonElectricTypeRange;
    }
  }

  updateFavoriteIcon(status: boolean): string {
    return status ? "favorite" : "favorite_border";
  }

  onFavorite(tech: string, query: string, callback: () => void) {
    const url =
      this.dashboardAPI.savingsUrl +
      this.dashboardAPI.userDetails.userName +
      `?timeSpan=${this.dashboardAPI.timeFrame}&${query}=${tech}`;
    return this.httpClient
      .get<SolarDashboardUserConsModel>(url, { observe: "response" })
      .pipe(take(1))
      .subscribe(
        (response: HttpResponse<SolarDashboardUserConsModel>) => {
          if (response.status === 200) {
            if (query === "solar") {
              this.snackBar.open(
                this.dashboardAPI.techMessage(query, tech),
                "OK",
                {
                  duration: 8000,
                }
              );
            } else {
              this.snackBar.open(
                this.dashboardAPI.techMessage(tech, query),
                "OK",
                {
                  duration: 8000,
                }
              );
            }
            callback();
          }
        },
        (error: HttpErrorResponse) => {
          console.error(error);
        }
      );
  }

  isFormEditted(formData, response): boolean {
    return Object.keys(formData).some(
      (data) => formData[data] !== response[data]
    );
  }
}
