import { Component, OnDestroy, OnInit } from "@angular/core";
import { DashboardAPIService } from "src/app/rest-api/dashboard/dashboard-api.service";
import { TechCardInitial } from "../shared/mini-cards/tech-cards/tech-card.init";
import { TechCategoryModel } from "../overview/model/dashboard.init.model";
import { TimeFrameEnum } from "../learn-more/learn-more.enum";
import { Router } from "@angular/router";
import { AnalyticsModel } from "src/app/rest-api/dashboard/dashboard-api.model";
import { AppService } from "src/app/app.service";
import { take } from "rxjs/operators";
import { LifestyleModel } from "../overview/model/solarvendordetails.model";
import { SavingsDetailsResponseType } from "../overview/dashboard-v3/dashboard-v3.types";
import { MarketPlaceService } from "../market-place/market-place.service";
import { OverviewService } from "src/app/homeowner-dashboard/overview/overview.service";

@Component({
  selector: "yt-my-list",
  templateUrl: "my-list.component.html",
  styleUrls: ["./my-list.component.scss"],
})
export class MyListComponent implements OnInit, OnDestroy {
  loader: boolean = true;
  favoritesList: any[] = [];
  alreadyHaveList: any[] = [];
  techCardsList: TechCategoryModel;
  timeSelected: TimeFrameEnum;
  twentyYear: boolean;
  tenYear: boolean;
  oneYear: boolean;
  oneMonth: boolean;
  favoriteLoading: string;
  removeAlreadyHaveTech: string;
  showBlackLoader: boolean;
  selectedCategory: string;
  isSavingsAPIDispatched: boolean;
  alive = true;
  lifeStyleModel: LifestyleModel;

  constructor(
    private overviewService: OverviewService,
    private dashboardAPI: DashboardAPIService,
    private techCards: TechCardInitial,
    private router: Router,
    private appService: AppService,
    private marketplaceService: MarketPlaceService
  ) {
    this.timeSelected = dashboardAPI.timeFrame;
    this.techCardsList = this.techCards.techList;
  }

  ngOnInit(): void {
    this.setTimeSpan(this.timeSelected);
    this.showTechCardLoader();
    this.getSavingsDetails(this.timeSelected);
    this.appService.lifeStyleModel$
      .pipe(take(1))
      .subscribe((data) => (this.lifeStyleModel = data));
  }

  getUserSettingInfo(): void {
    this.dashboardAPI
      .getUserSettingInfo()
      .pipe(take(1))
      .subscribe((response) => {
        this.dashboardAPI.energyChoicesData = response;
        if (response.categoryInfo)
          this.selectedCategory = response.categoryInfo;
      });
  }

  getSavingsDetails(query?: string): void {
    this.isSavingsAPIDispatched = true;
    this.getUserSettingInfo();
    this.dashboardAPI
      .getSavingsDetails(this.timeSelected)
      .pipe(take(1))
      .subscribe(
        (response: SavingsDetailsResponseType) => {
          this.dashboardAPI.removeAlreadyHave.next("");
          this.dashboardAPI.favoriteLoading.next("");
          this.isSavingsAPIDispatched = false;
          this.favoritesList = [];
          this.alreadyHaveList = [];
          Object.keys(this.techCardsList).forEach((categoryName) => {
            Object.keys(this.techCardsList[categoryName]).forEach(
              (techName) => {
                if (categoryName === "simpleLowCost") {
                  if (
                    techName === "everGreen" &&
                    response.simpleLowCost.everGreen
                  ) {
                    const {
                      own,
                      priceAfterIncentives,
                      shopNow,
                      dollarSavings,
                    } = response.simpleLowCost.everGreen.techDetails;
                    if (own === "yes") {
                      this.alreadyHaveList.push({
                        ...this.techCardsList.simpleLowCost.everGreen,
                        ...response.simpleLowCost.everGreen,
                      });
                    } else if (own === "yesPlan") {
                      this.favoritesList.push({
                        ...this.techCardsList.simpleLowCost.everGreen,
                        own,
                        shopNow,
                        dollarSavings,
                        priceAfterIncentives,
                        isNegative: dollarSavings < 0,
                        link: response.simpleLowCost["everGreen"].techDetails
                          .link,
                      });
                    }
                  } else if (response.simpleLowCost[techName]) {
                    const {
                      own,
                      dollarSavings,
                      priceAfterIncentives,
                      guideLink,
                    } = response.simpleLowCost[techName];
                    if (own === "yes") {
                      this.alreadyHaveList.push({
                        ...this.techCardsList.simpleLowCost[techName],
                        ...response.simpleLowCost[techName],
                      });
                    } else if (own === "yesPlan") {
                      this.favoritesList.push({
                        ...this.techCardsList.simpleLowCost[techName],
                        own,
                        priceAfterIncentives,
                        shopNow: response.simpleLowCost[techName].shopNow,
                        link: response.simpleLowCost[techName].link,
                        isNegative: dollarSavings < 0,
                        guideLink,
                        dollarSavings,
                      });
                    }
                  }
                } else if (categoryName === "transportation") {
                  if (
                    (techName === "ev1" || techName === "ev2") &&
                    response.transportation.ev[techName]
                  ) {
                    const { own, dollarSavings, guideLink, shopNow } =
                      response.transportation.ev[techName]?.techDetails;
                    if (own === "yes") {
                      this.alreadyHaveList.push({
                        ...this.techCardsList.transportation[techName],
                        ...response.transportation.ev[techName],
                        techName:
                          response.transportation.ev[techName]?.makeAndModel,
                        isNegative:
                          response.transportation.ev[techName]?.techDetails
                            ?.dollarSavings! < 0,
                        shopNow: shopNow,
                      });
                    } else if (own === "yesPlan") {
                      this.favoritesList.push({
                        ...this.techCardsList.transportation[techName],
                        own,
                        priceAfterIncentives:
                          response.transportation.ev[techName].financial ===
                          "cash"
                            ? response.transportation.ev[techName].techDetails
                                .priceAfterIncentives
                            : response.transportation.ev[techName]
                                .monthlyPayment,
                        techName:
                          response.transportation.ev[techName].makeAndModel,
                        link: response.transportation.ev[techName].techDetails
                          .link,
                        shopNow: shopNow,
                        guideLink: guideLink,
                        isNegative: dollarSavings! < 0,
                        dollarSavings: dollarSavings,
                      });
                    }
                  } else if (response.transportation[techName]) {
                    const { own, shopNow, guideLink, dollarSavings } =
                      response.transportation[techName];
                    if (own === "yes") {
                      this.alreadyHaveList.push({
                        ...this.techCardsList.transportation[techName],
                        ...response.transportation[techName],
                        own,
                        shopNow,
                        isNegative:
                          response.transportation[techName]?.dollarSavings! < 0,
                      });
                    } else if (own === "yesPlan") {
                      this.favoritesList.push({
                        ...this.techCardsList.transportation[techName],
                        own,
                        priceAfterIncentives:
                          response.transportation[techName].financial === "cash"
                            ? response.transportation[techName]
                                .priceAfterIncentives
                            : response.transportation[techName].monthlyPayment,
                        link: response.transportation[techName].link,
                        shopNow,
                        guideLink,
                        isNegative: dollarSavings! < 0,
                        dollarSavings: dollarSavings,
                      });
                    }
                  }
                } else if (categoryName === "appliances") {
                  if (techName === "ic" && response.appliances.ic) {
                    this.dashboardAPI.icType =
                      response.appliances.ic?.inductionCooktopType;
                    this.dashboardAPI.getICInfo(
                      response.appliances.ic?.inductionCooktopType
                    );
                    const {
                      own,
                      dollarSavings,
                      priceAfterIncentives,
                      guideLink,
                    } = response.appliances.ic.techDetails;
                    if (own === "yes") {
                      this.alreadyHaveList.push({
                        ...this.techCardsList.appliances.ic,
                        ...response.appliances.ic,
                        own,
                        shopNow: response.appliances.ic.techDetails.shopNow,
                        img: this.dashboardAPI.icInfo.image,
                        link: response.appliances.ic.techDetails.link,
                        techName: this.dashboardAPI.icInfo.title,
                        isNegative: dollarSavings < 0,
                        techParameter:
                          this.dashboardAPI.icType === "portable"
                            ? "portableIC"
                            : "ic",
                      });
                    } else if (own === "yesPlan") {
                      this.favoritesList.push({
                        ...this.techCardsList.appliances.ic,
                        own,
                        priceAfterIncentives,
                        shopNow: response.appliances.ic.techDetails.shopNow,
                        link: response.appliances.ic.techDetails.link,
                        isNegative: dollarSavings < 0,
                        dollarSavings: dollarSavings,
                        guideLink,
                        img: this.dashboardAPI.icInfo.image,
                        techName: this.dashboardAPI.icInfo.title,
                        techParameter:
                          this.dashboardAPI.icType === "portable"
                            ? "portableIC"
                            : "ic",
                      });
                    }
                  } else if (response.appliances[techName]) {
                    const { own, shopNow, dollarSavings, guideLink } =
                      response.appliances[techName];
                    if (own === "yes") {
                      this.alreadyHaveList.push({
                        ...this.techCardsList.appliances[techName],
                        ...response.appliances[techName],
                      });
                    } else if (own === "yesPlan") {
                      this.favoritesList.push({
                        ...this.techCardsList.appliances[techName],
                        own,
                        shopNow,
                        guideLink,
                        dollarSavings,
                        priceAfterIncentives:
                          response.appliances[techName].financial === "cash"
                            ? response.appliances[techName].priceAfterIncentives
                            : response.appliances[techName].monthlyPayment,
                        link: response.appliances[techName].link,
                        isNegative: dollarSavings! < 0,
                      });
                    }
                  }
                } else if (
                  categoryName === "solarBattery" &&
                  response.solarBattery[techName]
                ) {
                  const {
                    own,
                    dollarSavings,
                    shopNow,
                    guideLink,
                    priceAfterIncentives,
                  } = response.solarBattery[techName].techDetails;
                  if (own === "yes") {
                    this.alreadyHaveList.push({
                      ...this.techCardsList.solarBattery[techName],
                      ...response.solarBattery[techName].techDetails,
                    });
                  } else if (own === "yesPlan") {
                    this.favoritesList.push({
                      ...this.techCardsList.solarBattery[techName],
                      own,
                      shopNow,
                      dollarSavings,
                      priceAfterIncentives,
                      guideLink,
                      isNegative: dollarSavings < 0,
                      link: response.solarBattery[techName].techDetails.link,
                    });
                  }
                }
              }
            );
          });
          this.loader = false;
        },
        () => {
          this.dashboardAPI.removeAlreadyHave.next("");
          this.dashboardAPI.favoriteLoading.next("");
          this.loader = false;
          this.isSavingsAPIDispatched = false;
        }
      );
  }

  showTechCardLoader(): void {
    this.dashboardAPI.removeAlreadyHave.pipe(take(1)).subscribe((response) => {
      this.showBlackLoader = !!response;
      this.removeAlreadyHaveTech = response;
    });
    this.dashboardAPI.favoriteLoading.pipe(take(1)).subscribe((response) => {
      this.showBlackLoader = !!response;
      this.favoriteLoading = response;
    });
  }

  onRemove(remove_tech: string): void {
    this.loader = true;
    this.showBlackLoader = true;
    this.dashboardAPI.removeAlreadyHave.next(remove_tech);
    this.dashboardAPI.favoriteLoading.next(remove_tech);
    this.overviewService
      .updateTechSettingInfo(remove_tech, "noPlan")
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.getSavingsDetails();
        }
      });
  }

  onShopNow(techParameter: string, link: string, isShopAgain?: boolean): void {
    if (link) {
      const techName = techParameter === "hpsc" ? "ashp" : techParameter;
      this.updateAnalytics(
        isShopAgain ? "alreadyHaveAnalytics" : "favouriteAnalytics",
        "shopNow",
        techName
      );
      this.marketplaceService.openShopNowTab(link);
    } else {
      const marketplaceRoute = "dashboard/market-place/";
      const homeAppliances = ["hpd", "hpsc", "hpwh"];
      const solarBattery = ["battery", "communitySolar", "solar"];
      const energyEfficientDevices = [
        "ledCflBulbs",
        "smartThermostat",
        "weatherStripping",
        "smartPowerStrips",
        "showerHeadsAerators",
      ];
      const transportationTech = ["ev", "eBike", "evChargers"];
      let techName = "";
      if (techParameter === "ev1" || techParameter === "ev2") {
        techName = "ev";
      } else if (techParameter === "hpsc") {
        techName = "ashp";
      } else {
        techName = techParameter;
      }
      this.updateAnalytics(
        isShopAgain ? "alreadyHaveAnalytics" : "favouriteAnalytics",
        "shopNow",
        techName
      );
      if (homeAppliances.includes(techParameter)) {
        this.router.navigateByUrl(
          `${marketplaceRoute}homeAppliances/${techName}`
        );
      } else if (energyEfficientDevices.includes(techParameter)) {
        if (
          techParameter === "ledCflBulbs" ||
          techParameter === "smartPowerStrips"
        ) {
          this.router.navigateByUrl(
            `${marketplaceRoute}energyEfficientDevices/lightingElectricDevices`
          );
        } else if (
          techParameter === "smartThermostat" ||
          techParameter === "weatherStripping"
        ) {
          this.router.navigateByUrl(
            `${marketplaceRoute}energyEfficientDevices/homeComfortDevices`
          );
        } else if (techParameter === "showerHeadsAerators") {
          this.router.navigateByUrl(
            `${marketplaceRoute}energyEfficientDevices/waterSavingDevices`
          );
        }
      } else if (transportationTech.includes(techName)) {
        this.router.navigateByUrl(
          `${marketplaceRoute}transportationAndAccessories/${techName}`
        );
      } else if (solarBattery.includes(techParameter)) {
        this.router.navigateByUrl(`${marketplaceRoute}solarBattery`);
      }

      if (techParameter === "portableBattery") {
        this.router.navigateByUrl(
          `${marketplaceRoute}homeAppliances/portableBattery`
        );
      } else if (techParameter === "ic") {
        this.router.navigateByUrl(`${marketplaceRoute}homeAppliances/ic`);
      } else if (techParameter === "portableIC") {
        this.router.navigateByUrl(
          `${marketplaceRoute}homeAppliances/portableIC`
        );
      }
    }
  }

  openGuidanceLink(link: string, techName: string): void {
    if (techName === "ev1" || techName === "ev2") {
      techName = "ev";
    } else if (techName === "hpsc") {
      techName = "ashp";
    } else {
      techName = techName;
    }
    this.updateAnalytics("favouriteAnalytics", "viewBuyingGuide", techName);
    this.marketplaceService.openShopNowTab(link);
  }

  setTimeSpan(span: string) {
    this.twentyYear = span === "twentyYear";
    this.tenYear = span === "tenYear";
    this.oneYear = span === "oneYear";
    this.oneMonth = span === "oneMonth";
  }

  updateAnalytics(analytics: string, detail: string, tech: string): void {
    const details: AnalyticsModel = {
      [analytics]: {
        detail,
        tech,
      },
    };
    this.dashboardAPI.getAnalyticsAPI(details).pipe(take(1)).subscribe();
  }

  onAddTech(): void {
    this.router.navigateByUrl("/dashboard/overview");
  }

  ngOnDestroy(): void {}
}
