import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Output } from "@angular/core";
import { ReferFriendDTO, ReferFriendEmailsModel } from "./refer-friend.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-refer-friend",
  templateUrl: "refer-friend.component.html",
  styleUrls: ["./refer-friend.component.scss"],
})
export class ReferFriendComponent implements OnInit {
  removable: boolean = true;
  addOnBlur: boolean = true;
  visible: boolean = true;
  selectable: boolean = true;
  isMailListValid: boolean = true;
  separatorKeysCodes = [ENTER, COMMA];
  friendMails: ReferFriendEmailsModel[] = [];
  referFriendForm: FormGroup;
  @Input() referFriendPopup: boolean;
  @Input() referAFriendText: string;
  @Output("friendMail") friendMail: EventEmitter<any> = new EventEmitter();
  @ViewChild("referFriendOverlay", { read: ElementRef }) modal: ElementRef;
  mailPattern: RegExp =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private fb: FormBuilder,
    private restAPIService: DashboardAPIService,
    public snackBar: MatSnackBar
  ) {
    this.referFriendForm = this.fb.group({
      referEmail: ["", [Validators.pattern(this.mailPattern)]],
    });
  }

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key == "Escape") {
      this.referFriendPopup = false;
      this.friendMail.emit(this.referFriendPopup);
    }
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event) {
    if (this.modal && this.referFriendPopup) {
      if (event.target === this.modal.nativeElement) {
        this.onClose();
      }
    }
  }

  ngOnInit(): void {}

  get friendsMails(): ReferFriendDTO {
    const mails = this.friendMails.map(
      (mails: ReferFriendEmailsModel) => mails.mail
    );
    return {
      toList: mails,
    };
  }

  onMailAdd(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;
    let isMailValid = this.mailPattern.test(value);
    if ((value || "").trim() && isMailValid) {
      this.friendMails.push({ mail: value.trim(), validStatus: isMailValid });
    }
    if (input && isMailValid) {
      input.value = "";
    }
    input.focus();
  }

  onMailRemove(userMail: ReferFriendEmailsModel): void {
    let index = this.friendMails.indexOf(userMail);
    if (index >= 0) {
      this.friendMails.splice(index, 1);
    }
  }

  onClose(): void {
    this.friendMails.splice(0, this.friendMails.length);
    this.referFriendPopup = false;
    this.friendMail.emit(this.referFriendPopup);
  }

  onSubmit(): void {
    this.restAPIService
      .referFriendsAPI(this.friendsMails)
      .pipe(take(1))
      .subscribe(() => {
        this.friendMails.splice(0, this.friendMails.length);
        this.snackBar.open(
          "Thanks for referring the platform to your friend(s).",
          "OK",
          {
            duration: 8000,
          }
        );
      });
  }

  get disableSend(): boolean {
    return (
      this.referFriendForm.get("referEmail")?.hasError("pattern") ||
      !this.isMailListValid ||
      this.friendMails.length < 1
    );
  }

  get invalidEmail(): boolean {
    return (
      this.referFriendForm.get("referEmail")?.hasError("pattern") ||
      !this.isMailListValid
    );
  }
}
