<div class="padding-top">
  <div class="data_box">
    <div class="top_section">
      <div
        class="main_value"
        *ngIf="!electricAuthorizedStatus && !gasNotAuthorizedStatus"
      >
        {{ totalBill | number }} lbs
        <div class="main_value_label">Total CO2 Emissions</div>
      </div>
      <div
        class="breakdown_text"
        *ngIf="electricAuthorizedStatus || gasNotAuthorizedStatus"
      >
        CO2 Emissions Breakdown
      </div>
    </div>
    <div class="bottom_section">
      <div class="left_values">
        <div class="sub_value" *ngIf="!electricAuthorizedStatus">
          {{ annualElectricEmission | number }} lbs
        </div>
        <div class="sub_value" *ngIf="electricAuthorizedStatus">
          {{ annualElectricEmission }}
        </div>
        <div class="sub_value_label">Electricity</div>
      </div>
      <div class="right_values">
        <div class="sub_value" *ngIf="!gasNotAuthorizedStatus">
          {{ annualGasEmission | number }} lbs
        </div>
        <div class="sub_value" *ngIf="gasNotAuthorizedStatus">
          {{ annualGasEmission }}
        </div>
        <div class="sub_value_label">Natural Gas</div>
      </div>
    </div>
  </div>

  <div class="chart_section">
    <div class="monthly_text">Monthly CO2 Emissions</div>
    <div class="chart_box">
      <div class="chart_toggle_buttons yt__energy_usage_common">
        <mat-button-toggle-group
          [value]="selected"
          (change)="getCarbonOverview($event.value)"
        >
          <mat-button-toggle (click)="onElectricity()" value="Electricity">
            <span class="toggle_label">ELECTRICITY</span>
          </mat-button-toggle>
          <mat-button-toggle value="Gas" class="label_margin" (click)="onGas()">
            <span class="toggle_label">NATURAL GAS</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="high_chart" *ngIf="showElectricityChart || showGasChart">
        <div class="height" #container></div>
      </div>
      <div
        class="high_chart"
        style="padding-bottom: 40px"
        *ngIf="electricity && showElectricityChartError && !authorizedStatus"
      >
        <div class="paragraph_text">
          We have not received your electricity data from the utility provider.
          This is because you have only authorized your gas but not electricity
          data. If you wish to authorize your electricity data, please
          <span class="pointer link_text" (click)="onEnergyUsageSetting()"
            >click here</span
          >.
        </div>
      </div>
      <div
        class="high_chart"
        style="padding-bottom: 40px"
        *ngIf="electricity && showElectricityChartError && authorizedStatus"
      >
        <div class="paragraph_text">
          There are no carbon emissions due to your electricity usage. This is
          because you receive carbon-free electricity from your community
          electricity provider.
        </div>
      </div>
      <div
        class="high_chart"
        style="padding-bottom: 40px"
        *ngIf="gas && showGasChartError"
      >
        <div class="paragraph_text">
          <div>
            We have not received the gas data from the utility provider. This
            might happen due to any one of the following reasons:
          </div>
          <div>
            1) You have only authorized your electricity but not gas data. If
            you wish to authorize your gas data, please
            <span class="pointer link_text" (click)="onEnergyUsageSetting()"
              >click here</span
            >.
          </div>
          <div>
            2) Gas service is not available at your home through the utility
            provider.
          </div>
          <div>
            3) You have an All-Electric home and so do not have gas service.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="descriptionMessage" *ngIf="showElectricityChart || showGasChart">
    The results are based on your utility provided data and are rounded to the
    nearest decimal.
  </div>
</div>

<div id="overlay" (click)="offPopUp()">
  <div id="text">
    <div class="bg-popup-white">
      <div class="yt_popup-header yt_popup-padding">Energy Authorization</div>
      <div class="yt_popup-padding">
        <p class="yt_info">
          We have not received this information from the utility provider. This
          might happen due to any one of the following reasons:
        </p>
        <div class="yt_paragraph">
          <p class="yt_info">1) Energy data is not authorized.</p>
          <p class="yt_info">2) Complete authorization is not provided.</p>
          <p class="yt_info">3) This service is not available at your home.</p>
        </div>
        <p class="yt_paragraph">
          If you wish to authorize your energy data, please
          <span class="pointer yt_color" (click)="onEnergyUsageSetting()"
            >click here</span
          >.
        </p>
      </div>
    </div>
  </div>
</div>

<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>
