import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SolarVendorModel } from "../../../rest-api/rest-api.model";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import {
  ElectricityProvidersModel,
  HPJourneyDetailsPUTModel,
} from "../../homeowner-info.model";
import { HomeOwnerInfoService } from "../../homeowner-info.service";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-utility-provider",
  templateUrl: "./utility-provider.component.html",
  styleUrls: ["../component-common.scss"],
})
export class UtilityProviderComponent implements OnInit {
  @Input() vendorInfo: SolarVendorModel;
  cceFullForm: string;
  cce: string;
  cceTooltip: string;
  utilityName: string;
  utilityId: number;
  utilityGbc: string;
  utilityForm: FormGroup;
  isUtilitySelected = false;
  cceOptOut: boolean = false;
  isDisabledButton: boolean = false;
  utilityProvidersList: ElectricityProvidersModel[];
  authorizationSection: boolean;
  authorizationSectionPGE: boolean = false;
  authorizeEnergyUsageUrl: string;
  selectedProviderId: string;
  @Output() goalInfo: EventEmitter<null> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private homeOwnerInfoService: HomeOwnerInfoService,
    private restAPI: RestAPIService
  ) {
    this.utilityForm = this.fb.group({
      utilityId: [""],
      avgMonthlyBill: [
        "95",
        [
          Validators.required,
          Validators.pattern("^([1-9][0-9]|[0-5][0-9][0-9])$"),
          Validators.min(35),
          Validators.max(500),
        ],
      ],
      checkCceOptOut: [false],
    });
  }

  ngOnInit(): void {
    this.utilityProvidersList = this.homeOwnerInfoService.energyProviderList;
    if (this.utilityProvidersList.length === 1) {
      this.utilityId = this.utilityProvidersList[0].utilityId;
      this.utilityName = this.utilityProvidersList[0].utilityName;
      this.utilityForm.controls["utilityId"].setValue(
        this.utilityProvidersList[0]
      );
      this.onCceOptOut();
    }
    if (this.homeOwnerInfoService.selectedProviderId) {
      this.utilityForm.controls["utilityId"].setValue(
        this.utilityProvidersList.find(
          (provider) =>
            provider.utilityId === this.homeOwnerInfoService.selectedProviderId
        )
      );
    }

    const energyInfo = this.homeOwnerInfoService.userInfo?.energyInfo;
    if (energyInfo?.avgMonthlyBill)
      this.utilityForm.controls["avgMonthlyBill"].setValue(
        energyInfo.avgMonthlyBill
      );
  }

  onChangeOfUtilityId(utilityProvider: ElectricityProvidersModel): void {
    if (utilityProvider) {
      this.utilityGbc = utilityProvider.gbc;
      this.utilityId = utilityProvider.utilityId;
      this.utilityName = utilityProvider.utilityName;
      this.homeOwnerInfoService.selectedProviderId = this.utilityId;
      this.homeOwnerInfoService.selectedProviderName = this.utilityName;
      this.onCceOptOut();
    } else {
      this.utilityGbc = "No";
      this.cceOptOut = false;
    }
    this.isDisabledButton =
      this.utilityForm.get("utilityId")?.invalid || !utilityProvider;
  }

  onCceOptOut(): void {
    this.homeOwnerInfoService
      .getProviderInfo(this.utilityId)
      .pipe(take(1))
      .subscribe((response) => {
        this.cceFullForm = response.ccefullForm;
        this.cce = response.cce;
        this.cceTooltip = `In your area, you can either choose ${this.cceFullForm} or ${this.utilityName} as your electricity provider.
            Checking this box indicates that you have opted for ${this.utilityName} as your provider.`;
        if (this.cce === "NA" || this.cceFullForm === "NONE") {
          this.cceOptOut = false;
        } else {
          this.cceOptOut = true;
        }
      });
  }

  onSubmit(onContinue: boolean): void {
    const button = document.querySelector<HTMLElement>("#loaderButton");
    if (onContinue && button)
      button.innerHTML =
        'Submitting... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
    let energyProviderDetails: HPJourneyDetailsPUTModel = {
      energyInfo: {
        utilityProviderInfo: {
          cceOptOut: this.utilityForm.get("checkCceOptOut")?.value,
          elecUtilityProviderId: this.homeOwnerInfoService.selectedProviderId,
          elecUtilityProviderName:
            this.homeOwnerInfoService.selectedProviderName,
        },
        avgMonthlyBill: this.utilityForm.get("avgMonthlyBill")?.value
          ? this.utilityForm.controls["avgMonthlyBill"].value
          : null,
        onContinue,
      },
    };
    if (this.utilityForm.get("utilityId")?.value) {
      this.postUserDetails(energyProviderDetails);
    }
  }

  postUserDetails(
    providerDetails: HPJourneyDetailsPUTModel,
    button?: HTMLElement
  ): void {
    this.homeOwnerInfoService
      .postUserDetails(providerDetails)
      .pipe(take(1))
      .subscribe(
        (response: HPJourneyDetailsPUTModel) => {
          this.homeOwnerInfoService.userInfo = {
            ...this.homeOwnerInfoService.userInfo,
            energyInfo: providerDetails.energyInfo,
          };
          this.homeOwnerInfoService.checkAutoLogin();
        },
        (error) => {
          if (this.utilityForm.get("utilityId")?.value) {
            this.restAPI.showErrorMessage(error.error.message);
            if (error.status === 409 && button) {
              button.innerHTML = "SUBMIT";
            }
          }
        }
      );
  }

  isFieldValid(field: string, required: boolean): boolean {
    const isEmpty = this.utilityForm.controls[field].value === "";
    if (required) return isEmpty && this.utilityForm.controls[field].touched;
    return isEmpty;
  }

  isButtonDisabled(): boolean {
    const avgMonthlyBill = this.utilityForm.controls["avgMonthlyBill"];
    const isAvgMonthlyBillInvalid =
      this.isFieldValid("avgMonthlyBill", false) ||
      avgMonthlyBill.hasError("pattern") ||
      avgMonthlyBill.hasError("min") ||
      avgMonthlyBill.hasError("max");
    return isAvgMonthlyBillInvalid || this.isFieldValid("utilityId", false);
  }
}
