<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">Community Solar</div>
      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isSavings]="true"
        [isDescription]="false"
        [isSettings]="false"
        [isDetails]="true"
        [isAssumptions]="!!assumptionDetails"
        [isFaq]="true"
        [techParameter]="'communitySolar'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>
      <div>
        <div
          class="yt-text"
          [innerHTML]="
            'homeOwenerDashboard.learnMore.communitySolar.paragraph' | translate
          "
        ></div>
        <div *ngIf="!isLangSpanish" id="aboutTechnology">
          <yt-community-solar-faq
            [showAboutTech]="true"
          ></yt-community-solar-faq>
        </div>
        <div class="faq_mobile">
          <button
            class="favorite_btn"
            mat-raised-button
            color="accent"
            (click)="onFavorite()"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
      </div>

      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="assets/lifestyle/Community Solar.png"
              alt="technology icon"
              loading="lazy"
              [ngStyle]="{ height: !displayMarketPlaceBox ? '160px' : '250px' }"
            />
          </div>
          <button
            (click)="onFavorite()"
            class="favorite_btn mt_20"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button mt_20"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
        <div class="width-box" id="cost" *ngIf="financialDetails">
          <yt-cost-rendering
            [financialDetails]="financialDetails.csCost"
            [paymentTypeLabel]="'upfront cost'"
            [isEvergreen]="true"
          >
          </yt-cost-rendering>

          <yt-market-place
            [tech_name]="'communitySolar'"
            [marketPlaceDetails]="marketPlaceDetails"
            *ngIf="marketPlaceDetails && displayMarketPlaceBox"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'communitySolar'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div class="bottom_section w-100">
        <div class="left_section">
          <div class="highChart_card highChart_card_mobile">
            <div class="details_header pb_14">savings</div>
            <div class="w-100 toggle_buttons">
              <mat-button-toggle-group
                [(value)]="selected"
                (change)="updateChart($event.value)"
              >
                <mat-button-toggle
                  *ngFor="let time of timeSegmentList"
                  [value]="time.value"
                >
                  <span class="toggle_label">
                    {{ time.viewValue?.toUpperCase() }}
                  </span>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="While you can go green by signing up for Community Solar, there's no discount in your area."
                >energy savings</span
              >
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span class="savings_value">{{
                communitySolarSavings | currency : "$" : "symbol" : "1.0-0"
              }}</span>
            </div>
            <div class="w-100 chart_section">
              <div class="mobile_chart_text">
                To view and compare graphs of energy costs, please login to your
                dashboard using a desktop.
              </div>
            </div>
          </div>

          <yt-assumption-card
            [assumptionDetails]="assumptionDetails"
          ></yt-assumption-card>
        </div>

        <div class="right_section">
          <div class="highChart_card" id="savings">
            <div class="details_header pb_14">savings</div>
            <div class="w-100 toggle_buttons">
              <mat-button-toggle-group
                [(value)]="selected"
                (change)="updateChart($event.value)"
              >
                <mat-button-toggle
                  *ngFor="let time of timeSegmentList"
                  [value]="time.value"
                >
                  <span class="toggle_label">
                    {{ time.viewValue?.toUpperCase() }}
                  </span>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="While you can go green by signing up for Community Solar, there's no discount in your area."
                >energy savings</span
              >
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span class="savings_value">{{
                communitySolarSavings | currency : "$" : "symbol" : "1.0-0"
              }}</span>
            </div>
            <div class="formula_text">
              Energy savings &nbsp; = &nbsp; Electricity cost without community
              solar - Electricity cost with community solar
            </div>
            <div class="w-100 chart_section">
              <div class="hide_chart">
                <div class="chart" #container></div>
              </div>
            </div>
            <div class="w-100 chart_description_text">
              Savings estimate is based on the typical bill credit discount in
              your area. Community solar is generally sized to cover about 90%
              of your current annual electricity bill.
            </div>
          </div>

          <div class="summary_card" id="details">
            <mat-expansion-panel
              (closed)="isTechnicalExpanded = false"
              [expanded]="isTechnicalExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="expansionPanelClick()">
                <mat-panel-title class="flex items-center">
                  Technical Details &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="solar_panel_body w-100" style="display: block">
                <div
                  class="solar_panel_body_row"
                  *ngIf="communitySolarViewDetails"
                >
                  <div class="left_side_values">
                    <div class="label_heading w-100">Summary</div>
                    <div class="left_label_content_box">
                      <div class="label_content_row">
                        <p>Current average monthly electric bill</p>
                        <span>
                          ${{
                            technicalDetails.csSummary.avgMonthlyElectricBill
                              | number
                          }}
                        </span>
                      </div>
                      <div class="label_content_row row_paddingtop">
                        <p>Typical bill credit discount</p>
                        <span
                          >{{
                            technicalDetails.csSummary.discount | number
                          }}%</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

          <div class="faq_card" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="basicInfoPanelClick()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon1 ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-community-solar-faq
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-community-solar-faq>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div
        class="link_text back_to_top"
        (click)="scrollToTop()"
        *ngIf="!isLangSpanish"
      >
        <mat-icon class="pointer mat_color"> keyboard_arrow_up </mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<!-- loader -->
<yt-loader
  [blackLoader]="showBlackLoader"
  *ngIf="loader || showBlackLoader"
  top="0"
  [relative]="true"
></yt-loader>
