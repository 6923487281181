import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { MarketPlaceDetailsModel } from "../learn-more.model";
import { LearnMoreService } from "../learn-more.service";
import {
  LEDBulbAssumptionsModel,
  LEDBulbDetailsModel,
  LEDBulbFinancialModel,
  LEDBulbLearnMoreModel,
  LEDBulbTechnicalModel,
} from "./led-details.model";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { AppService } from "../../../app.service";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-led-bulbs",
  templateUrl: "./led-bulbs.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class LEDBulbsComponent implements OnInit, AfterContentChecked {
  loader: boolean = true;
  showCash: boolean = true;
  icon: boolean = false;
  showIncentiveBreakdown = false;
  timeSegmentList: DropDownModel[];
  marketplaceDetails: MarketPlaceDetailsModel;
  displayMarketPlace: boolean;
  marketPlaceLink: string;
  linkId: string;
  readMore: boolean = true;
  readLess: boolean = false;
  vendorName: string;
  totalIncentives: number;
  incentiveStatus: boolean;
  LEDViewDetails: LEDBulbDetailsModel;
  ledDescription: string;
  led_text: string;
  adviceCardStatus: boolean;
  assumptionDetails: LEDBulbAssumptionsModel;
  financialDetails: LEDBulbFinancialModel;
  technicalDetails: LEDBulbTechnicalModel;
  activeIndex: number = 0;
  tabLabel: string;
  tabIndex: number = 0;
  isLangSpanish: boolean;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  isFAQExpanded: boolean;
  favStatus: boolean;
  favIcon: string;
  learnMoreDetails: LEDBulbLearnMoreModel;
  showBlackLoader: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private learnMoreService: LearnMoreService,
    private dashboardService: DashboardAPIService,
    private cdRef: ChangeDetectorRef,
    private appService: AppService
  ) {
    this.timeSegmentList = this.learnMoreService.timeSegmentList;
  }

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.vendorName = this.dashboardService.getUserDetails().solarVendorCode;
    this.dashboardService
      .faqDetails()
      .pipe(take(1))
      .subscribe((list: SectionListModel) => {
        this.FAQTabsList = list.ledBulbs.faqList!;
      });
    this.marketplaceAPI();
    this.getLEDBulbDetails();
  }

  getLEDBulbDetails(): void {
    this.learnMoreService
      .learnMoreInfo<LEDBulbDetailsModel>("ledCflBulbs")
      .pipe(take(1))
      .subscribe((response: LEDBulbDetailsModel) => {
        this.LEDViewDetails = response;
        this.incentiveStatus =
          response.financialDetails.costDetails.showIncentive;
        this.assumptionDetails = response.assumptionDetails;
        this.financialDetails = response.financialDetails;
        this.totalIncentives = response.financialDetails.costDetails.incentives;
        this.technicalDetails = response.technicalDetails;
        this.ledDescription = this.technicalDetails.ledCflBulbsMoreDetails[0];
        this.favStatus = this.LEDViewDetails.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.showBlackLoader = this.loader = false;
      });
  }

  handleActiveValue(data: string): void {
    if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon = true;
    }
  }

  faqExpansionClick(): void {
    this.icon = !this.icon;
  }

  onReadContent(value: string): void {
    if (value === "readMore") {
      this.readMore = false;
      this.readLess = true;
      this.ledDescription += this.technicalDetails.ledCflBulbsMoreDetails[1];
    } else if (value === "readLess") {
      this.readMore = true;
      this.readLess = false;
      this.ledDescription = this.technicalDetails.ledCflBulbsMoreDetails[0];
    }
  }

  marketplaceAPI(): void {
    this.dashboardService
      .getMarketplaceDetails("ledCflBulbs")
      .pipe(take(1))
      .subscribe((data: MarketPlaceDetailsModel) => {
        this.marketplaceDetails = data;
        this.adviceCardStatus = data.expertAdvice;
        this.displayMarketPlace = this.marketplaceDetails.displayBox;
        this.marketPlaceLink = this.marketplaceDetails.buttonLink;
        this.linkId = this.marketplaceDetails.linkId;
        if (this.marketplaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketplaceDetails.linkId;
        }
      });
  }

  handleFAQClick(): void {
    this.dashboardService.dashboardDetailsAnalytics("moreInfo", "ledCflBulbs");
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/led-bulb-faq-es/",
      "_blank"
    );
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo("ledCflBulbs", ownPlan)
      .pipe(take(1))
      .subscribe(() => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getLEDBulbDetails();

        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardService.techMessage("ledCflBulbs", action);
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }
}
