<div *ngIf="!isEvergreen" class="main_values_box">
  <div class="details_header">Cost</div>
  <div class="top_values">
    <div *ngIf="showCash">
      <div class="main_value_text">
        ${{
          financialDetails.netCost || financialDetails.priceAfterIncentives
            | number
        }}
        <div class="value_text">
          <span
            [ngClass]="{
              'tooltip_text pointer dashed-underline':
                paymentTypeLabel === 'Net cost'
            }"
            [matTooltip]="
              paymentTypeLabel === 'Net cost' ? netCostTooltipText : ''
            "
          >
            {{ paymentTypeLabel || "Price after incentives" }}
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="showLease && !showLoan">
      <div class="main_value_text">
        ${{ financialDetails.monthlyPayment | number }}
        <div class="value_text">
          <span class="dashed-underline">
            {{ paymentTypeLabel || "Monthly Lease Payment" }}
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="showLoan">
      <div class="main_value_text">
        ${{ financialDetails.monthlyPayment | number }}
        <div class="value_text">
          <span class="dashed-underline">
            {{ paymentTypeLabel || "Monthly Loan Payment" }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isEv" class="bottom_values">
    <div class="left_bottom_value">
      <div *ngIf="showCash">
        <div class="value">
          ${{ financialDetails.upfrontCost || financialDetails.msrp | number }}
        </div>
        <div class="value_label">
          <span
            class="tooltip_text pointer dashed-underline"
            [matTooltip]="
              show_Upfront_price ? upfrontCostTooltipText : priceTooltipText
            "
          >
            {{ show_Upfront_price ? "Upfront cost" : "Price" }}
          </span>
        </div>
      </div>

      <div *ngIf="showLease && !showLoan">
        <div class="value">${{ financialDetails.downPayment | number }}</div>
        <div class="value_label dashed-underline">Down payment</div>
      </div>

      <div *ngIf="showLoan">
        <div class="value">${{ financialDetails.downPayment | number }}</div>
        <div class="value_label dashed-underline">Down payment</div>
      </div>
    </div>

    <div class="right_bottom_value">
      <div class="value">${{ financialDetails.incentives | number }}</div>
      <div class="value_label">
        <mat-icon *ngIf="!lease" class="hidden_tooltip">help_outline</mat-icon>
        <span
          *ngIf="showIncentivesStatus"
          class="link_text pointer"
          (click)="toggleIncentiveBreakdown()"
        >
          Incentives
        </span>
        <span *ngIf="!showIncentivesStatus" class="link_text1">Incentives</span>
        <mat-icon
          *ngIf="!lease"
          class="tooltip_icon"
          matTooltip="Available tax credits and rebates."
        >
          help_outline
        </mat-icon>
      </div>
    </div>
  </div>

  <div *ngIf="isEv" class="bottom_values">
    <div class="left_bottom_value_2">
      <div *ngIf="showLoan && showPrice">
        <div class="value">${{ financialDetails.msrp | number }}</div>
        <div class="value_label">
          <span
            class="tooltip_text dashed-underline"
            [matTooltip]="showPrice ? priceTooltipText : upfrontCostTooltipText"
          >
            Price
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="showLoan" class="middle_bottom_value">
      <div class="value">${{ financialDetails.downPayment | number }}</div>
      <div class="value_label dashed-underline">Down payment</div>
    </div>

    <div class="right_bottom_value_2">
      <div class="value">${{ financialDetails.incentives | number }}</div>
      <div class="value_label">
        <mat-icon *ngIf="!lease" class="hidden_tooltip">help_outline</mat-icon>
        <span
          *ngIf="showIncentivesStatus"
          class="link_text pointer"
          (click)="toggleIncentiveBreakdown()"
        >
          Incentives
        </span>
        <span *ngIf="!showIncentivesStatus" class="link_text1">Incentives</span>
        <mat-icon
          *ngIf="!lease"
          class="tooltip_icon"
          matTooltip="Available tax credits and rebates."
        >
          help_outline
        </mat-icon>
      </div>
    </div>
  </div>

  <yt-breakdown-popup
    *ngIf="showIncentiveBreakdown"
    [techName]="techName"
    [heading]="heading"
    [netCost]="netCost"
    (closeIncentiveBreakdown)="closeBreakdownPopup()"
  >
  </yt-breakdown-popup>
</div>

<div *ngIf="isEvergreen" class="main_values_box evergreen_card">
  <div class="details_header">Cost</div>
  <div class="top_values">
    <div class="main_value_text">
      ${{ financialDetails.upfrontCost | number }}
      <div class="value_text">Upfront Cost</div>
    </div>
  </div>
</div>
