import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterContentChecked,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as Highcharts from "highcharts";
import { TimeFrameEnum } from "../learn-more.enum";
import {
  HPDDetailsModel,
  HPDFinancialModel,
  HPDAssumptionsModel,
  HPDTechnicalModel,
  HPDLearnMoreModel,
  HPDSavingsModel,
} from "./hpd-details.model";
import {
  BarChartDataModel,
  LearnMoreRequestModel,
  MarketPlaceDetailsModel,
} from "../learn-more.model";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { LearnMoreService } from "../learn-more.service";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { AppService } from "../../../app.service";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-hpd-details",
  templateUrl: "hpd-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class HPDDetailsComponent implements OnInit, AfterContentChecked {
  hpd_Savings: number;
  icon = false;
  icon1 = false;
  panelOpenState = false;
  hpdViewDetails: HPDDetailsModel;
  financialDetails: HPDFinancialModel;
  technicalDetails: HPDTechnicalModel;
  assumptionDetails: HPDAssumptionsModel;
  marketPlaceDetails: MarketPlaceDetailsModel;
  learnMoreDetails: HPDLearnMoreModel;
  selected: TimeFrameEnum;
  twentyYear: boolean;
  oneYear: boolean;
  oneMonth: boolean;
  HPDType: string;
  yAxisLabel: string;
  yAxisLabel1: string;
  yAxisLabel2: string;
  chartOptions1: Highcharts.Options = {};
  textUnderChart: string;
  showTooltipZero: boolean;
  hpdForm: FormGroup;
  showIncentivesStatus: boolean;
  loader = true;
  totalIncentives: number;
  displayMarketPlaceBox: boolean;
  marketPlaceLink: string;
  haveResponse = false;
  showIncentiveBreakdown: boolean;
  linkId: string;
  timeFrame: string;
  timeSegmentList: DropDownModel[];
  adviceCardStatus: boolean;
  typeOfGas: DropDownModel[];
  activeIndex: number = 0;
  tabIndex: number = 0;
  tabLabel: string;
  isLangSpanish: boolean;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  isTechnicalExpanded: boolean;
  isFAQExpanded: boolean;
  favStatus: boolean;
  favIcon: string;
  financeHpdSavings: HPDSavingsModel;
  showCash: boolean = true;
  timeoutId: any;
  showBlackLoader: boolean;

  @ViewChild("container", { read: ElementRef }) container: ElementRef;
  @ViewChild("loaderButton", { read: ElementRef }) saveButton: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private fb: FormBuilder,
    private dashboardAPIService: DashboardAPIService,
    private learnMoreService: LearnMoreService,
    private cdRef: ChangeDetectorRef,
    private appService: AppService
  ) {
    this.timeSegmentList = this.learnMoreService.timeSegmentList;
    this.typeOfGas = this.learnMoreService.typeOfGas;
    this.hpdForm = this.fb.group({
      howManyPeople: [
        "3",
        [
          Validators.required,
          Validators.pattern("^([1-9]|10)$"),
          Validators.min(1),
          Validators.max(12),
        ],
      ],
      currentDryer: ["Natural gas", Validators.required],
    });
  }

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit() {
    this.setTimeSelector(this.dashboardAPIService.timeFrame);
    this.dashboardAPIService
      .faqDetails()
      .pipe(take(1))
      .subscribe((list: SectionListModel) => {
        this.FAQTabsList = list.hpd.faqList!;
      });
    this.getHPDViewDetails(this.selected);
    this.dashboardAPIService
      .getMarketplaceDetails("hpd")
      .pipe(take(1))
      .subscribe((response) => {
        this.marketPlaceDetails = response;
        this.adviceCardStatus = response.expertAdvice;
        this.displayMarketPlaceBox = this.marketPlaceDetails.displayBox;
        this.marketPlaceLink = this.marketPlaceDetails.buttonLink;
        if (this.marketPlaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketPlaceDetails.linkId;
        }
      });
  }

  setTimeSelector(val: TimeFrameEnum): void {
    this.selected = val;
    if (this.selected) {
      this.timeFrame = this.learnMoreService
        .renderTimeFrame(this.selected)
        .toLowerCase();
    }
  }

  handleActiveValue(data: string): void {
    if (data === "details") {
      this.isTechnicalExpanded = true;
      this.icon = true;
    } else if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon1 = true;
    }
  }

  expansionPanelClick(): void {
    this.icon = !this.icon;
  }

  faqExpansionClick(): void {
    this.icon1 = !this.icon1;
  }

  getHPDViewDetails(val: TimeFrameEnum): void {
    this.learnMoreService
      .learnMoreInfo<HPDDetailsModel>("hpd")
      .pipe(take(1))
      .subscribe((response: HPDDetailsModel) => {
        this.haveResponse = true;
        this.hpdViewDetails = response;
        this.financialDetails = this.hpdViewDetails.hpdFinancialDetails;
        this.technicalDetails = this.hpdViewDetails.hpdTechnicalDetails;
        this.assumptionDetails = this.hpdViewDetails.assumptionsDetails;
        this.totalIncentives = this.financialDetails.hpdCost.incentives;
        this.learnMoreDetails = this.hpdViewDetails.learnMoreDetails;
        this.favStatus = this.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.showIncentivesStatus =
          this.financialDetails.hpdCost.showIncentives;
        this.HPDType = this.learnMoreDetails.currentDryer;
        this.hpdFormDetails = this.technicalDetails;
        this.financeHpdSavings = this.financialDetails.hpdSavings;
        this.textUnderChart =
          "If you're able to add rooftop solar, the low-cost electricity from it can help improve the economics of a heat pump dryer.";
        this.HPDType = this.HPDType === "Natural gas" ? "gas" : this.HPDType;
        this.yAxisLabel = "Energy cost";
        this.yAxisLabel1 = "with heat";
        this.yAxisLabel2 = "pump dryer";
        this.updateChart(val);
        this.showTooltipZero = this.hpd_Savings <= 0;
        this.showBlackLoader = this.loader = false;
      });
  }

  updateChart(val: TimeFrameEnum): void {
    let energyCost;
    let dataInputs;

    this.setTimeSelector(val);
    energyCost = this.financeHpdSavings.withHeatPumpDryer[val];
    energyCost = energyCost <= 0 ? 0.0001 : energyCost;
    this.hpd_Savings = this.financeHpdSavings.total[val];
    dataInputs = [
      { y: energyCost, color: "#69A8F7" },
      {
        y: this.financeHpdSavings.withoutHeatPumpDryer[val],
        color: "#414141",
      },
    ];
    this.onUpdateChartOptions(dataInputs, this.yAxisLabel, this.yAxisLabel1);
  }

  onUpdateChartOptions(inputData: BarChartDataModel[], yAxis, yAxis1): void {
    let filteredInputData: BarChartDataModel[] = [];
    let i = 0;
    for (let entry of inputData) {
      if (entry.y != 0) {
        filteredInputData[i] = entry;
        i++;
      }
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });
    this.chartOptions1 = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        labels: {
          style: {
            color: "#333333",
            fontSize: "16px",
            fontFamily: "Open sans",
          },
        },
        categories: [
          `${yAxis} <br> ${yAxis1} <br> ${this.yAxisLabel2}`,
          `Energy cost with <br> ${this.HPDType.toLowerCase()} dryer`,
        ],
        gridLineWidth: 0,
        lineWidth: 1,
        tickWidth: 0,
      },
      yAxis: {
        tickAmount: 8,
        tickWidth: 0,
        gridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: null,
        },
        labels: {
          style: {
            color: "#333333",
            fontSize: "12px",
            fontFamily: "Open sans",
          },
          formatter: function (this) {
            if (Number(this.value) >= 1000) {
              return "$" + Number(this.value) / 1000 + "K";
            } else {
              return "$" + this.value;
            }
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          pointWidth: 50,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "Open sans",
            },
            formatter: function (this) {
              if (this.point.y && this.point.y < 1) {
                return "$0";
              } else {
                return (
                  "$" +
                  this.point.y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }
            },
            animation: {
              duration: 20,
            },
          },
        },
        series: {
          borderWidth: 0,
          animation: {
            duration: 1500,
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                className: "small-chart",
              },
            },
          },
        ],
      },
      series: [
        {
          type: "bar",
          showInLegend: false,
          data: filteredInputData,
        },
      ],
    };
    Highcharts.chart(this.container.nativeElement, this.chartOptions1);
  }

  get isNoOfPeopleOutOfRange(): boolean {
    return (
      this.hpdForm.controls["howManyPeople"].hasError("pattern") ||
      this.hpdForm.controls["howManyPeople"].hasError("min") ||
      this.hpdForm.controls["howManyPeople"].hasError("max")
    );
  }

  get isNoOfPeopleRequired(): boolean {
    return this.hpdForm.controls["howManyPeople"].hasError("required");
  }

  get isDryerTypeRequired(): boolean {
    return this.hpdForm.controls["currentDryer"].hasError("required");
  }

  get hpdData(): LearnMoreRequestModel {
    return {
      hpd: {
        howManyPeople: this.hpdForm.get("howManyPeople")?.value,
        currentDryer: this.hpdForm.get("currentDryer")?.value,
      },
    };
  }

  set hpdFormDetails(details: HPDTechnicalModel) {
    this.hpdForm
      .get("howManyPeople")
      ?.setValue(this.learnMoreDetails.howManyPeople);
    this.hpdForm.get("currentDryer")?.setValue(this.HPDType);
  }

  onSaveDetails(): void {
    this.haveResponse = false;
    if (
      this.learnMoreService.isFormEditted(
        this.hpdData.hpd!,
        this.learnMoreDetails
      )
    ) {
      this.saveButton.nativeElement.innerHTML =
        'Saving... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
      this.dashboardAPIService.dashboardDetailsAnalytics("save", "hpd");
      this.learnMoreService
        .updateLearnMoreInfo("hpd", this.hpdData)
        .pipe(take(1))
        .subscribe((data: any) => {
          this.timeoutId = setTimeout(() => {
            this.saveButton.nativeElement.innerHTML = "SAVE DETAILS";
            this.getHPDViewDetails(this.selected);
          }, 800);
        });
    }
  }

  handleFAQClick(): void {
    this.dashboardAPIService.dashboardDetailsAnalytics("moreInfo", "hpd");
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/heat-pump-dryer-faq-es/",
      "_blank"
    );
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo("hpd", ownPlan)
      .pipe(take(1))
      .subscribe(() => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getHPDViewDetails(this.selected);

        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardAPIService.techMessage("hpd", action);
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
