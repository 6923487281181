import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { LearnMoreService } from "../../../../../learn-more/learn-more.service";
import { HPDDataModel } from "../../../../model/userdata.model";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { RadioOptionsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { MatRadioChange } from "@angular/material/radio";
import { SettingsDialogData } from "../../setting-overlay.component.model";
import { SettingsOverlayService } from "../../settings-overlay.service";
import { Utils } from "../../../../../../utils/util";
import { Subject } from "rxjs";
import { DropDownModel } from "../../../../../../rest-api/rest-api.model";

@Component({
  selector: "yt-hpd",
  templateUrl: "hpd.component.html",
  styleUrls: ["../../common-tech.scss"],
})
export class HPDComponent implements AfterContentChecked {
  panelOpenState: boolean = false;
  hpdForm: FormGroup;
  disableDatePicker: boolean = false;
  isOwnNo: boolean = true;
  monthList: string[];
  lastTwelthMonth: string;
  ownValue: string;
  userDataModel: HPDDataModel[];
  selectedRadioButton: string;
  radioButtonOptions: RadioOptionsModel[];
  hideFormField: boolean;
  hpdSettings: HPDDataModel[];
  haveInputError: boolean;
  @Input() HPDDetails;
  @Input() activeIcon: string;
  current_dryer: DropDownModel[];
  subject: Subject<any> = new Subject();
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private utils: Utils,
    private learnMoreService: LearnMoreService,
    private settingService: SettingsOverlayService,
    private dashboardAPIService: DashboardAPIService
  ) {
    this.current_dryer = this.learnMoreService.typeOfGas;
    this.hpdForm = this.fb.group({
      own: ["", Validators.required],
      currentDryer: ["Natural gas", Validators.required],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngAfterContentChecked(): void {
    const datePurchased = this.hpdForm.get("datePurchased")!;
    if (this.haveInputError) this.haveInputError = datePurchased.invalid;
  }

  ngOnInit() {
    this.dashboardAPIService.getRadioButtonDetails().subscribe((data) => {
      this.radioButtonOptions = data.radioButtons;
    });
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    this.haveInputError = this.settingService.checkSettingsInfoError("hpd");
    this.userDataModel = this.haveInputError
      ? this.settingService.settingsInfoErrors.appliances?.hpd!
      : this.settingService.checkSettingsInfo("hpd")
      ? this.settingService.settingsInfo.appliances?.hpd!
      : this.dashboardAPIService.energyChoicesData.appliances.hpd!;

    this.userDataModel.forEach((hpd: HPDDataModel) => {
      this.ownValue = hpd.own!;
      this.setSelectedRadioValues(this.ownValue);
      this.hpdForm.patchValue(hpd);
      if (hpd.own === "yes") {
        this.hideFormField = false;
        this.hpdForm.get("currentDryer")?.setValue("Electric");
        if (!hpd.bought) {
          this.hpdForm.get("datePurchased")?.setValue(this.lastTwelthMonth);
        }
        this.hpdForm.enable();
        this.disableDatePicker = true;
        this.isOwnNo = true;
      } else if (hpd.own === "yesPlan") {
        this.hideFormField = true;
        this.hpdForm.enable();
        this.hpdForm.get("bought")?.disable();
        this.hpdForm.get("datePurchased")?.disable();
        this.disableDatePicker = false;
        this.isOwnNo = true;
      } else if (hpd.own === "noPlan") {
        this.hideFormField = true;
        this.hpdForm.enable();
        this.hpdForm.get("bought")?.disable();
        this.hpdForm.get("datePurchased")?.disable();
        this.disableDatePicker = false;
        this.isOwnNo = true;
      } else if (hpd.own === "no") {
        this.isOwnNo = false;
      }

      this.hpdForm.get("own")?.enable();
    });
    if (this.activeIcon) {
      if (this.activeIcon === "favorite") {
        this.hpdForm.get("own")?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.hpdForm.get("own")?.setValue("yes");
      }
      this.onValueChanges();
      this.setSelectedRadioValues(this.hpdForm.get("own")?.value);
      this.condition();
    } else {
      this.onValueChanges();
    }
  }

  onRadioChange(event: MatRadioChange) {
    this.setSelectedRadioValues(event.value);
  }

  setSelectedRadioValues(value: string): void {
    this.selectedRadioButton = value;
  }

  condition() {
    this.ownValue = this.hpdForm.get("own")?.value;
    const hpd = this.dashboardAPIService.energyChoicesData.appliances.hpd![0];
    if (this.ownValue === "yes") {
      this.hideFormField = false;
      this.hpdForm.get("bought")?.enable();
      this.hpdForm.get("datePurchased")?.enable();
      this.disableDatePicker = true;
      this.isOwnNo = true;
    } else if (this.ownValue === "yesPlan") {
      this.hideFormField = true;
      this.hpdForm.get("currentDryer")?.enable();
      this.hpdForm.get("currentDryer")?.setValue(hpd.currentDryer);
      this.hpdForm.get("bought")?.disable();
      this.hpdForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
      this.isOwnNo = true;
    } else if (this.ownValue === "noPlan") {
      this.hideFormField = true;
      this.hpdForm.get("currentDryer")?.enable();
      this.hpdForm.get("currentDryer")?.setValue(hpd.currentDryer);
      this.hpdForm.get("bought")?.disable();
      this.hpdForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
      this.isOwnNo = true;
    } else if (this.ownValue === "no") {
      this.isOwnNo = false;
    }
  }

  isDatePurchasedEmpty() {
    if (this.ownValue === "yes") {
      if (!this.hpdForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  onValueChanges(): void {
    this.hpdForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        const datePurchased = data.datePurchased;
        const bought = this.utils.isDatePurchaseSelected(datePurchased);

        if (data.own === "yes") {
          this.hpdSettings = [
            {
              own: data.own,
              bought: bought,
              datePurchased: bought ? data.datePurchased : null,
            },
          ];
        } else if (data.own === "yesPlan" || data.own === "noPlan") {
          this.hpdSettings = [
            {
              own: data.own,
              currentDryer: data.currentDryer,
            },
          ];
        }
        if (this.hpdForm.valid) {
          this.settingService.updateSettingsInfo(
            this.dashboardAPIService.energyChoicesData.appliances,
            this.hpdSettings,
            "appliances",
            "hpd"
          );
        } else {
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPIService.energyChoicesData.appliances,
            this.hpdSettings,
            "appliances",
            "hpd"
          );
        }
      });
      this.subject.next();
    });
  }

  onSaveHPD() {
    this.settingService.putSettingsInfo(this.getSettingsInfo);
  }

  getSettingsInfo = () => {
    if (this.activeIcon) {
      this.techSave.emit({ own: this.ownValue, tech: "hpd" });
    }
  };
}
