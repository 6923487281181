import {
  Component,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";

@Component({
  selector: "yt-cost-rendering",
  templateUrl: "./cost-rendering.component.html",
  styleUrls: ["./cost-rendering.component.scss"],
})
export class CostRenderingComponent {
  @Input() financialDetails: any;
  @Input() paymentTypeLabel: string;
  @Input() showCash: boolean;
  @Input() showLoan: boolean;
  @Input() show_Upfront_price :boolean;
  @Input() showLease: boolean;
  @Input() showPrice: boolean;
  @Input() showIncentivesStatus: boolean;
  @Input() lease: boolean;
  @Input() showIncentiveBreakdown: boolean;
  @Input() upfrontCostTooltipText: string;
  @Input() priceTooltipText: string;
  @Input() netCostTooltipText: string;
  @Input() leaseTooltipText: string;
  @Input() techName: string;
  @Input() heading: string;
  @Input() netCost: string;
  @Output() closeIncentiveBreakdown: EventEmitter<void> = new EventEmitter();
  @Input() isEvergreen: boolean;
  @Input() isEv:boolean;
  closeBreakdownPopup(): void {
    this.closeIncentiveBreakdown.emit();
    setTimeout(() => {
      this.showIncentiveBreakdown = false;
    }, 0);
  }

  toggleIncentiveBreakdown(): void {
    this.showIncentiveBreakdown = !this.showIncentiveBreakdown;
  }
}
