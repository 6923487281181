import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import { SolarVendorDetailsModel } from "../overview/model/solarvendordetails.model";
import {
  MyInfoDataModel,
  UserDataModel,
} from "../overview/model/userdata.model";
import { take } from "rxjs/operators";
import { EverGreenModel } from "../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../overview/overview.service";

@Component({
  selector: "yt-my-info",
  templateUrl: "my-info.component.html",
  styleUrls: ["./my-info.component.scss"],
})
export class MyInfoComponent implements OnInit, OnDestroy {
  userDetailsForm: FormGroup;
  public alive = true;
  formattedNumber: string;
  userData: UserDataModel;
  mobilePlaceHolder: string;
  mobileNumberMandatory: boolean;
  everGreenApplicable: boolean;
  everGreenStatus: boolean;
  buildingType: string;
  solutionType: string;
  onSolutionType: boolean = false;
  personalElement: HTMLElement;
  userDetailsFormValidation = {
    buildingType: "",
    address: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: {
      required: "",
      pattern: "",
    },
  };
  formLabel: string;
  infoText: string;
  validationErrorMessages = {
    buildingType: {
      required: "Required!",
    },
    address: {
      required: "Required!",
    },
    firstName: {
      required: "Required!",
    },
    lastName: {
      required: "Required!",
    },
    contactNumber: {
      required: "Required!",
    },
    email: {
      required: "Required!",
      pattern: "Invalid email!",
    },
  };

  constructor(
    private fb: FormBuilder,
    private restAPIService: DashboardAPIService,
    private dashboardAPI: DashboardAPIService,
    public snackBar: MatSnackBar,
    private overviewService: OverviewService
  ) {
    this.userDetailsForm = this.fb.group({
      buildingType: [""],
      address: [""],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      contactNumber: ["", Validators.minLength(10)],
      incomeQualified: [""],
      email: [this.restAPIService.getUserDetails().userName],
      everGreen: [false],
    });
  }

  ngOnInit() {
    this.personalElement = document.getElementById("person")!;
    this.solutionType = this.dashboardAPI.vendorDetails.solutionType;
    if (this.solutionType === "vendor") {
      this.onSolutionType = true;
    }

    if (this.personalElement) this.personalElement.style.fontWeight = "600";
    this.overviewService.userSettingInfo
      .pipe(take(1))
      .subscribe((response: UserDataModel) => {
        this.userData = response;
        this.formLabel = "address" in response.myInfo ? "Address" : "Zip Code";
        this.infoText = this.formLabel.toLowerCase();
        this.getEverGreenData();
        this.getBuildingDisplayValue(this.userData.buildingType);
        this.userDetailsFormValue = this.userData.myInfo;
      });
    this.userDetailsForm.get("address")?.disable();
    this.userDetailsForm.get("email")?.disable();
    this.userDetailsForm.get("buildingType")?.disable();
    this.userDetailsForm.valueChanges.pipe(take(1)).subscribe((data) => {
      this.onFormValueChanges(data);
    });
  }

  set userDetailsFormValue(data: MyInfoDataModel) {
    this.userDetailsForm.get("firstName")?.setValue(data.firstName);
    this.userDetailsForm.get("lastName")?.setValue(data.lastName);
    this.userDetailsForm
      .get("address")
      ?.setValue(data.address ? data.address : data.zipCode);
    this.userDetailsForm.get("contactNumber")?.setValue(data.contactNumber);
    this.userDetailsForm.get("buildingType")?.setValue(this.buildingType);
  }

  getBuildingDisplayValue(buildingTypeValue: string): void {
    this.overviewService.lifestyleData
      .pipe(take(1))
      .subscribe((response: SolarVendorDetailsModel) => {
        this.mobileNumberMandatory = response.phoneNumber;
        this.modifyMobileFormField(this.mobileNumberMandatory);
        let buildingTypes = response.buildingTypes;
        Object.keys(buildingTypes).forEach((building) => {
          if (buildingTypes[building].value === buildingTypeValue) {
            this.buildingType = buildingTypes[building].displayValue;
          }
        });
      });
  }

  getEverGreenData(): void {
    this.restAPIService
      .getEvergreenUpdate()
      .pipe(take(1))
      .subscribe((response: EverGreenModel) => {
        this.everGreenApplicable = response.everGreenApplicable;
        if (this.everGreenApplicable) {
          this.everGreenStatus = response.everGreenStatus;
          this.userDetailsForm.get("everGreen")?.setValue(this.everGreenStatus);
        }
      });
  }

  modifyMobileFormField(data): void {
    this.mobilePlaceHolder = data ? "" : "(Optional)";
    if (data) {
      this.userDetailsForm.controls["contactNumber"].setValidators([
        Validators.required,
        Validators.minLength(10),
      ]);
      this.userDetailsForm.controls["contactNumber"].updateValueAndValidity();
    }
  }

  formatMobileNumber(mobile: string): void {
    const numberLength = mobile.length;
    const mobileValue = mobile;
    if (mobileValue.match(/^\d+$/)) {
      let s2 = ("" + mobileValue).replace(/\D/g, "");
      let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
      this.formattedNumber = !m ? "" : m[1] + "-" + m[2] + "-" + m[3];
      if (numberLength === 10) {
        this.userDetailsForm.controls["contactNumber"].setValue(
          this.formattedNumber
        );
      }
    } else {
      this.userDetailsForm.controls["contactNumber"].reset();
    }
  }

  onFormValueChanges(data: any): void {
    if (!this.userDetailsForm) {
      return;
    }
    this.checkForErrorValidations(data);
  }

  checkForErrorValidations(data: any): void {
    const form = this.userDetailsForm;
    const formErrors = this.userDetailsFormValidation;
    const validationmsg = this.validationErrorMessages;

    for (const field in formErrors) {
      if (field === "email") {
        formErrors[field] = {
          required: "",
          pattern: "",
        };
      } else {
        formErrors[field] = "";
      }
      const control = form.get(field);
      if (control && control.dirty && control.invalid) {
        const messages = validationmsg[field];
        for (const key in control.errors) {
          if (field === "email") {
            formErrors[field][key] += messages[key];
          } else {
            formErrors[field] += messages[key];
          }
        }
      }
    }
  }

  onContinue(): void {
    if (this.userDetailsForm.controls["everGreen"].value) {
      this.everGreenStatus = true;
    } else {
      this.everGreenStatus = false;
    }
    let userInfo = {
      myInfo: {
        address: this.userDetailsForm.controls["address"].value,
        firstName: this.userDetailsForm.controls["firstName"].value,
        lastName: this.userDetailsForm.controls["lastName"].value,
        contactNumber: this.userDetailsForm.controls["contactNumber"].value,
      },
      buildingType: this.userDetailsForm.controls["buildingType"].value,
    };
    let everGreenInfo = {
      everGreenStatus: this.everGreenStatus,
    };
    this.overviewService
      .updateUserSettingInfo(userInfo)
      .pipe(take(1))
      .subscribe((data) => {
        if (data.status === 200) {
          this.snackBar.open("Changes Saved.", "OK", {
            duration: 8000,
          });
        }
      });
    if (this.everGreenApplicable) {
      this.restAPIService
        .putEvergreenUpdate(everGreenInfo)
        .pipe(take(1))
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.personalElement) this.personalElement.style.fontWeight = "normal";
  }
}
