import { Component, Input } from "@angular/core";
import { LogoModel } from "../../../rest-api/rest-api.model";

@Component({
  selector: "yt-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  @Input() logo: LogoModel;
}
