import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { HelpMeChooseComponent } from "../../shared/help-me-choose/help-me-choose.component";
import { MatDialog } from "@angular/material/dialog";
import {
  RebateDetailsModel,
  MarketPlaceDetailsModel,
  LearnMoreRequestModel,
} from "../learn-more.model";
import {
  LCELearnMoreModel,
  LCELearnMoreInfoModel,
  LCEDetailsModel,
} from "./lce-details.model";
import { LearnMoreService } from "../learn-more.service";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { Router } from "@angular/router";
import { Utils } from "src/app/utils/util";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-lce-details",
  templateUrl: "lce-details.component.html",
  styleUrls: ["./lce-details.component.scss"],
})
export class LCEDetailsComponent implements OnInit {
  readMore = true;
  readLess = false;
  readMoreContent = false;
  readMoreSMT = true;
  readLessSMT = false;
  readMoreContentSMT = false;
  readMoreTHR = true;
  readLessTHR = false;
  readMoreContentTHR = false;
  readMoreWEA = true;
  readLessWEA = false;
  readMoreContentWEA = false;
  readMoreSHW = true;
  readLessSHW = false;
  readMoreContentSHW = false;
  efForm: FormGroup;
  installedLED = "no";
  ledBulbs: string;
  smartStrips: string;
  weatherStripping: string;
  smartThermostat: string;
  aeratorsShowerHeads: string;
  updateDetailsUrl: string;
  loader: boolean = true;
  showNetCost: boolean;
  rebateDetails: RebateDetailsModel;
  learnMoreDetails: LCELearnMoreModel;
  marketPlaceDetails_thermostat: MarketPlaceDetailsModel;
  marketPlaceDetails_ledCflBulbs: MarketPlaceDetailsModel;
  marketPlaceDetails_smartPowerStrips: MarketPlaceDetailsModel;
  marketPlaceDetails_weatherStripping: MarketPlaceDetailsModel;
  marketPlaceDetails_aerators: MarketPlaceDetailsModel;
  marketPlaceDetails_showerHeads: MarketPlaceDetailsModel;
  marketPlaceDetails_AS: MarketPlaceDetailsModel;
  lceInfo: LCELearnMoreInfoModel;
  heading: string;
  netCost: string;
  isResponseLoaded = false;
  displayMarketPlaceBox_thermostat: boolean;
  displayMarketPlaceBox_ledCflBulbs: boolean;
  displayMarketPlaceBox_smartPowerStrips: boolean;
  displayMarketPlaceBox_weatherStripping: boolean;
  displayMarketPlaceBox_aerators: boolean;
  displayMarketPlaceBox_showerHeads: boolean;
  displayMarketPlaceBox_AS: boolean;
  marketPlaceLink_thermostat: string;
  marketPlaceLink_ledCflBulbs: string;
  marketPlaceLink_smartPowerStrips: string;
  marketPlaceLink_weatherStripping: string;
  marketPlaceLink_aerators: string;
  marketPlaceLink_showerHeads: string;
  marketPlaceLink_AS: string;
  linkId_thermostat: string;
  linkId_ledCflBulbs: string;
  linkId_smartPowerStrips: string;
  linkId_weatherStripping: string;
  linkId_aerators: string;
  linkId_showerHeads: string;
  linkId_AS: string;
  showIncentiveBreakdown: boolean;
  makeAndModel: string;
  solutionType: string;
  vendorCodes: string[];
  haveYltnMarketplace: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private learnMoreService: LearnMoreService,
    private dashboardService: DashboardAPIService,
    private utils: Utils
  ) {
    this.efForm = this.fb.group({
      ledInstalled: false,
    });
  }

  ngOnInit(): void {
    this.solutionType = this.dashboardService.vendorDetails.solutionType;
    this.haveYltnMarketplace = this.utils.haveMarketPlace(this.solutionType);
    this.getLCEDetails();
    this.marketPlaceAPI();
  }

  marketPlaceAPI(): void {
    this.dashboardService
      .getMarketplaceDetails("thermostat")
      .pipe(take(1))
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails_thermostat = response;
        this.displayMarketPlaceBox_thermostat =
          this.marketPlaceDetails_thermostat.displayBox;
        this.marketPlaceLink_thermostat =
          this.marketPlaceDetails_thermostat.buttonLink;
        this.linkId_thermostat = this.marketPlaceDetails_thermostat.linkId;
        if (this.marketPlaceDetails_thermostat.linkId === "") {
          this.linkId_thermostat = "";
        } else {
          this.linkId_thermostat = this.marketPlaceDetails_thermostat.linkId;
        }
      });
    this.dashboardService
      .getMarketplaceDetails("ledCflBulbs")
      .pipe(take(1))
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails_ledCflBulbs = response;
        this.displayMarketPlaceBox_ledCflBulbs =
          this.marketPlaceDetails_ledCflBulbs.displayBox;
        this.marketPlaceLink_ledCflBulbs =
          this.marketPlaceDetails_ledCflBulbs.buttonLink;
        this.linkId_ledCflBulbs = this.marketPlaceDetails_ledCflBulbs.linkId;
        if (this.marketPlaceDetails_ledCflBulbs.linkId === "") {
          this.linkId_ledCflBulbs = "";
        } else {
          this.linkId_ledCflBulbs = this.marketPlaceDetails_ledCflBulbs.linkId;
        }
      });
    this.dashboardService
      .getMarketplaceDetails("smartPowerStrips")
      .pipe(take(1))
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails_smartPowerStrips = response;
        this.displayMarketPlaceBox_smartPowerStrips =
          this.marketPlaceDetails_smartPowerStrips.displayBox;
        this.marketPlaceLink_smartPowerStrips =
          this.marketPlaceDetails_smartPowerStrips.buttonLink;
        this.linkId_smartPowerStrips =
          this.marketPlaceDetails_smartPowerStrips.linkId;
        if (this.marketPlaceDetails_smartPowerStrips.linkId === "") {
          this.linkId_smartPowerStrips = "";
        } else {
          this.linkId_smartPowerStrips =
            this.marketPlaceDetails_smartPowerStrips.linkId;
        }
      });
    this.dashboardService
      .getMarketplaceDetails("weatherStripping")
      .pipe(take(1))
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails_weatherStripping = response;
        this.displayMarketPlaceBox_weatherStripping =
          this.marketPlaceDetails_weatherStripping.displayBox;
        this.marketPlaceLink_weatherStripping =
          this.marketPlaceDetails_weatherStripping.buttonLink;
        this.linkId_weatherStripping =
          this.marketPlaceDetails_weatherStripping.linkId;
        if (this.marketPlaceDetails_weatherStripping.linkId === "") {
          this.linkId_weatherStripping = "";
        } else {
          this.linkId_weatherStripping =
            this.marketPlaceDetails_weatherStripping.linkId;
        }
      });
    this.dashboardService
      .getMarketplaceDetails("aerators")
      .pipe(take(1))
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails_aerators = response;
        this.displayMarketPlaceBox_aerators =
          this.marketPlaceDetails_aerators.displayBox;
        this.marketPlaceLink_aerators =
          this.marketPlaceDetails_aerators.buttonLink;
        this.linkId_aerators = this.marketPlaceDetails_aerators.linkId;
        if (this.marketPlaceDetails_aerators.linkId === "") {
          this.linkId_aerators = "";
        } else {
          this.linkId_aerators = this.marketPlaceDetails_aerators.linkId;
        }
      });
    this.dashboardService
      .getMarketplaceDetails("showerHeads")
      .pipe(take(1))
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails_showerHeads = response;
        this.displayMarketPlaceBox_showerHeads =
          this.marketPlaceDetails_showerHeads.displayBox;
        this.marketPlaceLink_showerHeads =
          this.marketPlaceDetails_showerHeads.buttonLink;
        this.linkId_showerHeads = this.marketPlaceDetails_showerHeads.linkId;
        if (this.displayMarketPlaceBox_aerators) {
          this.marketPlaceDetails_AS = this.marketPlaceDetails_aerators;
          this.displayMarketPlaceBox_AS = this.displayMarketPlaceBox_aerators;
          this.marketPlaceLink_AS = this.marketPlaceLink_aerators;
          this.linkId_AS = this.linkId_aerators;
        } else if (this.displayMarketPlaceBox_showerHeads) {
          this.marketPlaceDetails_AS = this.marketPlaceDetails_showerHeads;
          this.displayMarketPlaceBox_AS =
            this.displayMarketPlaceBox_showerHeads;
          this.marketPlaceLink_AS = this.marketPlaceLink_showerHeads;
          this.linkId_AS = this.linkId_showerHeads;
        }
        if (this.marketPlaceDetails_showerHeads.linkId === "") {
          this.linkId_showerHeads = "";
        } else {
          this.linkId_showerHeads = this.marketPlaceDetails_showerHeads.linkId;
        }
      });
  }

  onStartShopping(tech_name: string, linkId: string): void {
    if (this.haveYltnMarketplace) {
      const techList = [
        "ledCflBulbs",
        "smartPowerStrips",
        "lightDimmer",
        "motionSensors",
      ];
      if (techList.includes(tech_name)) {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/lightingElectricDevices`
        );
      } else if (tech_name === "aerators") {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/waterSavingDevices`
        );
      } else if (tech_name === "thermostat" || "weatherStripping") {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/homeComfortDevices`
        );
      }
    }
  }

  getLCEDetails(): void {
    this.learnMoreService
      .learnMoreInfo<LCEDetailsModel>("energyEff")
      .pipe(take(1))
      .subscribe((response: LCEDetailsModel) => {
        this.loader = false;
        this.isResponseLoaded = true;
        let lce = response.technicalDetails.enrgEff_individual;
        this.learnMoreDetails = response.learnMoreDetails;
        this.makeAndModel = response.learnMoreDetails.mnfAndModel;
        this.lceInfo = lce.lceLearnMoreDetails[0];
        this.ledBulbs = lce.ledBulbs;
        this.smartStrips = lce.smartStrips;
        this.showNetCost = lce.showNetCost;
        this.weatherStripping = lce.weatherStripping;
        this.smartThermostat = lce.smartThermostat;
        this.aeratorsShowerHeads = lce.aeratorsShowerHeads;
        this.rebateDetails = lce.rebateDetails;
        this.installedLED = response.learnMoreDetails.ledInstalled
          ? "yes"
          : "no";
        this.getLCEInfo();
      });
  }

  getLCEInfo(): void {
    let ledInfoDoc = document.getElementById("ledBulbs")!;
    ledInfoDoc.innerHTML = this.readMoreContent
      ? this.lceInfo.ledBulbsInfo[0] + " " + this.lceInfo.ledBulbsInfo[1]
      : this.lceInfo.ledBulbsInfo[0];
    let smartInfoDoc = document.getElementById("smartPowInfo")!;
    smartInfoDoc.innerHTML = this.readMoreContentSMT
      ? this.lceInfo.smartPowerStripsInfo[0] +
        " " +
        this.lceInfo.smartPowerStripsInfo[1]
      : this.lceInfo.smartPowerStripsInfo[0];
    let smartThermoDoc = document.getElementById("smartThermoInfo")!;
    smartThermoDoc.innerHTML = this.readMoreContentTHR
      ? this.lceInfo.smartThermostatInfo[0] +
        " " +
        this.lceInfo.smartThermostatInfo[1]
      : this.lceInfo.smartThermostatInfo[0];
    let weatherStripInfoDoc = document.getElementById("weatherStripInfo")!;
    weatherStripInfoDoc.innerHTML = this.readMoreContentWEA
      ? this.lceInfo.weatherStrippingInfo[0] +
        " " +
        this.lceInfo.weatherStrippingInfo[1]
      : this.lceInfo.weatherStrippingInfo[0];
    let lowFlowInfoDoc = document.getElementById("lowFInfo")!;
    lowFlowInfoDoc.innerHTML = this.readMoreContentSHW
      ? this.lceInfo.lowFlowInfo[0] + " " + this.lceInfo.lowFlowInfo[1]
      : this.lceInfo.lowFlowInfo[0];
    this.appendClasses();
  }

  appendClasses(): void {
    const lceLED = document.getElementById("lce_led")!;
    this.readMoreContent
      ? lceLED.setAttribute("class", "yt_text db pb3")
      : lceLED.setAttribute("class", "yt_text");
    const lceSPS = document.getElementById("lce_sps")!;
    this.readMoreContentSMT
      ? lceSPS.setAttribute("class", "yt_text db pb3")
      : lceSPS.setAttribute("class", "yt_text");
    const lceST = document.getElementById("lce_st")!;
    this.readMoreContentTHR
      ? lceST.setAttribute("class", "yt_text db pb3")
      : lceST.setAttribute("class", "yt_text");
    const lceWS = document.getElementById("lce_ws")!;
    this.readMoreContentWEA
      ? lceWS.setAttribute("class", "yt_text db pb3")
      : lceWS.setAttribute("class", "yt_text");
    const lceLFA = document.getElementById("lce_lfa")!;
    this.readMoreContentSHW
      ? lceLFA.setAttribute("class", "yt_text db pb3")
      : lceLFA.setAttribute("class", "yt_text");
  }

  onChangeLED(): void {
    this.isResponseLoaded = false;
    this.installedLED = this.installedLED === "no" ? "yes" : "no";
    this.learnMoreApi();
  }

  learnMoreApi(): void {
    this.learnMoreService
      .updateLearnMoreInfo("ee", this.LCEData)
      .pipe(take(1))
      .subscribe(this.getLCEDetails);
  }

  get LCEData(): LearnMoreRequestModel {
    return {
      ee: {
        ledInstalled: this.installedLED === "yes",
        thermostatMnfAndModel: this.makeAndModel,
      },
    };
  }

  readManager(arg: string) {
    switch (arg) {
      case "onReadMore":
        this.readMore = false;
        this.readLess = true;
        this.readMoreContent = true;
        break;
      case "onReadLess":
        this.readLess = false;
        this.readMore = true;
        this.readMoreContent = false;
        break;
      case "onReadMoreSMT":
        this.readMoreSMT = false;
        this.readLessSMT = true;
        this.readMoreContentSMT = true;
        break;
      case "onReadLessSMT":
        this.readLessSMT = false;
        this.readMoreSMT = true;
        this.readMoreContentSMT = false;
        break;
      case "onReadMoreTHR":
        this.readMoreTHR = false;
        this.readLessTHR = true;
        this.readMoreContentTHR = true;
        break;
      case "onReadLessTHR":
        this.readLessTHR = false;
        this.readMoreTHR = true;
        this.readMoreContentTHR = false;
        break;
      case "onReadMoreWEA":
        this.readMoreWEA = false;
        this.readLessWEA = true;
        this.readMoreContentWEA = true;
        break;
      case "onReadLessWEA":
        this.readLessWEA = false;
        this.readMoreWEA = true;
        this.readMoreContentWEA = false;
        break;
      case "onReadMoreSHW":
        this.readMoreSHW = false;
        this.readLessSHW = true;
        this.readMoreContentSHW = true;
        break;
      case "onReadLessSHW":
        this.readLessSHW = false;
        this.readMoreSHW = true;
        this.readMoreContentSHW = false;
        break;
    }
    this.getLCEInfo();
  }

  openHelpMeChoose(): void {
    let dialogRef = this.dialog.open(HelpMeChooseComponent, {
      width: "90%",
      height: "75%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "custom-dialog-container",
      data: { tech: "thermostat" },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: string) => {
        if (result !== undefined) {
          this.makeAndModel = result;
          this.learnMoreApi();
        }
      });
  }
}
