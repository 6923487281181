import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NotificationCenterService } from "../notification-center.service";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-notifcation-setting",
  templateUrl: "notification-setting.component.html",
  styleUrls: ["./notification-setting.component.scss"],
})
export class NotificationSettingComponent implements OnInit {
  notificationForm: FormGroup;
  notificationStatus = false;
  vendorcode: string;
  vendorDesc: string;

  constructor(
    public notificationCenterService: NotificationCenterService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dashboardAPIService: DashboardAPIService
  ) {}

  ngOnInit(): void {
    this.notificationCenterService
      .getNotificationStatus()
      .pipe(take(1))
      .subscribe((response) => {
        this.notificationStatus = response.incentivesEmailMe;
      });
    this.vendorcode = this.dashboardAPIService.getUserDetails().solarVendorCode;
    this.vendorDesc = this.dashboardAPIService.solarVendorDescription;

    this.notificationForm = this.fb.group({
      incentivesEmailMe: [""],
    });
    this.notificationForm
      .get("incentivesEmailMe")
      ?.setValue(this.notificationStatus);
  }

  onIncentivesValueChange() {
    const notificationInfo = {
      incentivesEmailMe: this.notificationForm.get("incentivesEmailMe")?.value,
    };
    this.notificationCenterService
      .putNotificationUpdate(notificationInfo)
      .pipe(take(1))
      .subscribe((data) => {
        if (data.status === 200) {
          this.snackBar.open("Changes Saved.", "OK", {
            duration: 8000,
          });
        }
      });
  }
}
