<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">Heat Pump Clothes Dryer</div>

      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isSavings]="true"
        [isDescription]="false"
        [isSettings]="true"
        [isDetails]="true"
        [isAssumptions]="!!assumptionDetails"
        [isFaq]="true"
        [techParameter]="'hpd'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>

      <div>
        <div
          class="yt-text"
          [innerHTML]="
            'homeOwenerDashboard.learnMore.hpd.paragraph' | translate
          "
        ></div>

        <div *ngIf="!isLangSpanish" id="aboutTechnology">
          <yt-hpd-FAQ [showAboutTech]="true"></yt-hpd-FAQ>
        </div>
        <div class="faq_mobile">
          <button
            (click)="onFavorite()"
            class="favorite_btn"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
      </div>

      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="assets/lifestyle/dryer.svg"
              alt="heat pump dryer"
              [ngStyle]="{ height: !displayMarketPlaceBox ? '160px' : '250px' }"
            />
          </div>
          <button
            (click)="onFavorite()"
            class="favorite_btn mt_20"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button mt_20"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>

        <div class="width-box" id="cost" *ngIf="financialDetails">
          <yt-cost-rendering
            [financialDetails]="financialDetails.hpdCost"
            [paymentTypeLabel]="'Net cost'"
            [showCash]="true"
            [show_Upfront_price]="true"
            [showIncentivesStatus]="showIncentivesStatus"
            [lease]="false"
            [showIncentiveBreakdown]="showIncentiveBreakdown"
            [techName]="'hpd'"
            [heading]="'Incentives Breakdown'"
            [netCost]="'Total'"
            (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
            [upfrontCostTooltipText]="
              'Includes equipment, labor, permits, etc. Excludes charges for main service panel upgrade (which is sometimes needed).'
            "
            [netCostTooltipText]="'Net cost = Upfront cost - Incentives'"
          ></yt-cost-rendering>

          <yt-market-place
            [tech_name]="'hpd'"
            [linkId]="linkId"
            [marketPlaceDetails]="marketPlaceDetails"
            *ngIf="marketPlaceDetails && displayMarketPlaceBox"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'hpd'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div class="bottom_section w-100">
        <div class="left_section">
          <div class="highChart_card highChart_card_mobile">
            <div class="details_header pb_14">Savings</div>
            <div class="w-100 toggle_buttons">
              <mat-button-toggle-group
                [(value)]="selected"
                (change)="updateChart($event.value)"
              >
                <mat-button-toggle
                  *ngFor="let time of timeSegmentList"
                  [value]="time.value"
                >
                  <span class="toggle_label">{{
                    time.viewValue?.toUpperCase()
                  }}</span></mat-button-toggle
                >
              </mat-button-toggle-group>
            </div>
            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="Using a natural gas dryer is cheaper than using a heat pump dryer. However, switching from gas reduces carbon emissions."
              >
                energy savings
              </span>
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span
                class="savings_value"
                [ngClass]="{ value_color: hpd_Savings < 0 }"
                >{{ hpd_Savings | currency : "$" : "symbol" : "1.0-0" }}</span
              >
            </div>
            <div class="w-100 chart_section">
              <div class="mobile_chart_text">
                To view and compare graphs of energy costs, please login to your
                dashboard using a desktop.
                <!-- Please log into your dashboard on a desktop to view and compare <span class="fw6">graphs</span> of
                energy costs. -->
              </div>
            </div>
          </div>

          <div class="details_card" [formGroup]="hpdForm" id="settings">
            <div class="details_header">PERSONALIZE</div>
            <div class="form_label">No. of people in household</div>
            <mat-form-field
              class="w-100 formfield_padding"
              floatLabel="auto"
              appearance="outline"
            >
              <input
                matInput
                formControlName="howManyPeople"
                placeholder="Enter 1-10"
                autocomplete="off"
                maxlength="3"
              />
              <mat-error *ngIf="isNoOfPeopleOutOfRange">
                Please enter value between 1-10
              </mat-error>
              <mat-error *ngIf="isNoOfPeopleRequired"> Required! </mat-error>
            </mat-form-field>
            <div class="form_label">Current dryer type</div>
            <mat-form-field
              class="w-100 formfield_padding"
              floatLabel="auto"
              appearance="outline"
            >
              <mat-select
                placeholder="Current dryer type"
                formControlName="currentDryer"
              >
                <mat-option
                  *ngFor="let dryer of typeOfGas"
                  [value]="dryer.value"
                >
                  {{ dryer.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="isDryerTypeRequired"> Required! </mat-error>
            </mat-form-field>
            <div
              class="save_button"
              [ngClass]="{
                '': !displayMarketPlaceBox,
                'ghost-button': displayMarketPlaceBox,
                disable_button: hpdForm.invalid
              }"
            >
              <button
                mat-raised-button
                color="accent"
                #loaderButton
                [disabled]="hpdForm.invalid"
                (click)="onSaveDetails()"
              >
                SAVE DETAILS
              </button>
            </div>
          </div>

          <yt-assumption-card
            [assumptionDetails]="assumptionDetails"
          ></yt-assumption-card>
        </div>

        <div class="right_section">
          <div class="highChart_card" id="savings">
            <div class="details_header pb_14">Savings</div>
            <app-Time-Rendering
              [selected]="selected"
              (selectionChange)="updateChart($event)"
            >
            </app-Time-Rendering>
            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="Using a natural gas dryer is cheaper than using a heat pump dryer in some situations. However, switching from gas reduces carbon emissions."
              >
                energy savings
              </span>
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span
                class="savings_value"
                [ngClass]="{ value_color: hpd_Savings < 0 }"
                >{{ hpd_Savings | currency : "$" : "symbol" : "1.0-0" }}</span
              >
            </div>
            <div class="formula_text">
              Energy savings &nbsp; = &nbsp; Energy cost with
              <span style="text-transform: lowercase">{{ HPDType }}</span> dryer
              - Energy cost with heat pump dryer
            </div>
            <div class="w-100 chart_section">
              <div class="hide_chart">
                <div class="chart" #container></div>
              </div>
            </div>
            <div class="w-100 chart_description_text">{{ textUnderChart }}</div>
          </div>
          <div class="summary_card" id="details">
            <mat-expansion-panel
              (closed)="isTechnicalExpanded = false"
              [expanded]="isTechnicalExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="expansionPanelClick()">
                <mat-panel-title class="flex items-center">
                  Technical Details &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <div class="left_side_values">
                  <div class="label_heading w-100">Recommended Dryer</div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <div>Type</div>
                      <div *ngIf="hpdViewDetails">
                        {{ technicalDetails.hpdType }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Capacity</div>
                      <div *ngIf="hpdViewDetails">
                        {{ technicalDetails.capacity }} cu ft
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Voltage</div>
                      <div *ngIf="hpdViewDetails">
                        {{ technicalDetails.voltageRequired }}
                        V
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Manufacturer</div>
                      <div *ngIf="hpdViewDetails">
                        {{ technicalDetails.manufacturer }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Warranty</div>
                      <div *ngIf="hpdViewDetails">
                        {{ technicalDetails.warranty }}
                        year
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

          <div class="faq_card w-100" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="faqExpansionClick()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon1 ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-hpd-FAQ
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-hpd-FAQ>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div
        class="link_text back_to_top"
        (click)="scrollToTop()"
        *ngIf="!isLangSpanish"
      >
        <mat-icon class="pointer mat_color">keyboard_arrow_up</mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<yt-breakdown-popup
  *ngIf="showIncentiveBreakdown && haveResponse"
  (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
  [heading]="'Incentives Breakdown'"
  [netCost]="'Total'"
  [techName]="'hpd'"
>
</yt-breakdown-popup>

<!-- loader -->
<yt-loader
  [blackLoader]="showBlackLoader"
  *ngIf="loader || showBlackLoader"
  top="0"
  [relative]="true"
></yt-loader>
