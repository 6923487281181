<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Uses:</strong> Charging an electric vehicle (EV) at home is a
      convenient way to ensure your vehicle is ready for daily use. It’s also a
      cheaper alternative to the use of commercial charging stations.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>

    <div *ngIf="showReadLess">
      <p class="sys_type">
        <strong>Types of Home Charging Systems:</strong> The simplest approach
        is slow charging with a Level 1 charging cord that plugs into a standard
        120V outlet. However, faster charging can be accomplished by using a
        Level 2, 240V charging cord or wall-mounted charger. This is normally
        recommended since faster charging provides greater flexibility and
        convenience.
      </p>

      <p class="sys_type">
        <strong>Upfront Cost:</strong> There’s generally no upfront cost for
        Level 1 charging because most EVs come with a Level 1 charging cord. The
        cost for Level 2 charging can be as low as a few hundred dollars if you
        have an existing 240V electrical outlet and use a portable charging
        cord. On the other hand, setting up Level 2 charging would likely cost
        between $1,200 and $2,000 if you need to have an electrician run a new
        240V circuit and install a wall-mounted Level 2 charger.
      </p>

      <p class="sys_type">
        <strong>Options for Those in apartments:</strong> Some apartment
        complexes include EV charging as a convenience for tenants, but for
        those without this option charging at work is sometimes a good
        alternative.  A third option is to use a Level 3 fast charger at a
        nearby shopping center, restaurant, or mall.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>

  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How can I charge my electric vehicle at home?
        </h5>
        <p class="answer_text">
          Charging an electric vehicle (EV) at home is a convenient way to
          ensure your vehicle is always ready to go. Various types of home
          charging equipment (also referred to as Electric Vehicle Supply
          Equipment) are available to meet a wide range of needs. The simplest
          approach is slow charging, with a Level 1 charging cord that plugs
          into a standard 120V outlet. Faster charging can be accomplished with
          Level 2 chargers that require a 240V circuit. (Level 3 chargers are
          very fast, but they’re not available for home use.)
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. What type of home charging approach is best for me?
        </h5>
        <p class="answer_text">
          This depends on several factors, such as your EV’s characteristics,
          your daily driving habits, and your budget. Level 1 charging is the
          cheapest and simplest, but only adds about 3 – 5 miles of travel for
          each hour of charging. This may be sufficient if you have a short
          daily commute or drive a plug-in hybrid EV (PHEV) with a relatively
          small battery. Level 2 charging is generally recommended as the more
          flexible approach, letting you add from 15 - 60 miles per hour of
          charging. However, this would require hiring an electrician to install
          a 240V electrical outlet if one isn’t already available in the right
          place. Level 2 charging is often accomplished with wall-mounted
          charging stations, but these are not required.  Instead, you can use
          the less-expensive approach of a portable Level 2 charger that plugs
          into a 240V wall socket.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">3. What if I live in an apartment?</h5>
        <p class="answer_text">
          An increasing number of apartment building owners are installing EV
          chargers, partly because of financial incentives and partly because
          tenants view chargers as a valued amenity. For tenants without this
          option, charging at work is sometimes a good approach.  Another
          alternative is to find a Level 3, fast charger nearby, such as at a
          shopping center, restaurant, or mall.  Often you can add between 150 –
          1000 miles of travel per hour of charging while buying groceries,
          taking in a movie, or working out at the gym.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What is the upfront cost for home charging?
        </h5>
        <p class="answer_text">
          The cost depends on the charging approach you take. The least
          expensive approach – essentially zero upfront cost -- is Level 1
          charging which simply involves plugging the charge cord that likely
          came with the EV into a standard 120V electrical outlet. This approach
          would let you add about 3 – 5 miles of travel for each hour of
          charging. For Level 2 charging, which can add 15 - 60 miles per hour
          of charging from a 240v circuit, you have several options. If you have
          an existing 240V circuit near where you park your car, you can
          probably use a portable 240V charge cord (generally costing between
          $200 and $400) that plugs into an existing outlet. Alternatively, you
          can have a wall-mounted charger installed – generally $500 - $800 for
          the hardware and another $300 - $500 for installation. If you also
          need to have a 240V circuit run to where you park, the overall upfront
          cost could easily rise to $2,000 or more.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Can my EV share a 240V circuit with another appliance?
        </h5>
        <p class="answer_text">
          Yes, this is often possible, and “circuit splitting” is becoming
          increasingly popular as more and more households electrify. This
          approach allows one 240V circuit to be split between two end uses. For
          example, if you have a 240V outlet in your garage for your electric
          dryer, a circuit-splitting device can let you power both your dryer
          and your EV from the same outlet. You’ll generally not be able to
          operate both end uses at the same time, and you can program the device
          regarding which end use takes priority. Circuit splitting can help you
          save money by avoiding the cost of running a new circuit. It can also
          sometimes help avoid the cost of an electrical service panel upgrade.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Do I need to install a wall-mounted EV charger?
        </h5>
        <p class="answer_text">
          No. Many people worry that they will have to put in an expensive
          charging station to obtain Level 2 charging at home. This is an
          option, but often not required. If a 240V electrical outlet is
          available, you can use a 240V portable charge cord to power your car,
          and these are available for about $200 - $400. An advantage to a
          portable charger is that you can easily take it with you if you move.
          It can also be handy for trips, allowing you to charge up if you find
          a compatible 240V electrical outlet along the way. The alternative of
          having a wall-mounted charging station installed is a more-expensive
          option, but generally offers some advantages like the ability to
          control charging, receive notifications, etc. through an app on your
          mobile device.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. Can I put an EV charger outside?</h5>
        <p class="answer_text">
          Yes, many EV charging stations are designed for outdoor use and are
          perfectly safe in rainy and snowy weather. If you can charge your car
          inside the garage, that’s generally preferable since an indoor charger
          will be protected from the elements and likely to last longer than one
          placed outside. Also, an inside charger will be more secure than one
          located outside.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Are special electric rates available for EV charging?
        </h5>
        <p class="answer_text">
          Many electric utilities have optional rates that are favorable for
          those with EVs. These are normally time-of-use rate programs that
          offer a lower price for electricity during off-peak periods, such as
          late at night. EV owners can save hundreds of dollars per year through
          such programs, so it’s worthwhile to check with your local utility
          about your rate options.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Are incentives available for home EV chargers?
        </h5>
        <p class="answer_text">
          Various electric utilities around the country provide rebates for
          Level 2 chargers. These often provide savings of $500 or more. A 30%
          federal tax credit, up to a maximum of $1,000, is also available, but
          only for certain rural and low-income areas.
        </p>
      </div>
    </div>
  </div>
</div>
