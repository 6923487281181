import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { MarketPlaceDetailsModel } from "../learn-more.model";
import { LearnMoreService } from "../learn-more.service";
import {
  WeatherStrippingAssumptionsModel,
  WeatherStrippingDetailsModel,
  WeatherStrippingFinancialModel,
  WeatherStrippingLearnMoreModel,
  WeatherStrippingTechnicalDetailsModel,
} from "./weather-strips-model";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { AppService } from "../../../app.service";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-weather-strips-details",
  templateUrl: "./weather-strips-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class WeatherStripsDetailsComponent
  implements OnInit, AfterContentChecked
{
  loader: boolean = true;
  icon: boolean = false;
  showIncentivesBreakdown = false;
  marketplaceDetails: MarketPlaceDetailsModel;
  displayMarketPlace: boolean;
  description: string;
  marketPlaceLink: string;
  linkId: string;
  readMore: boolean = true;
  readLess: boolean = false;
  totalIncentives: number;
  incentiveStatus: boolean;
  adviceCardStatus: boolean;
  weatherStripDetails: WeatherStrippingDetailsModel;
  assumptionDetails: WeatherStrippingAssumptionsModel;
  financialDetails: WeatherStrippingFinancialModel;
  technicalDetails: WeatherStrippingTechnicalDetailsModel;
  activeIndex: number = 0;
  tabIndex: number = 0;
  tabLabel: string;
  isLangSpanish: boolean;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  isFAQExpanded: boolean;
  favStatus: boolean;
  favIcon: string;
  learnMoreDetails: WeatherStrippingLearnMoreModel;
  showCash: boolean = true;
  showBlackLoader: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private LearnMoreService: LearnMoreService,
    private dashboardAPI: DashboardAPIService,
    private cdRef: ChangeDetectorRef,
    private appService: AppService
  ) {}

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.dashboardAPI
      .faqDetails()
      .pipe(take(1))
      .subscribe((list: SectionListModel) => {
        this.FAQTabsList = list.weatherStripping.faqList!;
      });
    this.marketplaceAPI();
    this.getWeatherStripDetails();
  }

  marketplaceAPI(): void {
    this.dashboardAPI
      .getMarketplaceDetails("weatherStripping")
      .pipe(take(1))
      .subscribe((data: MarketPlaceDetailsModel) => {
        this.marketplaceDetails = data;
        this.adviceCardStatus = data.expertAdvice;
        this.displayMarketPlace = this.marketplaceDetails.displayBox;
        this.linkId = this.marketplaceDetails.linkId;
        this.marketPlaceLink = this.marketplaceDetails.buttonLink;
        if (this.marketplaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketplaceDetails.linkId;
        }
      });
  }

  getWeatherStripDetails(): void {
    this.LearnMoreService.learnMoreInfo<WeatherStrippingDetailsModel>(
      "weatherStripping"
    )
      .pipe(take(1))
      .subscribe((data: WeatherStrippingDetailsModel) => {
        this.weatherStripDetails = data;
        this.assumptionDetails = data.assumptionDetails;
        this.financialDetails = data.financialDetails;
        this.technicalDetails = data.technicalDetails;
        this.incentiveStatus = this.financialDetails.costDetails.showIncentives;
        this.totalIncentives = this.financialDetails.costDetails.incentives;
        this.description = this.technicalDetails.weatherStrippingMoreDetails[0];
        this.favStatus =
          this.weatherStripDetails.learnMoreDetails.favoriteStatus;
        this.favIcon = this.LearnMoreService.updateFavoriteIcon(this.favStatus);
        this.showBlackLoader = this.loader = false;
      });
  }

  onReadContent(value: string): void {
    if (value === "readMore") {
      this.readMore = false;
      this.readLess = true;
      const spanElement = `<span class="db pt3 w100"> </span>`;
      this.description +=
        spanElement + this.technicalDetails.weatherStrippingMoreDetails[1];
    } else if (value === "readLess") {
      this.readMore = true;
      this.readLess = false;
      this.description = this.technicalDetails.weatherStrippingMoreDetails[0];
    }
  }

  handleActiveValue(data: string): void {
    if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon = true;
    }
  }

  faqExpansionClick(): void {
    this.icon = !this.icon;
  }

  handleFAQClick(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("moreInfo", "weatherStripping");
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/weather-stripping-faq-es/",
      "_blank"
    );
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    const sideNavElement = document.querySelector(".mat-sidenav-content");
    if (sideNavElement)
      sideNavElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo("weatherStripping", ownPlan)
      .pipe(take(1))
      .subscribe(() => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.LearnMoreService.updateFavoriteIcon(this.favStatus);
        this.getWeatherStripDetails();

        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardAPI.techMessage("weatherStripping", action);
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }
}
