import { Component, OnChanges } from "@angular/core";
import { Input } from "@angular/core";
import { LogoModel, RoofTypesModel } from "../../../rest-api/rest-api.model";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { AppService } from "../../../app.service";
import { Utils } from "../../../utils/util";
import { take } from "rxjs/operators";
@Component({
  selector: "yt-top-nav",
  templateUrl: "./top-nav.component.html",
  styleUrls: ["./top-nav.component.scss"],
})
export class TopNavComponent implements OnChanges {
  @Input() logo: LogoModel;
  @Input() vendorCode: string;
  @Input() language: RoofTypesModel[];
  selectedLanguage: string;
  currentLanguage: string;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private restAPI: RestAPIService,
    private appService: AppService,
    private utils: Utils
  ) {}

  ngOnChanges(): void {
    this.selectedLanguage = sessionStorage.getItem("language")!;
    if (!this.selectedLanguage) {
      this.selectedLanguage = this.language[0].value;
      sessionStorage.setItem("language", this.selectedLanguage);
    }
    this.currentLanguage = this.utils.updateCurrentLanguage(
      this.language,
      this.selectedLanguage
    );
    const lang = this.language.find(
      (lang) => lang.value === this.selectedLanguage
    );
    this.selectedLanguage = lang ? lang.value : "en";
    this.translate.use(this.selectedLanguage);
  }

  onClickLogo(link?: string): void {
    if (link) {
      window.open(link, "_blank");
    } else {
      window.open(
        !this.logo.customer ? this.logo.href : this.logo.customer.href
      );
    }
  }

  onLanguageChange(lang: string): void {
    this.appService.selectedLanguage.next(lang);
    sessionStorage.setItem("language", lang);
    this.selectedLanguage = lang;
    this.currentLanguage = this.utils.updateCurrentLanguage(
      this.language,
      this.selectedLanguage
    );
    this.translate.use(lang);
    if (this.restAPI.homeOwnerEmail) {
      this.restAPI
        .updateLanguage(lang)
        .pipe(take(1))
        .subscribe(
          (data) => {},
          (error) => {
            console.error(error);
          }
        );
    }
  }

  onEnergyCenter() {
    window.open("https://www.yellowtin.com/yt_faq_preview/", "_blank");
  }

  onContact() {
    window.open("https://www.yellowtin.com/yt-contact-us", "_blank");
  }

  onLearningHub() {
    if (this.language.length > 1) {
      if (this.selectedLanguage === "sp") {
        window.open(
          "https://www.yellowtin.com/clean-energy-information-center-es/",
          "_blank"
        );
      } else {
        this.router.navigateByUrl("/dashboard/learning-hub");
      }
    } else {
      this.router.navigateByUrl("/dashboard/learning-hub");
    }
  }
}
