import { Component, Input } from "@angular/core";

@Component({
  selector: "yt-solar-FAQ",
  templateUrl: "./solar-faq.component.html",
  styleUrls: ["../common-faq.scss"],
})
export class SolarFAQComponent {
  showReadMore: boolean = true;
  showReadLess: boolean = false;
  @Input() activeIndex: number;
  @Input() showAboutTech: boolean;

  handleExpansionPanel(index: number): void {
    this.activeIndex = this.activeIndex === index ? -1 : index;
  }

  isActive(index: number): boolean {
    return index === this.activeIndex;
  }

  onReadContent(value: string) {
    if (value === "readMore") {
      this.showReadMore = false;
      this.showReadLess = true;
    } else if (value === "readLess") {
      this.showReadMore = true;
      this.showReadLess = false;
    }
  }
}
