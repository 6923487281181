import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  ChangeDetectorRef,
  AfterContentChecked,
} from "@angular/core";
import * as Highcharts from "highcharts";
import { FormGroup, FormBuilder } from "@angular/forms";
import { TimeFrameEnum } from "../learn-more.enum";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { LearnMoreService } from "../learn-more.service";
import {
  MarketPlaceDetailsModel,
  LearnMoreRequestModel,
  BarChartDataModel,
} from "../learn-more.model";
import {
  SolarDetailsModel,
  SolarFinancialModel,
  SolarTechnicalModel,
  SolarLearnMoreModel,
  SolarAssumptionModel,
  SolarCashModel,
  SolarLoanModel,
  SolarLeaseModel,
} from "./solar-details.model";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { AppService } from "../../../app.service";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { Utils } from "../../../utils/util";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-solar-details",
  templateUrl: "solar-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class SolarDetailsComponent implements OnInit, AfterContentChecked {
  showFinance: boolean;
  icon = false;
  icon1 = false;
  solSavings: number;
  panelOpenState = false;
  selected: TimeFrameEnum;
  solarViewDetails: SolarDetailsModel;
  financialDetails: SolarFinancialModel;
  technicalDetails: SolarTechnicalModel;
  learnMoreDetails: SolarLearnMoreModel;
  assumptionDetails: SolarAssumptionModel;
  marketPlaceDetails: MarketPlaceDetailsModel;
  solarCash: SolarCashModel;
  solarLoan: SolarLoanModel;
  solarLease: SolarLeaseModel;
  cash: boolean;
  loan: boolean;
  lease: boolean;
  twentyYear = false;
  oneYear = true;
  oneMonth = false;
  tenYear = false;
  panelEfficiency: boolean;
  panelModel: boolean;
  showIncentivesStatus = false;
  totalIncentives: number;
  payBackYear: string;
  solarForm: FormGroup;
  loader = true;
  financeValue: string;
  paymentTypeLabel: string;
  financial = true;
  haveResponse = false;
  paybackPeriod: number;
  displayPayback: boolean;
  displayPaybackGTTwenty: boolean;
  displayMarketPlaceBox: boolean;
  marketPlaceLink: string;
  chartOptions1: Highcharts.Options = {};
  showIncentiveBreakdown: boolean;
  showCompareFinancial = false;
  errorMessage: string;
  linkId: string;
  timeFrame: string;
  roofShadeList: DropDownModel[];
  roofSlopeList: DropDownModel[];
  financeList: DropDownModel[];
  adviceCardStatus: boolean;
  timeSegmentList: DropDownModel[];
  tabLabel: string;
  tabIndex: number = 0;
  isLangSpanish: boolean;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  isTechnicalExpanded: boolean;
  isFAQExpanded: boolean;
  favStatus: boolean;
  favIcon: string;
  assumptionLabel: string;
  showPriceAfterIncentives: boolean = false;
  showLoan: boolean = false;
  upfrontCost: number;
  downPayment: number;
  incentives: number;
  timeoutId: any;
  showBlackLoader: boolean;

  @ViewChild("container", { read: ElementRef }) container: ElementRef;
  @ViewChild("loaderButton", { read: ElementRef }) saveButton: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private fb: FormBuilder,
    private dashboardAPI: DashboardAPIService,
    private learnMoreService: LearnMoreService,
    private cdRef: ChangeDetectorRef,
    private appService: AppService,
    private utils: Utils
  ) {
    this.roofShadeList = this.learnMoreService.roofShadeList;
    this.roofSlopeList = this.learnMoreService.roofSlopeList;
    this.financeList = this.learnMoreService.financeList;
    this.timeSegmentList = this.learnMoreService.timeSegmentList;
    this.solarForm = this.fb.group({
      finance: ["cash"],
      roofSlope: [""],
      roofShade: [""],
    });
  }

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.setTimeSelector(this.dashboardAPI.timeFrame);
    this.getSolarViewDetails(this.selected);
    this.dashboardAPI
      .faqDetails()
      .pipe(take(1))
      .subscribe((list: SectionListModel) => {
        this.FAQTabsList = list.solar.faqList!;
      });
    this.dashboardAPI
      .getMarketplaceDetails("solar")
      .pipe(take(1))
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails = response;
        this.adviceCardStatus = response.expertAdvice;
        this.displayMarketPlaceBox = this.marketPlaceDetails.displayBox;
        this.marketPlaceLink = this.marketPlaceDetails.buttonLink;
        if (this.marketPlaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketPlaceDetails.linkId;
        }
      });
  }

  handleActiveValue(data: string): void {
    if (data === "details") {
      this.isTechnicalExpanded = true;
      this.icon = true;
    } else if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon1 = true;
    }
  }

  expansionPanelClick(): void {
    this.icon = !this.icon;
  }

  faqExpansionClick(): void {
    this.icon1 = !this.icon1;
  }

  getSolarViewDetails(period: TimeFrameEnum): void {
    this.learnMoreService
      .learnMoreInfo<SolarDetailsModel>("solar")
      .pipe(take(1))
      .subscribe((response: SolarDetailsModel) => {
        this.haveResponse = true;
        this.solarViewDetails = response;
        this.technicalDetails = this.solarViewDetails.technicalDetails;
        this.financialDetails = this.solarViewDetails.financialDetails;
        this.learnMoreDetails = this.solarViewDetails.learnMoreDetails;
        this.favStatus = this.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        if (this.learnMoreDetails.errorMessage)
          this.errorMessage = this.learnMoreDetails.errorMessage;
        this.assumptionDetails = this.solarViewDetails.assumptionsDetails;
        this.solarCash = this.financialDetails.solarCost.compareScreen.cash;
        this.solarLoan = this.financialDetails.solarCost.compareScreen.loan;
        this.solarLease = this.financialDetails.solarCost.compareScreen.lease;
        this.totalIncentives = this.financialDetails.solarCost.incentives;
        this.showIncentivesStatus =
          this.financialDetails.solarCost.showIncentives;
        this.showLoan = !!this.solarLoan;
        this.upfrontCost = this.financialDetails.solarCost.upfrontCost;
        this.downPayment = this.financialDetails.solarCost.downPayment;
        this.incentives = this.financialDetails.solarCost.incentives;
        this.paybackPeriod = this.financialDetails.solarPayback.paybackPeriod;
        this.displayPayback = this.financialDetails.solarPayback.displayPayback;
        this.displayPaybackGTTwenty =
          this.financialDetails.solarPayback.displayPaybackGTTwenty;
        this.financeValue = this.learnMoreDetails.financial;
        this.assumptionLabel = this.utils.toTitleCase(this.financeValue);
        this.paymentTypeLabel = this.dashboardAPI.getPaymentTypeLabel(
          this.financeValue
        );
        this.showFinance =
          this.dashboardAPI.vendorDetails.solutionType !== "vendor";

        if (this.displayPayback) {
          if (this.displayPaybackGTTwenty) {
            this.payBackYear = "greater than 20 years";
          } else {
            this.payBackYear = this.paybackPeriod + " years";
          }
        }
        this.solarFormDetails = this.learnMoreDetails;
        this.updateChart(period);
        this.cash = this.financeValue === "cash";
        this.loan = this.financeValue === "loan";
        this.lease = this.financeValue === "lease";
        this.showBlackLoader = this.loader = false;
      });
  }

  updateChart(period: TimeFrameEnum): void {
    this.setTimeSelector(period);
    if (period === TimeFrameEnum.TwentyYear) {
      let electricityCostwithoutSolar_TY;
      let electricityCostwithSolar_TY;
      let solarSavings_TY;

      electricityCostwithoutSolar_TY = this.setEnergyCostValue(
        this.financialDetails.solarSavings.withoutSolar.twentyYear
      );
      electricityCostwithSolar_TY = this.setEnergyCostValue(
        this.financialDetails.solarSavings.withSolar.twentyYear
      );
      solarSavings_TY = this.financialDetails.solarSavings.total.twentyYear;

      let dataInputs: BarChartDataModel[] = [
        { y: electricityCostwithSolar_TY, color: "#DFE154" },
        { y: electricityCostwithoutSolar_TY, color: "#414141" },
      ];
      this.solSavings = solarSavings_TY;
      this.updateChartOptions(dataInputs);
    }
    if (period === TimeFrameEnum.TenYear) {
      let electricityCostWithoutSolar_TENY;
      let electricityCostWithSolar_TENY;
      let solarSavings_TENY;

      electricityCostWithSolar_TENY = this.setEnergyCostValue(
        this.financialDetails.solarSavings.withSolar.tenYear
      );
      electricityCostWithoutSolar_TENY = this.setEnergyCostValue(
        this.financialDetails.solarSavings.withoutSolar.tenYear
      );
      solarSavings_TENY = this.financialDetails.solarSavings.total.tenYear;

      let dataInputs: BarChartDataModel[] = [
        { y: electricityCostWithSolar_TENY, color: "#DFE154" },
        { y: electricityCostWithoutSolar_TENY, color: "#414141" },
      ];

      this.solSavings = solarSavings_TENY;
      this.updateChartOptions(dataInputs);
    }
    if (period === TimeFrameEnum.OneYear) {
      let electricityCostwithoutSolar_OY;
      let electricityCostwithSolar_OY;
      let solarSaviings_OY;
      electricityCostwithoutSolar_OY = this.setEnergyCostValue(
        this.financialDetails.solarSavings.withoutSolar.oneYear
      );
      electricityCostwithSolar_OY = this.setEnergyCostValue(
        this.financialDetails.solarSavings.withSolar.oneYear
      );
      solarSaviings_OY = this.financialDetails.solarSavings.total.oneYear;

      let dataInputs: BarChartDataModel[] = [
        { y: electricityCostwithSolar_OY, color: "#DFE154" },
        { y: electricityCostwithoutSolar_OY, color: "#414141" },
      ];
      this.solSavings = solarSaviings_OY;
      this.updateChartOptions(dataInputs);
    }
    if (period === TimeFrameEnum.OneMonth) {
      let electricityCostwithoutSolar_OM;
      let electricityCostwithSolar_OM;
      let solarSavings_OM;
      solarSavings_OM = this.financialDetails.solarSavings.total.oneMonth;
      electricityCostwithoutSolar_OM = this.setEnergyCostValue(
        this.financialDetails.solarSavings.withoutSolar.oneMonth
      );
      electricityCostwithSolar_OM = this.setEnergyCostValue(
        this.financialDetails.solarSavings.withSolar.oneMonth
      );

      let dataInputs: BarChartDataModel[] = [
        { y: electricityCostwithSolar_OM, color: "#DFE154" },
        { y: electricityCostwithoutSolar_OM, color: "#414141" },
      ];
      this.updateChartOptions(dataInputs);
      this.solSavings = solarSavings_OM;
    }
  }

  setEnergyCostValue(value: Number): Number {
    return value === 0 ? 0.0001 : value;
  }

  setTimeSelector(period: TimeFrameEnum): void {
    this.selected = period;
    if (this.selected) {
      this.timeFrame = this.learnMoreService
        .renderTimeFrame(this.selected)
        .toLowerCase();
    }
  }

  updateChartOptions(inputData: BarChartDataModel[]): void {
    let filteredInputData: BarChartDataModel[] = [];
    let i = 0;
    for (let entry of inputData) {
      if (entry.y != 0) {
        filteredInputData[i] = entry;
        i++;
      }
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    this.chartOptions1 = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        labels: {
          style: {
            color: "#333333",
            fontSize: "16px",
            fontFamily: "Open sans",
          },
        },
        categories: [
          `Electricity cost <br> with solar`,
          `Electricity cost <br> without solar`,
        ],
        gridLineWidth: 0,
        lineWidth: 1,
        tickWidth: 0,
      },
      yAxis: {
        tickAmount: 8,
        tickWidth: 0,
        gridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: null,
        },
        labels: {
          style: {
            color: "#333333",
            fontSize: "12px",
            fontFamily: "Open sans",
          },
          formatter: function (this) {
            if (Number(this.value) >= 1000) {
              return "$" + Number(this.value) / 1000 + "K";
            } else {
              return "$" + this.value;
            }
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          pointWidth: 50,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "Open sans",
            },
            formatter: function (this) {
              if (this.point.y && this.point.y < 1) {
                return "$0";
              } else {
                return (
                  "$" +
                  this.point.y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }
            },
            animation: {
              duration: 20,
            },
          },
        },
        series: {
          borderWidth: 0,
          animation: {
            duration: 1500,
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                className: "small-chart",
              },
            },
          },
        ],
      },
      series: [
        {
          type: "bar",
          showInLegend: false,
          data: filteredInputData,
        },
      ],
    };
    Highcharts.chart(this.container.nativeElement, this.chartOptions1);
  }

  onSaveDetails(finance?: string): void {
    this.haveResponse = false;
    if (finance) {
      this.solarForm.get("finance")?.setValue(finance);
      this.financeValue = finance;
    } else {
      this.financeValue = this.solarForm.get("finance")?.value;
    }
    if (
      this.learnMoreService.isFormEditted(
        this.solarData.solar!,
        this.learnMoreDetails
      )
    ) {
      this.saveButton.nativeElement.innerHTML =
        'Saving... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
      this.dashboardAPI.dashboardDetailsAnalytics("save", "solar");
      this.learnMoreService
        .updateLearnMoreInfo("solar", this.solarData)
        .pipe(take(1))
        .subscribe((response: LearnMoreRequestModel) => {
          this.timeoutId = setTimeout(() => {
            this.saveButton.nativeElement.innerHTML = "SAVE DETAILS";
            this.getSolarViewDetails(this.selected);
          }, 800);
        });
    }
  }

  get solarData(): LearnMoreRequestModel {
    return {
      solar: {
        financial: this.solarForm.get("finance")?.value,
        roofSlope: this.solarForm.get("roofSlope")?.value,
        roofShade: this.solarForm.get("roofShade")?.value,
      },
    };
  }

  set solarFormDetails(details: SolarLearnMoreModel) {
    this.solarForm.get("finance")?.setValue(details.financial);
    this.solarForm.get("roofSlope")?.setValue(details.roofSlope);
    this.solarForm.get("roofShade")?.setValue(details.roofShade);
  }

  handleFAQClick(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("moreInfo", "solar");
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/rooftop-solar-faq-es/",
      "_blank"
    );
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo("solar", ownPlan)
      .pipe(take(1))
      .subscribe(() => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getSolarViewDetails(this.selected);
        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardAPI.techMessage("solar", action);
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
