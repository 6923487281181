<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="pb_30 yt_maxWidth" id="techAlert" *ngIf="errorMessage">
      <div class="solar_alert">
        <div class="flex justify-between popUp_padding">
          <span class="padding_right">
            {{ errorMessage }}
          </span>
          <span (click)="errorMessage = ''" class="fa fa-times pointer"></span>
        </div>
      </div>
    </div>
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">Rooftop Solar</div>

      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isSavings]="true"
        [isDescription]="false"
        [isSettings]="true"
        [isDetails]="true"
        [isAssumptions]="true"
        [isFaq]="true"
        [techParameter]="'solar'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>

      <div
        class="yt-text"
        [innerHTML]="
          'homeOwenerDashboard.learnMore.solar.paragraph' | translate
        "
      ></div>
      <div *ngIf="!isLangSpanish" id="aboutTechnology">
        <yt-solar-FAQ [showAboutTech]="true"> </yt-solar-FAQ>
      </div>
      <div class="faq_mobile">
        <button
          class="favorite_btn"
          mat-raised-button
          color="accent"
          (click)="onFavorite()"
        >
          <mat-icon
            class="favorite_icon"
            [ngClass]="{ favorite_icon_color: favStatus }"
            >{{ favIcon }}</mat-icon
          >
          FAVORITE
        </button>

        <button
          *ngIf="isLangSpanish"
          class="faq_button"
          mat-raised-button
          color="accent"
          (click)="handleFAQClick()"
        >
          MORE INFO
        </button>
      </div>

      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="assets/lifestyle/solar.svg"
              alt="technology icon"
              loading="lazy"
              [ngStyle]="{ height: !displayMarketPlaceBox ? '160px' : '250px' }"
            />
          </div>
          <button
            (click)="onFavorite()"
            class="favorite_btn mt_20"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>

          <button
            *ngIf="isLangSpanish"
            class="faq_button mt_20"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>

        <div class="width-box" id="cost" *ngIf="financialDetails">
          <yt-cost-rendering
            *ngIf="financialDetails"
            [financialDetails]="financialDetails.solarCost"
            [paymentTypeLabel]="
              cash
                ? 'Net cost'
                : loan
                ? 'Monthly loan payment'
                : 'Monthly lease payment'
            "
            [showCash]="cash"
            [showLease]="lease"
            [showLoan]="loan"
            [show_Upfront_price]="true"
            [showIncentivesStatus]="showIncentivesStatus"
            [lease]="!cash && !loan"
            [showPrice]="showPriceAfterIncentives"
            [showIncentiveBreakdown]="showIncentiveBreakdown"
            [techName]="'solar'"
            [heading]="'Incentives Breakdown'"
            [netCost]="'Total'"
            (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
            [upfrontCostTooltipText]="
              'Includes equipment, labor, permits, etc. Excludes any cost for main service panel upgrade (which is sometimes needed).'
            "
            [netCostTooltipText]="'Net cost = Upfront cost - Incentives'"
          ></yt-cost-rendering>

          <yt-market-place
            [tech_name]="'solar'"
            [linkId]="linkId"
            [marketPlaceDetails]="marketPlaceDetails"
            *ngIf="marketPlaceDetails && displayMarketPlaceBox"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'solar'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div class="bottom_section w-100">
        <div class="left_section">
          <div class="highChart_card highChart_card_mobile">
            <div class="details_header pb_14">savings</div>
            <div class="w-100 toggle_buttons">
              <mat-button-toggle-group
                [(value)]="selected"
                (change)="updateChart($event.value)"
              >
                <mat-button-toggle
                  *ngFor="let time of timeSegmentList"
                  [value]="time.value"
                >
                  <span class="toggle_label">
                    {{ time.viewValue?.toUpperCase() }}
                  </span>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="w-100 savings_text">
              Your
              {{ timeFrame }}
              projected
              <span
                class="tooltip_text_border pointer"
                matTooltip="The energy savings does not include the upfront cost of the solar system."
              >
                energy savings
              </span>
              is
              <span class="savings_value">{{
                solSavings | currency : "$" : "symbol" : "1.0-0"
              }}</span>
              <span *ngIf="displayPayback">
                with a
                <span
                  class="tooltip_text_border pointer"
                  matTooltip="Simple payback period = Net cost / Annual energy cost savings."
                >
                  payback
                </span>
                of <span>{{ payBackYear }}</span>
              </span>
            </div>
            <div class="w-100 chart_section">
              <div class="mobile_chart_text">
                To view and compare graphs of energy costs, please login to your
                dashboard using a desktop.
              </div>
            </div>
          </div>
          <div class="details_card" [formGroup]="solarForm" id="settings">
            <div class="details_header">PERSONALIZE</div>
            <div
              class="w-100 assumption_row form_label"
              *ngIf="solarViewDetails"
            >
              <div>System Size</div>
              <div>
                {{ technicalDetails.systemDetails.systemSize | number }}
                kW
              </div>
            </div>
            <div
              class="w-100 assumption_row form_label"
              *ngIf="solarViewDetails"
            >
              <div>Number Of Panels</div>
              <div>
                {{ technicalDetails.systemDetails.panelCount | number }}
              </div>
            </div>
            <div
              class="w-100 assumption_row form_label"
              *ngIf="solarViewDetails"
            >
              <div>Panel wattage</div>
              <div>
                {{ technicalDetails.systemDetails.panelWattage | number }}
                W
              </div>
            </div>
            <div class="form_label">Roof Slope</div>
            <div style="position: relative">
              <mat-form-field
                class="w-100"
                floatLabel="auto"
                hideRequiredMarker="true"
                appearance="outline"
              >
                <mat-select formControlName="roofSlope">
                  <mat-option
                    *ngFor="let slope of roofSlopeList"
                    [value]="slope.value"
                    >{{ slope.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form_label">Roof Shade</div>
            <div style="position: relative">
              <mat-form-field
                class="w-100"
                floatLabel="auto"
                hideRequiredMarker="true"
                appearance="outline"
              >
                <mat-select formControlName="roofShade">
                  <mat-option
                    *ngFor="let shade of roofShadeList"
                    [value]="shade.value"
                    >{{ shade.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="showFinance">
              <div class="form_label flex justify-between items-baseline">
                Financing
                <span class="help_me_find" (click)="showCompareFinancial = true"
                  >Compare</span
                >
              </div>
              <div style="position: relative">
                <mat-form-field
                  class="w-100"
                  floatLabel="auto"
                  hideRequiredMarker="true"
                  appearance="outline"
                >
                  <mat-select formControlName="finance">
                    <mat-option
                      *ngFor="let finance of financeList"
                      [value]="finance.value"
                      >{{ finance.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div
              class="save_button yt_pt2-75"
              [ngClass]="{
                '': !displayMarketPlaceBox,
                'ghost-button': displayMarketPlaceBox,
                disable_button: solarForm.invalid
              }"
            >
              <button
                mat-raised-button
                (click)="onSaveDetails()"
                #loaderButton
                [disabled]="solarForm.invalid"
                color="accent"
              >
                SAVE DETAILS
              </button>
            </div>
          </div>
          <yt-assumption-card
            [assumptionDetails]="assumptionDetails"
            [assumptionLabel]="assumptionLabel"
            [showLoanLease]="!cash"
          ></yt-assumption-card>
        </div>
        <div class="right_section">
          <div class="highChart_card" id="savings">
            <div class="details_header pb_14">savings</div>

            <app-Time-Rendering
              [selected]="selected"
              (selectionChange)="updateChart($event)"
            >
            </app-Time-Rendering>

            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span>
              projected
              <span
                class="tooltip_text_border pointer"
                matTooltip="The energy savings does not include the upfront cost of the solar system."
              >
                energy savings
              </span>
              is
              <span class="savings_value">{{
                solSavings | currency : "$" : "symbol" : "1.0-0"
              }}</span>
              <span *ngIf="displayPayback">
                with a
                <span
                  class="tooltip_text_border pointer"
                  matTooltip="Simple payback period = Net cost / Annual energy cost savings."
                >
                  payback
                </span>
                of <span>{{ payBackYear }}</span>
              </span>
            </div>
            <div class="formula_text">
              Energy savings &nbsp; = &nbsp; Electricity cost without solar -
              Electricity cost with solar
            </div>
            <div class="w-100 chart_section">
              <div class="hide_chart">
                <div class="chart" #container></div>
              </div>
            </div>
            <div class="w-100 chart_description_text">
              While the actual savings from a rooftop solar systems depends on
              many different factors, solar can cut your electric bills and help
              insulate you from rate hikes.
            </div>
          </div>

          <div class="summary_card" id="details">
            <mat-expansion-panel
              (closed)="isTechnicalExpanded = false"
              [expanded]="isTechnicalExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="expansionPanelClick()">
                <mat-panel-title class="flex items-center">
                  Technical Details &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="solar_panel_body w-100" style="display: block">
                <div class="solar_panel_body_row">
                  <div class="left_side_values">
                    <div class="label_heading w-100">Solar System</div>
                    <div class="left_label_content_box">
                      <div class="label_content_row">
                        <span
                          class="tooltip_text pointer"
                          matTooltip="This includes your current usage and any stated changes to your energy choices (such as obtaining an electric vehicle in the future)."
                        >
                          Annual usage without solar
                        </span>
                        <div *ngIf="solarViewDetails">
                          {{
                            technicalDetails.systemDetails
                              .annualUsageWithoutSolar | number
                          }}
                          kWh
                        </div>
                      </div>
                      <div class="label_content_row row_paddingtop">
                        <span
                          class="tooltip_text pointer"
                          matTooltip="This is the amount of energy your system will produce in its first year. Production estimates are a key factor in calculating your 20-year savings.
                          "
                        >
                          Annual solar production
                        </span>
                        <div *ngIf="solarViewDetails">
                          {{
                            technicalDetails.systemDetails.annualSolarProduction
                              | number
                          }}
                          kWh
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

          <div class="faq_card w-100" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="faqExpansionClick()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon1 ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-solar-FAQ
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-solar-FAQ>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div
        class="link_text back_to_top"
        (click)="scrollToTop()"
        *ngIf="!isLangSpanish"
      >
        <mat-icon class="pointer mat_color">keyboard_arrow_up</mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<!-- Incentives Breakdown Dialogue box-->
<yt-breakdown-popup
  *ngIf="showIncentiveBreakdown && haveResponse"
  (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
  [heading]="'Incentives Breakdown'"
  [netCost]="'Total'"
  [techName]="'solar'"
>
</yt-breakdown-popup>

<!-- Compare Financial -->
<yt-compare-financial
  *ngIf="haveResponse && showCompareFinancial"
  [solarDetails]="financialDetails"
  [solar]="financial"
  [financeValue]="financeValue"
  (inputFinance)="onSaveDetails($event)"
  (compare)="showCompareFinancial = false"
>
</yt-compare-financial>

<!-- loader -->
<yt-loader
  [blackLoader]="showBlackLoader"
  *ngIf="loader || showBlackLoader"
  top="0"
  [relative]="true"
></yt-loader>
