<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">Heat Pump Water Heater</div>

      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isSavings]="true"
        [isDescription]="false"
        [isSettings]="true"
        [isDetails]="true"
        [isAssumptions]="!!assumptionDetails"
        [isFaq]="true"
        [techParameter]="'hpwh'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>
      <div
        class="yt-text"
        [innerHTML]="'homeOwenerDashboard.learnMore.hpwh.paragraph' | translate"
      ></div>

      <div *ngIf="!isLangSpanish" id="aboutTechnology">
        <yt-hpwh-FAQ [showAboutTech]="true"></yt-hpwh-FAQ>
      </div>

      <div class="faq_mobile">
        <button
          (click)="onFavorite()"
          class="favorite_btn"
          mat-raised-button
          color="accent"
        >
          <mat-icon
            class="favorite_icon"
            [ngClass]="{ favorite_icon_color: favStatus }"
            >{{ favIcon }}</mat-icon
          >FAVORITE
        </button>
        <button
          *ngIf="isLangSpanish"
          class="faq_button"
          mat-raised-button
          color="accent"
          (click)="handleFAQClick()"
        >
          MORE INFO
        </button>
      </div>
      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="assets/lifestyle/hpwh.svg"
              alt="technology icon"
              [ngStyle]="{ height: !displayMarketPlaceBox ? '160px' : '250px' }"
            />
          </div>
          <button
            (click)="onFavorite()"
            class="favorite_btn mt_20"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            class="faq_button mt_20"
            *ngIf="isLangSpanish"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>

        <div class="width-box" id="cost" *ngIf="financialDetails">
          <yt-cost-rendering
            [financialDetails]="financialDetails.hpwhCost"
            [paymentTypeLabel]="'Net cost'"
            [showCash]="showCash"
            [show_Upfront_price]="true"
            [showIncentivesStatus]="showIncentivesStatus"
            [lease]="false"
            [showIncentiveBreakdown]="showIncentiveBreakdown"
            [techName]="'hpwh'"
            [heading]="'Incentives Breakdown'"
            [netCost]="'Total'"
            (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
            [upfrontCostTooltipText]="
              'Includes equipment, labor, permits, etc. Excludes charges for main service panel upgrade (which is sometimes needed).'
            "
            [netCostTooltipText]="'Net cost = Upfront cost - Incentives'"
          >
          </yt-cost-rendering>

          <yt-market-place
            [tech_name]="'hpwh'"
            [linkId]="linkId"
            [marketPlaceDetails]="marketPlaceDetails"
            *ngIf="marketPlaceDetails && displayMarketPlaceBox"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'hpwh'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div class="bottom_section w-100">
        <div class="left_section">
          <div class="highChart_card highChart_card_mobile">
            <div class="details_header pb_14">savings</div>
            <div class="w-100 toggle_buttons">
              <mat-button-toggle-group
                [(value)]="selected"
                (change)="updateChart($event.value)"
              >
                <mat-button-toggle
                  *ngFor="let time of timeSegmentList"
                  [value]="time.value"
                >
                  <span class="toggle_label">{{
                    time.viewValue?.toUpperCase()
                  }}</span></mat-button-toggle
                >
              </mat-button-toggle-group>
            </div>
            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="Water heating with natural gas is cheaper than heating with an electric water heater. However, switching from natural gas reduces carbon emissions."
              >
                energy savings
              </span>
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span
                class="savings_value"
                [ngClass]="{ value_color: hpwh_Savings < 0 }"
                >{{ hpwh_Savings | currency : "$" : "symbol" : "1.0-0" }}</span
              >
              <span *ngIf="displayPayback">
                with a
                <span
                  class="tooltip_text_border pointer"
                  matTooltip="This is a simple payback based on the net cost, annual energy cost savings, value based on remaining life of your current water heater and the cost of additional solar panels (if you added solar in your selections) to power your heat pump water heater."
                >
                  payback</span
                >
                of
                <span>{{ payBackYear }}</span>
              </span>
            </div>
            <div class="w-100 chart_section">
              <div class="mobile_chart_text">
                To view and compare graphs of energy costs, please login to your
                dashboard using a desktop.
              </div>
            </div>
          </div>

          <div class="details_card" [formGroup]="hpwhForm" id="settings">
            <div class="details_header">PERSONALIZE</div>
            <div *ngIf="!isPortable">
              <div class="form_label flex justify-between items-baseline">
                Make & Model
                <span
                  class="help_me_find"
                  (click)="openHelpMeChoose()"
                  *ngIf="showHelpMeChoose"
                >
                  <span class="helpme">Help me choose</span>
                  <span class="choose">Choose</span>
                </span>
              </div>
              <mat-form-field
                floatLabel="auto"
                hideRequiredMarker="true"
                class="w-100 formfield_padding"
                appearance="outline"
              >
                <mat-select placeholder="Make & Model" formControlName="model">
                  <mat-option
                    *ngFor="let makeAndModel of makeAndModelList"
                    [value]="makeAndModel.mnfAndModel"
                    [matTooltip]="makeAndModel.mnfAndModel"
                    >{{ makeAndModel.mnfAndModel }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form_label">No. of people in household</div>
            <mat-form-field
              class="w-100 formfield_padding"
              floatLabel="auto"
              appearance="outline"
            >
              <input
                matInput
                formControlName="howManyPeople"
                placeholder="Enter 1-10"
                autocomplete="off"
                maxlength="3"
              />
              <mat-error *ngIf="isNoOfPeopleOutOfRange">
                Please enter value between 1-10</mat-error
              >
              <mat-error *ngIf="isNoOfPeopleRequired"> Required! </mat-error>
            </mat-form-field>
            <div class="form_label baseline">Current water heater type</div>
            <mat-form-field
              class="w-100 formfield_padding"
              floatLabel="auto"
              appearance="outline"
            >
              <mat-select
                formControlName="currentHeater"
                placeholder="Appliances"
              >
                <mat-option *ngFor="let type of hpwhTypes" [value]="type.value">
                  {{ type.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="form_label">Age of current water heater</div>
            <mat-form-field
              class="w-100 formfield_padding"
              floatLabel="auto"
              appearance="outline"
            >
              <mat-select formControlName="age" placeholder="Select Age">
                <mat-option
                  *ngFor="let range of ageList"
                  [value]="range.value"
                  >{{ range.viewValue }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="isAgeRequired"> Required! </mat-error>
            </mat-form-field>
            <div
              class="save_button"
              [ngClass]="{
                '': !displayMarketPlaceBox,
                'ghost-button': displayMarketPlaceBox,
                disable_button: hpwhForm.invalid
              }"
            >
              <button
                mat-raised-button
                color="accent"
                #loaderButton
                [disabled]="hpwhForm.invalid || isMNFAndModelListAbsent()"
                (click)="onSaveDetails(true)"
              >
                SAVE DETAILS
              </button>
            </div>
          </div>

          <yt-assumption-card
            [assumptionDetails]="assumptionDetails"
          ></yt-assumption-card>
        </div>

        <div class="right_section">
          <div class="highChart_card" id="savings">
            <div class="details_header pb_14">savings</div>

            <app-Time-Rendering
              [selected]="selected"
              (selectionChange)="updateChart($event)"
            >
            </app-Time-Rendering>

            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="Water heating with natural gas is cheaper than heating with an electric water heater in some situations. However, switching from natural gas reduces carbon emissions."
              >
                energy savings
              </span>
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span
                class="savings_value"
                [ngClass]="{ value_color: hpwh_Savings < 0 }"
                >{{ hpwh_Savings | currency : "$" : "symbol" : "1.0-0" }}</span
              >
              <span *ngIf="displayPayback">
                with a
                <span
                  class="tooltip_text_border pointer"
                  matTooltip="This is a simple payback based on the net cost, annual energy cost savings, value based on remaining life of your current water heater and the cost of additional solar panels (if you added solar in your selections) to power your heat pump water heater."
                >
                  payback</span
                >
                of
                <span>{{ payBackYear }}</span>
              </span>
            </div>
            <div class="formula_text">
              Energy savings &nbsp; = &nbsp; Energy cost with
              <span style="text-transform: lowercase">{{ HPWHType }}</span> -
              Energy cost with heat pump water heater
            </div>
            <div class="w-100 chart_section">
              <div class="hide_chart">
                <div class="chart" #container></div>
              </div>
            </div>
            <div class="w-100 chart_description_text">{{ textUnderChart }}</div>
          </div>
          <div class="summary_card" id="details">
            <mat-expansion-panel
              (closed)="isTechnicalExpanded = false"
              [expanded]="isTechnicalExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="expansionPanelClick()">
                <mat-panel-title class="flex items-center">
                  Technical Details &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <div class="left_side_values">
                  <div class="label_heading w-100">
                    Recommended Water Heater
                  </div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <div>Type</div>
                      <div *ngIf="hpwhViewDetails">
                        {{ technicalDetails.recommendedHpwh.hpwhType }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Tank capacity</div>
                      <div *ngIf="hpwhViewDetails">
                        {{ technicalDetails.recommendedHpwh.tankCapacity }}
                        gal
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Energy factor</div>
                      <div *ngIf="hpwhViewDetails">
                        {{ technicalDetails.recommendedHpwh.energyFactor }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Manufacturer</div>
                      <div *ngIf="hpwhViewDetails">
                        {{ technicalDetails.recommendedHpwh.manufacturer }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Circuit breaker size</div>
                      <div *ngIf="hpwhViewDetails">
                        {{
                          technicalDetails.recommendedHpwh.circuitBreakerSize
                        }}
                        amp
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Warranty</div>
                      <div *ngIf="hpwhViewDetails">
                        {{ technicalDetails.recommendedHpwh.warranty }}
                        years
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right_side_values">
                  <div class="label_heading w-100">Current Water Heater</div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <div>Type</div>
                      <div *ngIf="hpwhViewDetails">
                        {{ technicalDetails.currentHpwh.hpwhType }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Age</div>
                      <div *ngIf="hpwhViewDetails">
                        {{ technicalDetails.currentHpwh.hpwhAge }}
                        years
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Expected remaining life</div>
                      <div *ngIf="hpwhViewDetails">
                        {{ technicalDetails.currentHpwh.remainingLife }}
                        years
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

          <div class="faq_card w-100" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="faqExpansionClick()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon1 ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-hpwh-FAQ
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-hpwh-FAQ>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div
        class="link_text back_to_top"
        (click)="scrollToTop()"
        *ngIf="!isLangSpanish"
      >
        <mat-icon class="pointer mat_color">keyboard_arrow_up</mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<yt-breakdown-popup
  *ngIf="showIncentiveBreakdown && haveResponse"
  (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
  [heading]="'Incentives Breakdown'"
  [netCost]="'Total'"
  [techName]="'hpwh'"
>
</yt-breakdown-popup>

<!-- loader -->
<yt-loader
  [blackLoader]="showBlackLoader"
  *ngIf="loader || showBlackLoader"
  top="0"
  [relative]="true"
></yt-loader>
