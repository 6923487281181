import { AppService } from "./../app.service";
import {
  UserDetailsAPIPutModel,
  UpgradeAPIPutModel,
  SolarVendorModel,
  LogoModel,
  RequestMessageModel,
} from "./rest-api.model";
import {
  RoofTopPostModel,
  RoofTopPageModel,
} from "../homeowner-product/roof-top/roof-top.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { UpgradesModel } from "../homeowner-product/energy-changes/energy-changes.model";
import { UserDetailsDataModel } from "../homeowner-product/my-info/my-info.model";
import {
  EnergyProvidersModel,
  CCEDetailsModel,
  UtilityProviderModel,
  EnergyStatusModel,
} from "../shared/energy-info/energy-info.model";
import {
  EnergyChoicesModel,
  MNFAndModelListResponse,
  MNFAndModel,
} from "../homeowner-product/energy-choices/energy-choices.model";
import { environment } from "../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";

@Injectable()
export class RestAPIService {
  ROOF_TOP_QA_JSON_URL = "assets/json/roof-top-QA.json";
  public readonly API_END_POINT = environment.API_URL;
  public readonly DASHBOARDAPI_END_POINT = environment.BASE_URL;
  public readonly HOME_OWNERS = "homeOwners/";
  public readonly SOLAR_VENDORS = "solarVendors/";
  public readonly updateUserDetailsUrl =
    this.API_END_POINT + "v3/" + this.HOME_OWNERS;
  public readonly MULTI_FILE_UPLOAD = "files/multiUpload/";
  public readonly energyProvidersUrl = "energyprovider/energyProviderList/";
  public readonly cceDetailsUrl = "cceDetails/";
  public readonly makeAndModelUrl = "mnfAndModelsList/";
  private readonly languageUrl = "ho/dashboard/language/";
  private readonly requestMessageUrl = this.API_END_POINT + "ho/mail/";

  userProvidedInformation: UserDetailsDataModel;
  userProvidedRoofTopInfo: RoofTopPostModel;
  UserProvidedChoicesInfo: any;
  userProvidedLifeStyle: any;
  userProvidedLifeStyleV2: any;
  userProvidedUpgrades: UpgradesModel;
  coustmerComments: string;
  solarVendorDescription: string;
  solarVendorInformation: SolarVendorModel;
  peopleCount: string = "3";
  destination: number;
  solarVendorDetails: SolarVendorModel;
  homeOwnerEmail: string;
  buildingType: string;
  utilityProvider: string;
  userFromDashboard: boolean;
  buildingTypes: string;
  elecUtilityProviderId: number;
  elecUtilityProviderName: string;
  smdlink: boolean;
  vendorName: string;
  cceOptOut: boolean;
  responseId: string;
  logo: LogoModel;
  solutionType: string;
  categoryIndex: number = -1;
  timeoutId: any;
  private currentScrollId: string | null = null;
  private clickHandler: ((this: HTMLElement, ev: MouseEvent) => void) | null =
    null;

  constructor(
    private httpClient: HttpClient,
    private appService: AppService,
    private translate: TranslateService
  ) {}

  getTranslateText(key: string, value?) {
    let translatedText;
    this.translate
      .get(key, {
        value,
      })
      .pipe(take(1))
      .subscribe((response) => {
        translatedText = response;
      });
    if (translatedText) return translatedText;
  }

  getAllRoofTopQA(vendorName: string): Observable<RoofTopPageModel> {
    return this.httpClient.get<RoofTopPageModel>(
      this.ROOF_TOP_QA_JSON_URL + `?vendor=${vendorName}`
    );
  }

  updateLanguage(language) {
    return this.httpClient.get(
      this.API_END_POINT +
        this.languageUrl +
        this.homeOwnerEmail +
        "/" +
        language
    );
  }

  getDashboardDetails() {
    return this.httpClient.get(this.DASHBOARDAPI_END_POINT);
  }

  getSolarVendorDetails(
    vendorCode: string,
    buildingType?: string
  ): Observable<SolarVendorModel> {
    let url = this.API_END_POINT + this.SOLAR_VENDORS + vendorCode;
    url = !!buildingType ? `${url}?buildingType=${buildingType}` : url;
    return this.httpClient.get<SolarVendorModel>(url);
  }

  setSolarVendorDetails(data) {
    this.solarVendorDetails = data;
    return null;
  }

  getSVD() {
    return this.solarVendorDetails;
  }

  setEnergyUtility(utilityId, utilityName, cceOptOut) {
    this.elecUtilityProviderId = utilityId;
    this.elecUtilityProviderName = utilityName;
    this.cceOptOut = cceOptOut;
  }

  setVendorName(name: string) {
    this.vendorName = name;
  }

  getVendorName() {
    return this.vendorName;
  }

  setLogo(logoModel: LogoModel) {
    this.logo = logoModel;
  }

  setBuildingType(type: string) {
    this.buildingTypes = type;
    this.buildingType = type;
  }

  setSolutionType(type: string) {
    this.solutionType = type;
  }

  getBuildingType() {
    return this.buildingTypes;
  }

  setSmdLink(data) {
    this.smdlink = data;
    return null;
  }

  getSmdLink() {
    return this.smdlink;
  }

  getUtilityProvider(utilityID?: number) {
    let url =
      this.API_END_POINT + `consumers/${this.homeOwnerEmail}/utilityProvider`;
    if (!utilityID) {
      return this.httpClient.get<UtilityProviderModel>(url);
    } else {
      return this.httpClient.get<UtilityProviderModel>(
        url + `?electricUtilityId=${utilityID}`
      );
    }
  }

  get energyStatusAPI() {
    return this.httpClient.get<EnergyStatusModel>(
      `${this.API_END_POINT}ho/dashboard/energyStatus/${this.homeOwnerEmail}`
    );
  }

  getEnergyProvider() {
    return this.httpClient.get<EnergyProvidersModel>(
      this.API_END_POINT + this.energyProvidersUrl + this.homeOwnerEmail
    );
  }

  async postAuthCode(data, email): Promise<any> {
    try {
      await this.httpClient
        .put(this.updateUserDetailsUrl + email, data)
        .toPromise();
    } catch (error) {}
  }

  postUserDetailsForm(
    data: UserDetailsDataModel,
    isAddressPartial: boolean
  ): Observable<UserDetailsAPIPutModel> {
    this.userProvidedInformation = data;
    let userBasicDetails: UserDetailsAPIPutModel = {
      solarVendorCode: this.getVendorName() || "YLTN",
      address: data.address!,
      city: data.city,
      state: data.state,
      county: data.county,
      country: data.country,
      zipCode: data.zipCode,
      latitude: data.latitude,
      longitude: data.longitude,
      firstName: data.firstName!,
      lastName: data.lastName!,
      emailId: data.emailId,
      contactNumber: data.mobile,
      buildingType: data.buildingType!,
      onContinue: data.onContinue!,
      sendMail: data.sendMail!,
      id: data.id,
    };
    userBasicDetails.address = !isAddressPartial
      ? ""
      : userBasicDetails.address;
    if (!data.mobile) {
      delete userBasicDetails.contactNumber;
    }
    if (!data.county) {
      delete userBasicDetails.county;
    }
    if (!data.zipCode) {
      delete userBasicDetails.zipCode;
    }
    if (!data.id) {
      delete userBasicDetails.id;
    }
    data.emailId = data.emailId?.toLowerCase();
    this.homeOwnerEmail = data.emailId!;

    let url = this.updateUserDetailsUrl + this.homeOwnerEmail;
    if (this.appService.getSMDLink()) {
      url += "?action=SMD";
    }
    return this.httpClient.put<UserDetailsAPIPutModel>(url, userBasicDetails);
  }

  postRoofTopQA(roofData: RoofTopPostModel) {
    this.userProvidedRoofTopInfo = roofData;
    let roofTop = {
      roof: {
        solar: roofData.solar === "addPanel" ? "yesPlan" : roofData.solar,
        age: roofData.roofAge,
        reRoof: roofData.planForReRoof,
        slopes: roofData.roofSlopes,
        shade: roofData.roofShade,
        types: roofData.roofTypes,
      },
    };
    return this.httpClient.put(
      this.updateUserDetailsUrl + this.homeOwnerEmail,
      roofTop,
      { observe: "response" }
    );
  }

  postUpgrades(data: UpgradesModel) {
    this.userProvidedUpgrades = data;
    let upgrade: UpgradeAPIPutModel = {
      futureEnergyUsage: "" + data.futureEnergyUsage,
      addEvChargers: data.addEvCharges,
      noOfEvChargers: "" + data.noOfEvCharges,
    };

    if (upgrade.noOfEvChargers === "null") {
      delete upgrade.noOfEvChargers;
    }
    let upgradePutModel = { upgrade };
    return this.httpClient.put(
      this.updateUserDetailsUrl + this.homeOwnerEmail,
      upgradePutModel,
      { observe: "response" }
    );
  }

  postCoustmerComments(comments: string) {
    this.coustmerComments = comments;
    let customerComments = { comments };
    return this.httpClient.put(
      this.updateUserDetailsUrl + this.homeOwnerEmail,
      customerComments,
      { observe: "response" }
    );
  }

  postAvgMonthlyBill(
    avgMonthlyBill: string,
    elecUtilityProviderName: string,
    elecUtilityProviderId: number,
    cceOptOut: boolean
  ) {
    let bill = {
      avgMonthlyBill,
      elecUtilityProviderName,
      elecUtilityProviderId,
      cceOptOut,
    };
    return this.httpClient.put(
      this.updateUserDetailsUrl + this.homeOwnerEmail,
      bill,
      { observe: "response" }
    );
  }

  postUtilityProvider(
    elecUtilityProviderName: string,
    elecUtilityProviderId: number,
    cceOptOut: boolean
  ) {
    let utilityProvider = {
      elecUtilityProviderName: elecUtilityProviderName,
      elecUtilityProviderId: elecUtilityProviderId,
      cceOptOut: cceOptOut,
    };
    return this.httpClient.put(
      this.updateUserDetailsUrl + this.homeOwnerEmail,
      utilityProvider,
      { observe: "response" }
    );
  }

  getUserProvidedInformation() {
    return this.userProvidedInformation;
  }

  getUserProvidedRoofTopInfo() {
    return this.userProvidedRoofTopInfo;
  }

  getUserProvidedUpgrades() {
    return this.userProvidedUpgrades;
  }

  getCustomerComments() {
    return this.coustmerComments;
  }

  setDestination(data: number) {
    this.destination = data;
  }

  getDestination() {
    return this.destination;
  }

  setSolarVendorDescription(data: string) {
    this.solarVendorDescription = data;
  }

  getSolarVendorDescrption() {
    return this.solarVendorDescription;
  }

  setSolarVendorInformation(data) {
    this.solarVendorInformation = data;
  }

  getSolarVendorInformation() {
    return this.solarVendorInformation;
  }

  resetCompleteData(): void {
    this.coustmerComments = "";
    this.userProvidedRoofTopInfo = undefined as unknown as RoofTopPostModel;
    this.userProvidedUpgrades = undefined as unknown as UpgradesModel;
  }

  getCceDetails(utilityId) {
    return this.httpClient.get<CCEDetailsModel>(
      this.API_END_POINT +
        this.cceDetailsUrl +
        this.homeOwnerEmail +
        `?electricUtilityId=${utilityId}`
    );
  }

  getMakeAndModelList(tech_name): Observable<MNFAndModelListResponse> {
    return this.httpClient.get<MNFAndModelListResponse>(
      this.API_END_POINT +
        this.makeAndModelUrl +
        this.homeOwnerEmail +
        `?tech=${tech_name}`
    );
  }

  updateMakeAndModel(): MNFAndModel[][] {
    const modelList: MNFAndModel[][] = [];
    this.getMakeAndModelList("ev1")
      .pipe(take(1))
      .subscribe((response: MNFAndModelListResponse) => {
        modelList[0] = response.mnfDropDownList;
      });
    this.getMakeAndModelList("ev2")
      .pipe(take(1))
      .subscribe((response: MNFAndModelListResponse) => {
        modelList[1] = response.mnfDropDownList;
      });
    return modelList;
  }

  onSavePopupData(
    userInfo: EnergyChoicesModel
  ): Observable<EnergyChoicesModel> {
    return this.httpClient.put<EnergyChoicesModel>(
      this.updateUserDetailsUrl + this.homeOwnerEmail,
      userInfo,
      {}
    );
  }

  showErrorMessage(message: string): void {
    // this.snackBar.open(message, "OK", {
    //   duration: 3000,
    //   panelClass: "error_snackBar",
    // });
    console.error({ message });
  }

  requestMessage(
    message: RequestMessageModel,
    type: string
  ): Observable<RequestMessageModel> {
    const url = this.requestMessageUrl + `${type}/`;
    return this.httpClient.put<RequestMessageModel>(
      url + this.homeOwnerEmail,
      message
    );
  }

  scrollIntoElement(id: string) {
    const clickedElement = document.getElementById(id);
    this.timeoutId = setTimeout(() => {
      if (clickedElement) {
        this.currentScrollId = clickedElement.getAttribute("data-scroll-to")!;
        const nextScrollElement = document.getElementById(this.currentScrollId);
        if (nextScrollElement) {
          this.clickHandler = () =>
            this.scrollIntoElement(this.currentScrollId!);
          nextScrollElement.addEventListener("click", this.clickHandler);
          nextScrollElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 100);
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutId);
    if (this.currentScrollId && this.clickHandler) {
      const nextScrollElement = document.getElementById(this.currentScrollId);
      if (nextScrollElement) {
        nextScrollElement.removeEventListener("click", this.clickHandler);
      }
    }
  }
}
