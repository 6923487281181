import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { CleanChoiceCardsInitial } from "./clean-choice-cards.init";
import {
  CardsDetailModel,
  CleanChoiceCardsModel,
} from "../../../model/dashboard.init.model";
import { DashboardAPIService } from "../../../../../rest-api/dashboard/dashboard-api.service";
import { LearningHubModel } from "../../../model/solarvendordetails.model";
import { OverviewService } from "../../../overview.service";
import { CleanChoiceCategoryModel } from "../../../model/dashboard.model";
import { Router } from "@angular/router";
import { CleanChoiceCardsType } from "./clean-choice-cards.types";
import { MarketPlaceAnalyticsModel } from "src/app/rest-api/dashboard/dashboard-api.model";
import { MarketPlaceService } from "src/app/homeowner-dashboard/market-place/market-place.service";

@Component({
  selector: "yt-clean-choice-cards",
  templateUrl: "./clean-choice-cards.component.html",
  styleUrls: ["./clean-choice-cards.component.scss"],
})
export class CleanChoiceCardsComponent
  implements OnInit, OnChanges, AfterViewChecked
{
  scrollOffSet: number = 200;
  showCard: boolean;
  isLowe = false;
  showScrollButton: boolean = true;
  tabHeaderElem: HTMLDivElement;
  cleanChoiceCardsList: CleanChoiceCardsModel;
  cardList: LearningHubModel;
  cleanChoiceMarketplace: CardsDetailModel[];
  displayCleanChoices: CleanChoiceCardsType[] = [];
  timeoutId: any;
  @Input() activeTech: string;
  @Input() activeTab: string;
  @Input() section: string;
  @Output() showTech: EventEmitter<string> = new EventEmitter();

  constructor(
    private cleanChoiceCards: CleanChoiceCardsInitial,
    private dashboardAPIService: DashboardAPIService,
    private overviewService: OverviewService,
    private marketplaceService: MarketPlaceService,
    private router: Router
  ) {
    this.cleanChoiceCardsList = this.cleanChoiceCards.cleanChoiceCardsList;
  }

  ngOnInit(): void {
    this.cardList = this.dashboardAPIService.vendorDetails.learningHub;
    this.isLowe =
      this.dashboardAPIService.getUserDetails().solarVendorCode === "LOWE";
  }

  ngOnChanges(): void {
    if (this.activeTab) {
      this.overviewService
        .getCleanChoiceMarketplace(this.activeTab)
        .subscribe((response: CleanChoiceCategoryModel[]) => {
          this.displayCleanChoices = [];
          const activeSection: CardsDetailModel[] =
            this.cleanChoiceCardsList[this.activeTab][this.section];
          activeSection.forEach((tech) => {
            const responseTech = response.find(
              (card) => card.tech === tech.cardName
            );
            if (responseTech) {
              this.displayCleanChoices.push({
                ...responseTech,
                ...tech,
              });
            } else {
              this.displayCleanChoices.push({
                ...tech,
                boxText: "",
                buttonLink: "",
                buttonText: "",
                displayBox: false,
                linkId: "",
                tech: "",
              });
            }
          });
        });
    }
  }

  ngAfterViewChecked(): void {
    this.tabHeaderElem = document.querySelector<HTMLDivElement>(
      `#${this.section} .wrapper`
    )!;
    this.onResize();
  }

  handleScroll(button: string): void {
    let left = this.tabHeaderElem.scrollLeft;
    if (button === "prev") {
      left -= this.scrollOffSet;
    } else if (button === "next") {
      left += this.scrollOffSet;
    }
    this.tabHeaderElem.scrollTo({ left, behavior: "smooth" });
  }

  onResize(): void {
    this.timeoutId = setTimeout(() => {
      this.showScrollButton =
        this.tabHeaderElem.scrollWidth > this.tabHeaderElem.clientWidth;
    }, 50);
  }

  showTechInfo(tech: string): void {
    this.showTech.emit(tech);
  }

  openShopTab(url: string): void {
    if (
      this.overviewService.productTab == null ||
      this.overviewService.productTab.closed
    ) {
      // Open a new tab if it doesn't exist or if it has been closed
      this.overviewService.productTab = window.open(url, "_blank");
    } else {
      // Focus on the existing tab and navigate to the new URL
      this.overviewService.productTab.focus();
      this.overviewService.productTab.location.href = url;
    }
  }

  onStartShopping(card: CleanChoiceCardsType): void {
    const techList = ["electricKettle", "instantPot"];
    const yardTechList = [
      "electricLawnMower",
      "electricGrassTrimmer",
      "electricLeafBlower",
    ];
    if (this.isLowe) {
      this.marketplaceService.openShopNowTab(card.buttonLink);
      this.dashboardAPIService.dashboardDetailsAnalytics("shopNow", card.tech);
    } else {
      if (techList.includes(card.tech)) {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/kitchenAppliances`
        );
      } else if (yardTechList.includes(card.tech)) {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/yardCareAppliances`
        );
      } else if (card.tech === "electricFireplace") {
        this.router.navigateByUrl(
          `dashboard/market-place/homeAppliances/${card.tech}`
        );
      } else if (card.tech === "smartWaterMonitor") {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/waterSavingDevices`
        );
      } else if (card.tech === "hvacFilters") {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/homeComfortDevices`
        );
      } else if (card.tech === "smartLightingControls") {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/lightingElectricDevices`
        );
      } else {
        const details: MarketPlaceAnalyticsModel = {
          tech: card.tech,
          linkId: card.linkId,
        };
        this.dashboardAPIService.marketplaceDetailsAnalytics(details);
        this.marketplaceService.openShopNowTab(card.buttonLink);
      }
      this.dashboardAPIService.dashboardDetailsAnalytics("shopNow", card.tech);
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
