import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SolarVendorModel } from "../../../rest-api/rest-api.model";
import { HomeOwnerInfoService } from "../../homeowner-info.service";
import { HPJourneyDetailsPUTModel } from "../../homeowner-info.model";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { environment } from "../../../../environments/environment";
import { AppService } from "../../../app.service";
import { Title } from "@angular/platform-browser";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-personal-info",
  templateUrl: "./personal-info.component.html",
  styleUrls: ["../component-common.scss"],
})
export class PersonalInfoComponent implements OnChanges {
  userDetailsForm: FormGroup;
  error: string;
  public alive = true;
  vendorCode: string;
  vendorLink: string;
  loginLink: string;
  forgotPasswordLink: string;
  tagLine: string;
  autoLogin: boolean;
  isDialogBoxOpened = false;
  showErrorDialogueBox = false;
  duplicationUserError = false;
  loginAccess: boolean = false;
  isEmailEdited: boolean;
  currentEmail: string;
  isWorkDayUser: boolean = false;
  inValidMail: boolean = false;
  cityStatus: boolean = false;
  vendorsWithPersonalMail = ["WORK"];
  @Input() vendorInfo: SolarVendorModel;
  @Output() homeInfo: EventEmitter<null> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private homeOwnerInfoService: HomeOwnerInfoService,
    private restAPIService: RestAPIService,
    private appService: AppService,
    private titleService: Title
  ) {
    this.userDetailsForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      emailId: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
    });
  }

  ngOnChanges(): void {
    this.vendorCode = this.vendorInfo.code;
    this.vendorLink = this.vendorInfo.vendorLink;
    const selectedLanguage = sessionStorage.getItem("language");
    this.onLanguageChange(selectedLanguage!);
    this.autoLogin = this.vendorInfo.accessConfig.autoLogin;
    let login = "dashboard/login";
    let forgot = "dashboard/forgot-password";
    const isProd = environment.production;
    if (this.vendorCode === "YSLR" || !isProd) {
      this.loginLink = `${this.vendorLink}${login}/?vendor=${this.vendorCode}`;
      this.forgotPasswordLink = `${this.vendorLink}${forgot}/?vendor=${this.vendorCode}`;
    } else {
      this.loginLink = `${this.vendorLink}${login}`;
      this.forgotPasswordLink = `${this.vendorLink}${forgot}`;
    }
    this.titleService.setTitle(
      `Register | ${this.appService.vendorsList[this.vendorCode].title}`
    );
    this.appService.selectedLanguage$
      .pipe(take(1))
      .subscribe((response: string) => {
        this.onLanguageChange(response);
      });
  }

  onLanguageChange(lang: string): void {
    if (lang === "sp") {
      this.tagLine = this.vendorInfo.tagLine_sp;
    } else {
      this.tagLine = this.vendorInfo.tagLine_en;
    }
  }

  checkForWorkday(email: string): boolean {
    this.currentEmail = email.toLowerCase();
    const index = this.currentEmail.lastIndexOf("@");
    const ind = this.currentEmail.lastIndexOf(".");
    const domain = this.currentEmail.slice(index + 1, ind);
    this.isWorkDayUser = domain === "workday";
    return this.isWorkDayUser;
  }

  openErrorDialogBox(error): void {
    this.error = error.error.message;
    if (this.error === "HO LOGIN") {
      this.loginAccess = true;
      this.cityStatus = false;
      this.duplicationUserError = true;
      this.showErrorDialogueBox = true;
    } else if (this.error === "HO NO LOGIN") {
      this.loginAccess = false;
      this.cityStatus = false;
      this.duplicationUserError = true;
      this.showErrorDialogueBox = true;
    } else {
      this.showErrorDialogueBox = true;
      this.isDialogBoxOpened = true;
      this.inValidMail = false;
      this.restAPIService.showErrorMessage(this.error);
    }
  }

  triggerApi(isEmailField: boolean) {
    if (
      this.userDetailsForm.controls["emailId"].value &&
      this.userDetailsForm.controls["emailId"].valid
    ) {
      this.inValidMail = false;
      this.checkForEmailChange();
    }
  }

  checkForEmailChange(): void {
    const existEmail = this.restAPIService.homeOwnerEmail;
    this.currentEmail = this.userDetailsForm.controls["emailId"].value;
    this.currentEmail = this.currentEmail.toLowerCase();
    const isUnSupportedMail = this.checkForWorkday(this.currentEmail);
    if (!isUnSupportedMail) {
      if (existEmail !== undefined) {
        if (existEmail === this.currentEmail) {
          this.isEmailEdited = false;
        } else {
          this.isEmailEdited = true;
        }
      }
    }
  }

  cancelEmailUpdate(): void {
    this.isDialogBoxOpened = false;
    this.isEmailEdited = false;
  }

  updateEmailAddress(): void {
    this.homeOwnerInfoService.userInfo =
      {} as unknown as HPJourneyDetailsPUTModel;
    this.isDialogBoxOpened = false;
    this.isEmailEdited = false;
    this.restAPIService.responseId = "";
    this.onSubmit();
  }

  onSubmit(onContinue: boolean = false): void {
    let personalDetails: HPJourneyDetailsPUTModel = {
      personalInfo: {
        firstName: this.userDetailsForm.controls["firstName"].value,
        lastName: this.userDetailsForm.controls["lastName"].value,
        emailId: this.currentEmail,
        solarVendorCode: this.vendorInfo.code,
        onContinue,
      },
    };
    this.restAPIService.homeOwnerEmail = this.currentEmail;
    this.updateLanguage();
    if (this.currentEmail) {
      this.homeOwnerInfoService
        .postUserDetails(personalDetails)
        .pipe(take(1))
        .subscribe(
          (data: HPJourneyDetailsPUTModel) => {
            this.restAPIService.responseId = data.id!;
            this.homeOwnerInfoService.userInfo = {
              ...this.homeOwnerInfoService.userInfo,
              personalInfo: personalDetails.personalInfo,
            };
            this.restAPIService.homeOwnerEmail = data.personalInfo?.emailId!;
            if (data.validStatus) {
              this.inValidMail = false;
              if (onContinue) this.homeInfo.emit();
            } else {
              this.inValidMail = true;
            }
          },
          (error) => {
            if (error.status === 409) {
              this.openErrorDialogBox(error);
            } else {
              this.restAPIService.showErrorMessage(error.error.message);
            }
          }
        );
    }
  }

  closeErrorDialogBox(): void {
    this.isDialogBoxOpened = false;
    this.showErrorDialogueBox = false;
  }

  updateLanguage(): void {
    if (
      this.restAPIService.solarVendorInformation.languageTranslator.length > 1
    ) {
      const language = sessionStorage.getItem("language");
      if (language)
        this.restAPIService.updateLanguage(language).pipe(take(1)).subscribe();
    }
  }
}
