import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DashboardAPIService } from "src/app/rest-api/dashboard/dashboard-api.service";
import { TimeFrameEnum } from "../../learn-more/learn-more.enum";
import { errorCheckingDetailsModel as ErrorCheckingDetailsModel } from "../model/dashboard.model";
import { SolarVendorDetailsModel } from "../model/solarvendordetails.model";
import { MatRadioChange } from "@angular/material/radio";
import { LearnMoreService } from "../../learn-more/learn-more.service";
import { DropDownModel } from "src/app/rest-api/rest-api.model";
import {
  AppliancesCategoryModel,
  SimpleCostCategoryModel,
  SolarCategoryModel,
  TechCategoryModel,
  TechDetailsModel,
  TransportationCategoryModel,
} from "../model/dashboard.init.model";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CleanChoicesSections, CleanChoicesTabs } from "../../../utils/enum";
import { TechCardInitial } from "../../shared/mini-cards/tech-cards/tech-card.init";
import { Utils } from "src/app/utils/util";
import { UserDataModel } from "../model/userdata.model";
import { OverviewService } from "../overview.service";
import { userDataPutModel } from "../dashboard-v2/setting-overlay/setting-overlay.component.model";
import { SettingOverlayComponent } from "../dashboard-v2/setting-overlay/setting-overlay.component";
import { SettingsOverlayService } from "../dashboard-v2/setting-overlay/settings-overlay.service";
import { MspOverlayComponent } from "../dashboard-v2/msp-overlay/msp-overlay.component";
import { TourGuideModel } from "../../shared/tour-guide/tour-guide.model";
import { SavingsDetailsResponseType } from "./dashboard-v3.types";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-dashboard-v3",
  templateUrl: "dashboard-v3.component.html",
  styleUrls: ["./dashboard-v3.component.scss"],
})
export class DashboardV3Component implements OnInit, OnDestroy {
  showDashboard: boolean = true;
  savingsDetails: SavingsDetailsResponseType;
  isNewUser: boolean;
  errorPhrase1: string;
  errorPhrase2: string;
  showMinPanelError: boolean;
  showMaxPanelError: boolean;
  showErrorMessage: boolean;
  showErrorMessageTab1: boolean;
  showErrorMessageTab2: boolean;
  solarErrorMessage: string;
  solarVendorDetails: SolarVendorDetailsModel;
  showAlert: boolean;
  partialAlert: boolean;
  sliderAlert: boolean;
  revokedAlert: boolean;
  errorMsg: string;
  showEmailAlert: boolean;
  showErrorAlert: boolean;
  loader: boolean;
  showTourGuide: boolean;
  errorTechnologiesList: string[] = [];
  errorCheckingDetailsList: ErrorCheckingDetailsModel;
  selectedCategory: string;
  selectedCategoryLabel: string;
  timeSelected: TimeFrameEnum;
  timeFrameOptions: DropDownModel[] = [];
  showCategorySelectedAlert: boolean = false;
  incentivesAmount: number;
  selectedCategoriesList: TechDetailsModel[];
  techCardsList: TechCategoryModel;
  vendorCode: string;
  evName1: string;
  evName2: string;
  twentyYear: boolean;
  oneYear: boolean;
  oneMonth: boolean;
  tenYear: boolean;
  showRedirectLoader: boolean = false;
  isApproach2RC: boolean;
  section: string;
  activeTech: string;
  isSimpleLowCost: boolean = true;
  showBlackLoader: boolean;
  isSavingsAPIDispatched: boolean;
  showWhatChoices: boolean;
  renterCondoStatus: boolean;
  buildingType: string;
  buildingTypeCondo: boolean;
  buildingTypeRenter: boolean;
  showCategoryLoader: boolean = true;
  tourGuideObject: TourGuideModel[] = [];
  categoriesListOrder: string[];
  recommendationList: {
    simpleLowCost: never[];
    appliances: never[];
    solarBattery: never[];
    transportation: never[];
  };
  categoriesList: {
    value: string;
    image: string;
    name: string;
  }[] = [];
  @ViewChild("errorCheckingAlert", { read: ElementRef })
  errorMessageRef: ElementRef;
  categoryLoaderTimer: any;
  redirectLoaderTimer: any;

  constructor(
    private snackBar: MatSnackBar,
    private dashboardAPI: DashboardAPIService,
    private learnMoreService: LearnMoreService,
    private techCards: TechCardInitial,
    private utils: Utils,
    private overviewService: OverviewService,
    private dialog: MatDialog,
    private settingService: SettingsOverlayService
  ) {
    this.timeFrameOptions = this.learnMoreService.timeSegmentList;
    this.techCardsList = this.techCards.techList;
  }

  ngOnInit(): void {
    this.showErrorAlert = this.dashboardAPI.showErrorAlertStatus;
    this.showEmailAlert = this.dashboardAPI.showEmailAlertStatus;
    this.showAlert =
      this.partialAlert =
      this.sliderAlert =
      this.revokedAlert =
        this.dashboardAPI.showPgeAlertStatus;
    this.vendorCode = this.dashboardAPI.getUserDetails().solarVendorCode;
    this.getVendorDetails();
    this.onValueChange(this.dashboardAPI.timeFrame);
    this.showTechCardLoader();
    this.tourGuideObject = [
      {
        title: "Welcome to your Dashboard",
        bodyText:
          "Let us walk you through a quick platform tour so you can start building your plan to save money and cut emissions.",
        buttonText: "Next",
        selector: "",
      },
      {
        title: "Browse Recommendations",
        bodyText: `See choices by category and simply
        click <mat-icon class="material-icons favorite_icon">favorite_border</mat-icon> to customize and add to 'Favorites'.`,
        buttonText: "Next",
        selector: ".tech_card",
        childSelector: ".favorite_icon",
      },
      {
        title: "Learn more",
        bodyText:
          "Click here to deep dive into any specific choice to understand Costs, Incentives, etc.",
        buttonText: "Next",
        selector: ".tech_card",
        childSelector: ".lm_btn",
      },
      {
        title: `<mat-icon class="material-icons already_icon">check_circle_outline</mat-icon> Already Have`,
        bodyText: `Click the tick mark if already purchased/installed and we will move it to the 'Already Have' section`,
        buttonText: "Next",
        selector: ".tech_card",
        childSelector: ".check_circle",
      },
      {
        title: "Complete Setup",
        bodyText:
          "Click to review and customize energy option settings to get the most accurate results.",
        buttonText: "Do it later",
        selector: ".personalize-button",
      },
    ];
  }

  getVendorDetails(): void {
    this.showCategoryLoader = false;
    const response = this.dashboardAPI.vendorDetails;
    if (response && Object.keys(response).length) {
      this.isApproach2RC = this.dashboardAPI.isApproach2RC;
      this.isNewUser = response.loggedInCount === 1;
      this.showEmailAlert =
        this.isNewUser && this.dashboardAPI.showEmailAlertStatus;
      this.solarVendorDetails = response;
      this.dashboardAPI.rewardBonus = response.rewardBonus;
      this.categoriesListOrder = response.categoriesList;
      const catagories = {
        simpleLowCost: {
          name: "Simple/low cost",
          image: "assets/dashboard/overview/simpleLowCost.png",
        },
        appliances: {
          name: "Appliances",
          image: "assets/dashboard/overview/appliances.png",
        },
        transportation: {
          name: "Transportation",
          image: "assets/dashboard/overview/transportation.png",
        },
        solarBattery: {
          name: "Solar & Battery",
          image: "assets/dashboard/overview/solarAndBattery.png",
        },
      };
      this.categoriesListOrder.forEach((value) => {
        this.categoriesList.push({
          value: value,
          ...catagories[value],
        });
      });
    }
  }

  getSavingsDetails(queryParam?: string): void {
    this.isSavingsAPIDispatched = true;
    this.getUserSettingInfo();
    this.dashboardAPI
      .getSavingsDetails(this.timeSelected, queryParam)
      .pipe(take(1))
      .subscribe(
        (response: SavingsDetailsResponseType) => {
          this.savingsDetails = response;
          this.dashboardAPI.alreadyHaveLoading.next("");
          this.dashboardAPI.favoriteLoading.next("");
          this.dashboardAPI.removeAlreadyHave.next("");
          this.isSavingsAPIDispatched = false;
          if (this.dashboardAPI.showTourGuide) {
            this.showTourGuide = this.isNewUser;
          }
          this.incentivesAmount = this.savingsDetails.totalIncentives;
          this.showErrorMessageTab2 = this.savingsDetails.errorMsg_2;
          const upfrontCost = "Upfront cost";
          const defaultNetCostLabel =
            this.dashboardAPI.getPaymentTypeLabel("cash");
          const {
            ledCflBulbs,
            showerHeadsAerators,
            smartPowerStrips,
            smartThermostat,
            weatherStripping,
            everGreen,
          } = response.simpleLowCost;
          const simpleLowCost: SimpleCostCategoryModel = {
            ...this.techCardsList.simpleLowCost!,
            everGreen: everGreen && {
              ...this.techCards.techList.simpleLowCost.everGreen,
              showTech: true,
              techName: everGreen.name,
              priceAfterIncentives: everGreen.upfrontCost,
              link: everGreen.techDetails.link,
              netCostLabel: upfrontCost,
              own: everGreen.techDetails.own,
              dollarSavings: everGreen.techDetails.dollarSavings,
              carbonSavings: everGreen.techDetails.carbonSavings,
              leaves: everGreen.techDetails.leaves,
              isNegative: everGreen.techDetails.dollarSavings === 0,
            },
            ledCflBulbs: ledCflBulbs && {
              ...this.techCards.techList.simpleLowCost?.ledCflBulbs!,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: ledCflBulbs.own,
              priceAfterIncentives: ledCflBulbs.priceAfterIncentives,
              link: ledCflBulbs.link,
              rebateStatus: ledCflBulbs.rebateStatus,
              shopNow: ledCflBulbs.shopNow,
              dollarSavings: ledCflBulbs.dollarSavings,
              carbonSavings: ledCflBulbs.carbonSavings,
              leaves: ledCflBulbs.leaves,
              isNegative: ledCflBulbs.dollarSavings === 0,
            },
            showerHeadsAerators: showerHeadsAerators && {
              ...this.techCards.techList.simpleLowCost?.showerHeadsAerators!,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: showerHeadsAerators.own,
              priceAfterIncentives: showerHeadsAerators.priceAfterIncentives,
              link: showerHeadsAerators.link,
              rebateStatus: showerHeadsAerators.rebateStatus,
              shopNow: showerHeadsAerators.shopNow,
              dollarSavings: showerHeadsAerators.dollarSavings,
              carbonSavings: showerHeadsAerators.carbonSavings,
              leaves: showerHeadsAerators.leaves,
              isNegative: showerHeadsAerators.dollarSavings === 0,
            },
            smartPowerStrips: smartPowerStrips && {
              ...this.techCards.techList.simpleLowCost?.smartPowerStrips!,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: smartPowerStrips.own,
              priceAfterIncentives: smartPowerStrips.priceAfterIncentives,
              link: smartPowerStrips.link,
              rebateStatus: smartPowerStrips.rebateStatus,
              shopNow: smartPowerStrips.shopNow,
              dollarSavings: smartPowerStrips.dollarSavings,
              carbonSavings: smartPowerStrips.carbonSavings,
              leaves: smartPowerStrips.leaves,
              isNegative: smartPowerStrips.dollarSavings === 0,
            },
            smartThermostat: smartThermostat && {
              ...this.techCards.techList.simpleLowCost?.smartThermostat!,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: smartThermostat.own,
              priceAfterIncentives: smartThermostat.priceAfterIncentives,
              link: smartThermostat.link,
              rebateStatus: smartThermostat.rebateStatus,
              shopNow: smartThermostat.shopNow,
              dollarSavings: smartThermostat.dollarSavings,
              carbonSavings: smartThermostat.carbonSavings,
              leaves: smartThermostat.leaves,
              isNegative: smartThermostat.dollarSavings === 0,
            },
            weatherStripping: weatherStripping && {
              ...this.techCards.techList.simpleLowCost?.weatherStripping!,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: weatherStripping.own,
              priceAfterIncentives: weatherStripping.priceAfterIncentives,
              link: weatherStripping.link,
              rebateStatus: weatherStripping.rebateStatus,
              shopNow: weatherStripping.shopNow,
              dollarSavings: weatherStripping.dollarSavings,
              carbonSavings: weatherStripping.carbonSavings,
              leaves: weatherStripping.leaves,
              isNegative: weatherStripping.dollarSavings === 0,
            },
          };

          const { ev, eBike, evChargers } = response.transportation;
          const { ev1, ev2 } = ev;
          this.evName1 = ev1?.makeAndModel;
          this.evName2 = ev2
            ? ev2.makeAndModel
            : this.techCardsList.transportation?.ev2?.techName!;
          const ev1PriceAfterIncentives =
            ev1?.financial === "cash"
              ? ev1?.techDetails.priceAfterIncentives
              : ev1?.monthlyPayment;
          const ev2PriceAfterIncentives =
            ev2?.financial === "cash"
              ? ev2?.techDetails.priceAfterIncentives
              : ev2?.monthlyPayment;

          const transportation: TransportationCategoryModel = {
            ...this.techCardsList.transportation!,
            ev1: ev1 && {
              ...this.techCards.techList.transportation?.ev1!,
              showTech: true,
              priceAfterIncentives: ev1PriceAfterIncentives,
              techName: this.evName1,
              netCostLabel: this.dashboardAPI.getPaymentTypeLabel(
                ev1.financial
              ),
              own: ev1.techDetails.own,
              financial: ev1.financial,
              monthlyPayment: ev1.monthlyPayment,
              shopNow: ev1.techDetails.shopNow,
              link: ev1.techDetails.link,
              rebateStatus: ev1.techDetails.rebateStatus,
              dollarSavings: ev1.techDetails.dollarSavings,
              carbonSavings: ev1.techDetails.carbonSavings,
              leaves: ev1.techDetails.leaves,
              isNegative: ev1.techDetails.dollarSavings === 0,
            },
            ev2: ev2 && {
              ...this.techCards.techList.transportation.ev2!,
              showTech: true,
              priceAfterIncentives: ev2PriceAfterIncentives,
              techName: this.evName2,
              netCostLabel: this.dashboardAPI.getPaymentTypeLabel(
                ev2?.financial!
              ),
              own: ev2?.techDetails.own!,
              financial: ev2?.financial,
              monthlyPayment: ev2?.monthlyPayment,
              shopNow: ev2?.techDetails.shopNow!,
              link: ev2?.techDetails.link,
              rebateStatus: ev2?.techDetails.rebateStatus,
              dollarSavings: ev2?.techDetails.dollarSavings!,
              carbonSavings: ev2?.techDetails.carbonSavings!,
              leaves: ev2?.techDetails.leaves!,
              isNegative: ev2?.techDetails.dollarSavings === 0,
            },
            eBike: eBike && {
              ...this.techCards.techList.transportation?.eBike!,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: eBike?.own!,
              priceAfterIncentives: eBike?.priceAfterIncentives,
              link: eBike?.link,
              shopNow: eBike?.shopNow!,
              rebateStatus: eBike?.rebateStatus,
              dollarSavings: eBike?.dollarSavings!,
              carbonSavings: eBike?.carbonSavings!,
              leaves: eBike?.leaves!,
              isNegative: eBike?.dollarSavings === 0,
            },
            evChargers: evChargers && {
              ...this.techCards.techList.transportation?.evChargers!,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: evChargers.own,
              priceAfterIncentives: evChargers.priceAfterIncentives,
              link: evChargers.link,
              shopNow: evChargers.shopNow,
              rebateStatus: evChargers.rebateStatus,
              dollarSavings: evChargers.dollarSavings,
              carbonSavings: evChargers.carbonSavings,
              leaves: evChargers.leaves,
              isNegative: evChargers.dollarSavings === 0,
            },
          };

          const { solar, communitySolar, battery, portableBattery } =
            response.solarBattery;
          const solarBattery: SolarCategoryModel = {
            ...this.techCardsList.solarBattery!,
            solar: solar && {
              ...this.techCards.techList.solarBattery?.solar!,
              panelStatus: response.panelCount > 30,
              error1: this.showErrorMessageTab1,
              error2: this.showErrorMessageTab2,
              showTech: true,
              own: solar.techDetails.own,
              financial: solar?.financial!,
              link: solar.techDetails.link,
              priceAfterIncentives:
                solar?.financial === "cash"
                  ? solar?.techDetails.priceAfterIncentives
                  : solar?.monthlyPayment,
              netCostLabel: this.dashboardAPI.getPaymentTypeLabel(
                solar?.financial!
              ),
              systemSize: solar?.systemSize,
              shopNow: solar.techDetails.shopNow!,
              rebateStatus: solar?.techDetails.rebateStatus,
              dollarSavings: solar?.techDetails.dollarSavings!,
              carbonSavings: solar?.techDetails.carbonSavings!,
              leaves: solar?.techDetails.leaves!,
              isNegative: solar?.techDetails.dollarSavings === 0,
            },
            communitySolar: communitySolar && {
              ...this.techCards.techList.solarBattery.communitySolar!,
              priceAfterIncentives: communitySolar.upfrontCost,
              showTech: true,
              netCostLabel: upfrontCost,
              link: communitySolar.techDetails.link,
              own: communitySolar.techDetails.own!,
              shopNow: communitySolar.techDetails.shopNow!,
              dollarSavings: communitySolar.techDetails.dollarSavings!,
              carbonSavings: communitySolar?.techDetails.carbonSavings!,
              leaves: communitySolar?.techDetails.leaves!,
              isNegative: communitySolar?.techDetails.dollarSavings === 0,
            },
            battery: battery && {
              ...this.techCards.techList.solarBattery?.battery!,
              priceAfterIncentives:
                battery?.financial === "cash"
                  ? battery.techDetails.priceAfterIncentives
                  : battery?.monthlyPayment,
              netCostLabel: this.dashboardAPI.getPaymentTypeLabel(
                battery?.financial!
              ),
              showTech: true,
              own: battery.techDetails?.own!,
              financial: battery?.financial!,
              makeAndModel: battery?.model,
              shopNow: battery.techDetails?.shopNow!,
              link: battery.techDetails?.link,
              rebateStatus: battery.techDetails?.rebateStatus,
              dollarSavings: battery.techDetails?.dollarSavings!,
              carbonSavings: battery.techDetails?.carbonSavings!,
              leaves: battery.techDetails?.leaves!,
              isNegative: battery.techDetails?.dollarSavings === 0,
            },
            portableBattery: portableBattery && {
              ...this.techCards.techList.solarBattery?.portableBattery!,
              priceAfterIncentives:
                portableBattery.techDetails?.priceAfterIncentives,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: portableBattery.techDetails?.own!,
              financial: portableBattery.financial!,
              makeAndModel: portableBattery.model,
              shopNow: portableBattery.techDetails?.shopNow!,
              link: portableBattery.techDetails?.link,
              rebateStatus: portableBattery.techDetails?.rebateStatus,
              dollarSavings: portableBattery.techDetails?.dollarSavings!,
              carbonSavings: portableBattery.techDetails?.carbonSavings!,
              leaves: portableBattery.techDetails?.leaves!,
              isNegative: portableBattery.techDetails?.dollarSavings === 0,
            },
          };

          const { hpwh, hpd, hpsc, ic } = response.appliances;
          this.dashboardAPI.icType = ic?.inductionCooktopType;
          this.dashboardAPI.getICInfo(ic?.inductionCooktopType);
          const appliances: AppliancesCategoryModel = {
            ...this.techCardsList.appliances!,
            hpwh: hpwh && {
              ...this.techCards.techList.appliances?.hpwh!,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: hpwh.own,
              priceAfterIncentives: hpwh.priceAfterIncentives,
              link: hpwh.link,
              shopNow: hpwh.shopNow,
              rebateStatus: hpwh.rebateStatus,
              dollarSavings: hpwh.dollarSavings,
              carbonSavings: hpwh.carbonSavings,
              leaves: hpwh.leaves,
              isNegative: hpwh.dollarSavings === 0,
            },
            hpd: hpd && {
              ...this.techCards.techList.appliances?.hpd!,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: hpd.own,
              priceAfterIncentives: hpd.priceAfterIncentives,
              link: hpd.link,
              shopNow: hpd.shopNow,
              rebateStatus: hpd.rebateStatus,
              dollarSavings: hpd.dollarSavings,
              carbonSavings: hpd.carbonSavings,
              leaves: hpd.leaves,
              isNegative: hpd.dollarSavings === 0,
            },
            hpsc: hpsc && {
              ...this.techCards.techList.appliances?.hpsc!,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: hpsc.own,
              priceAfterIncentives: hpsc.priceAfterIncentives,
              link: hpsc.link,
              shopNow: hpsc.shopNow,
              rebateStatus: hpsc.rebateStatus,
              dollarSavings: hpsc.dollarSavings,
              carbonSavings: hpsc.carbonSavings,
              leaves: hpsc.leaves,
              isNegative: hpsc.dollarSavings === 0,
              negativeTooltip:
                "Using a natural gas space heating is cheaper than using an air-source heat pump. However, switching from gas reduces carbon emissions.",
            },
            ic: ic && {
              ...this.techCards.techList.appliances?.ic!,
              netCostLabel: defaultNetCostLabel,
              showTech: true,
              own: ic.techDetails.own,
              priceAfterIncentives: ic.techDetails.priceAfterIncentives,
              link: ic.techDetails.link,
              shopNow: ic.techDetails.shopNow,
              rebateStatus: ic.techDetails.rebateStatus,
              dollarSavings: ic.techDetails.dollarSavings,
              carbonSavings: ic.techDetails.carbonSavings,
              leaves: ic.techDetails.leaves,
              techName: this.dashboardAPI.icInfo.title,
              img: this.dashboardAPI.icInfo.image,
              techParameter:
                this.dashboardAPI.icType === "portable" ? "portableIC" : "ic",
              isNegative: ic.techDetails.dollarSavings === 0,
              negativeTooltip:
                "Cooking with natural gas is cheaper than cooking with an induction cooktop. However, switching from natural gas improves indoor air quality and reduces carbon emissions.",
            },
          };
          this.techCardsList = {
            simpleLowCost,
            transportation,
            solarBattery,
            appliances,
            favorite: {},
          };
          this.recommendationList = {
            simpleLowCost: [],
            appliances: [],
            solarBattery: [],
            transportation: [],
          };
          Object.entries(this.techCardsList).forEach(
            (category: [string, TechCategoryModel]) => {
              Object.entries(category[1]).forEach(
                (tech: [string, TechDetailsModel]) => {
                  if (tech[1] && tech[1].own === "yes") {
                  } else if (tech[1] && tech[1].own === "yesPlan") {
                    if (
                      this.showErrorMessageTab2 &&
                      tech[1].techParameter === "solar"
                    ) {
                      this.recommendationList[category[0]].push(tech[1]);
                    } else {
                      this.recommendationList[category[0]].push(tech[1]);
                    }
                  } else if (tech[1] && tech[1].own) {
                    this.recommendationList[category[0]].push(tech[1]);
                  }
                }
              );
            }
          );
          if (this.showRedirectLoader) {
            this.redirectLoaderTimer = setTimeout(() => {
              this.showRedirectLoader = false;
            }, 6000);
          } else {
            this.loader = false;
          }
          this.getErrorMessage(this.savingsDetails);
          this.getTechErrorDetails();
        },
        (error) => {
          if (error.status === 500) {
            this.showErrorMessage = true;
            this.loader = false;
            this.isSavingsAPIDispatched = false;
            this.showTourGuide = false;
            this.showDashboard = false;
          }
        }
      );
  }

  getErrorMessage(data: any): void {
    if (data.solarBattery.solar) {
      this.showDashboard = true;
      this.showMaxPanelError = false;
      this.showMinPanelError = false;
      if (data.errorMsg_1) {
        this.showErrorMessageTab1 = true;
        this.showErrorMessageTab2 = false;
      } else if (data.errorMsg_2) {
        this.showErrorMessageTab2 = true;
        this.showErrorMessageTab1 = false;
        this.solarErrorMessage = data.message!;
      } else {
        this.showErrorMessageTab2 = false;
        this.showErrorMessageTab1 = false;
      }
    } else {
      if (data.errorMsg_1) {
        this.showMaxPanelError = true;
        this.showMinPanelError = false;
        this.errorPhrase1 =
          "Your home likely requires a solar system that is greater than 25kW." +
          " " +
          this.solarVendorDetails.description +
          " will contact you to discuss a custom quote";
        this.errorPhrase2 = "";
      }
      if (data.errorMsg_2) {
        this.showMinPanelError = true;
        this.showMaxPanelError = false;
        this.errorPhrase1 = "";
        this.errorPhrase2 = data.message!;
      }
    }
    this.showAlert =
      data.showPGEAlert !== "Complete auth" &&
      data.showPGEAlert !== "No GBC" &&
      data.showPGEAlert !== "PGE under maintenance" &&
      this.dashboardAPI.showPgeAlertStatus;
    this.partialAlert =
      data.showPGEAlert === "Partial auth" &&
      this.dashboardAPI.showPgeAlertStatus;
    this.sliderAlert =
      data.showPGEAlert === "Slider" && this.dashboardAPI.showPgeAlertStatus;
    this.revokedAlert =
      data.showPGEAlert === "Revoked" && this.dashboardAPI.showPgeAlertStatus;
    if (this.errorMessageRef)
      this.errorMessageRef.nativeElement.innerHTML = this.errorMsg;
  }

  getTechErrorDetails(): void {
    this.dashboardAPI
      .getErrorCheckingDetails()
      .pipe(take(1))
      .subscribe((response: ErrorCheckingDetailsModel) => {
        this.errorCheckingDetailsList = response;
        this.showErrorAlert =
          this.errorCheckingDetailsList.errorMessage !== false &&
          this.showErrorAlert
            ? true
            : false;
        this.errorTechnologiesList.length = 0;
        if (this.errorCheckingDetailsList.errorTechnologies.length !== 0) {
          for (
            let i = 0;
            i < this.errorCheckingDetailsList.errorTechnologies.length;
            i++
          ) {
            this.errorTechnologiesList.push(
              this.errorCheckingDetailsList.errorTechnologies[i]
            );
          }
        }
      });
  }

  getUserSettingInfo(): void {
    this.dashboardAPI
      .getUserSettingInfo()
      .pipe(take(1))
      .subscribe((response: UserDataModel) => {
        this.dashboardAPI.energyChoicesData = response;
        if (response.categoryInfo) {
          this.selectedCategory = response.categoryInfo;
          this.dashboardAPI.activeRecommendationTab = this.selectedCategory;
          this.selectedCategoryLabel = this.renderSelectedCategoryName(
            this.selectedCategory
          );
        }
        this.showCategorySelectedAlert = !!this.selectedCategory;
        this.updateRecommendationTab();
      });
    this.buildingType = this.dashboardAPI.getBuildingType();
    this.renterCondoStatus = this.overviewService.updateEnergyChoiceInfo(
      this.buildingType
    );
    this.buildingTypeRenter = this.buildingType === "renter";
    this.buildingTypeCondo = this.buildingType === "condominium";
    // If approach-1 available based on building type enable the option to show, What Choices Applies to me?
    const renterApproach = this.dashboardAPI.vendorDetails.renterApproach;
    const condoApproach = this.dashboardAPI.vendorDetails.condominiumApproach;
    this.showWhatChoices =
      (this.buildingTypeRenter && renterApproach === "approach1") ||
      (this.buildingTypeCondo && condoApproach === "approach1");
  }

  openWhatChoices(): void {
    const element = document.getElementById("renterPopup");
    if (element) element.style.display = "block";
  }

  closeWhatChoices(): void {
    const element = document.getElementById("renterPopup");
    if (element) element.style.display = "none";
  }

  onCloseOfEmailAlert(): void {
    this.dashboardAPI.showEmailAlertStatus = false;
    this.showEmailAlert = false;
  }

  onAlertClose(): void {
    this.dashboardAPI.showPgeAlertStatus = false;
    this.showAlert = false;
  }

  onCloseOfErrorAlert(): void {
    this.dashboardAPI.showErrorAlertStatus = false;
    this.showErrorAlert = false;
  }

  onEnergyUsage(value: boolean) {
    this.openPersonalize();
  }

  onSelectGoal($event: MatRadioChange) {
    this.showCategoryLoader = true;
    const data: userDataPutModel = {
      categoryInfo: $event.value,
    };
    this.overviewService
      .updateUserSettingInfo(data)
      .pipe(take(1))
      .subscribe((data) => {
        if (data.status === 200) {
          this.showCategorySelectedAlert = true;
          this.selectedCategory = $event.value;
          this.dashboardAPI.activeRecommendationTab = this.selectedCategory;
          this.selectedCategoryLabel = this.renderSelectedCategoryName(
            this.selectedCategory
          );
          this.selectedTab(this.selectedCategory);
          let scroll = document.getElementById("loader");
          if (scroll !== null) {
            scroll.scrollIntoView({ behavior: "smooth" });
            scroll = null;
          }
          this.categoryLoaderTimer = setTimeout(() => {
            this.showCategoryLoader = false;
          }, 1000);
        }
      });
  }

  onValueChange(event: string): void {
    this.timeSelected = event as TimeFrameEnum;
    this.dashboardAPI.setTimeFrame(this.timeSelected);
    const timeMapper = {};
    Object.values(TimeFrameEnum).forEach((timeSpan) => {
      timeMapper[timeSpan] = () => this.setTimeSpan(timeSpan);
    });
    timeMapper[this.timeSelected]();
    let param = window.history.state;
    let count = sessionStorage.getItem("count");
    if (!count) {
      sessionStorage.setItem("count", "redirected");
      this.showRedirectLoader = param?.data === "autoLogin";
      this.loader = !this.showRedirectLoader;
    } else {
      this.showRedirectLoader = false;
      this.loader = true;
    }
    this.getSavingsDetails();
  }

  setTimeSpan(span: string) {
    this.twentyYear = span === "twentyYear";
    this.tenYear = span === "tenYear";
    this.oneYear = span === "oneYear";
    this.oneMonth = span === "oneMonth";
  }

  renderSelectedCategoryName(value: string): string {
    return (
      this.categoriesList.find((category) => category.value === value)?.name ||
      value
    );
  }

  onAdd(add_tech: string): void {
    this.loader = true;
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo(add_tech, "yesPlan")
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.getSavingsDetails();
          let info = this.dashboardAPI.techMessage(add_tech, "add");
          this.snackBar.open(info, "OK", {
            duration: 8000,
          });
        }
      });
  }

  onRemove(remove_tech: string): void {
    this.loader = true;
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo(remove_tech, "noPlan")
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.getSavingsDetails();
          let info = this.dashboardAPI.techMessage(remove_tech, "remove");
          this.snackBar.open(info, "OK", {
            duration: 8000,
          });
        }
      });
  }

  onAlreadyHave(tech: string): void {
    this.loader = true;
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo(tech, "yes")
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.getSavingsDetails();
          let info = this.dashboardAPI.techMessage(tech, "remove");
          this.snackBar.open(info, "OK", {
            duration: 8000,
          });
        }
      });
  }

  showTechCardLoader(): void {
    this.dashboardAPI.alreadyHaveLoading.pipe(take(1)).subscribe((response) => {
      this.showBlackLoader = !!response;
    });
    this.dashboardAPI.favoriteLoading.pipe(take(1)).subscribe((response) => {
      this.showBlackLoader = !!response;
    });
  }

  selectedTab(event: string): void {
    this.dashboardAPI.activeRecommendationTab = this.selectedCategory = event;
    this.isSimpleLowCost = event === CleanChoicesTabs.SIMPLE_LOW_COST;
    this.section = "other" + this.utils.toTitleCase(event);
  }

  updateRecommendationTab(): void {
    const searchParams = new URLSearchParams(window.location.search);
    const tabToSectionMapping = {
      simpleLowCost: CleanChoicesSections.OTHER_SIMPLE_LOW_COST,
      transportation: CleanChoicesSections.OTHER_TRANSPORTATION,
      appliances: CleanChoicesSections.OTHER_APPLIANCES,
    };
    if (searchParams.has("tab")) {
      this.selectedCategory = searchParams.get("tab")!;
      this.activeTech = searchParams.get("tech")!;
      window.history.pushState({}, "YLTN", "/dashboard/overview");
    } else if (this.dashboardAPI.activeRecommendationTab) {
      this.selectedCategory = this.dashboardAPI.activeRecommendationTab;
    }
    this.section = tabToSectionMapping[this.selectedCategory];
  }

  updateSavingsDetails(): void {
    this.getSavingsDetails();
  }

  openPersonalize(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("updateSetting");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = { right: "0" };
    let dialogRef = this.dialog.open(SettingOverlayComponent, {
      width: "70%",
      height: "100%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "homeSetting_container",
      data: { tech: "" },
      disableClose: true,
      exitAnimationDuration: 0,
    });

    dialogRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        if (
          !!Object.keys(this.settingService.settingsInfo).length ||
          !!Object.keys(this.settingService.settingsInfoErrors).length
        ) {
          dialogRef.componentInstance.settingStatus = true;
        } else {
          dialogRef.close();
        }
      });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.dashboardAPI.energyInfoData = false;
        this.settingService.settingsInfo = {};
        this.getSavingsDetails();
      });
  }

  getApproach1Status(): boolean {
    const buildingType = this.dashboardAPI.getBuildingType();
    const renterApproach = this.dashboardAPI.vendorDetails.renterApproach;
    const condoApproach = this.dashboardAPI.vendorDetails.condominiumApproach;
    const approach1Status =
      renterApproach === "approach1" || condoApproach === "approach1";
    if (approach1Status) return true;
    return buildingType === "singleFamilyHome";
  }

  showServicePanelInfo(): void {
    let dialogRef = this.dialog.open(MspOverlayComponent, {
      width: "70%",
      height: "100%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "homeSetting_container",
      data: { tech: "" },
      disableClose: true,
      exitAnimationDuration: 0,
    });
    dialogRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => dialogRef.close());
  }

  isTechListAvailable(): boolean {
    return this.recommendationList[this.selectedCategory].every(
      (tech) => !tech.showTech
    );
  }

  closeTourGuide(): void {
    this.showTourGuide = false;
    this.dashboardAPI.showTourGuide = false;
  }

  onTour(): void {
    this.showTourGuide = true;
    this.showRedirectLoader = false;
    this.dashboardAPI.dashboardDetailsAnalytics("tourGuide");
  }

  ngOnDestroy(): void {
    clearTimeout(this.redirectLoaderTimer); 
    clearTimeout(this.categoryLoaderTimer); 
    this.showCategorySelectedAlert = false;
  }
}
