import {
  HPWHDetailsModel,
  HPWHFinancialModel,
  HPWHTechnicalModel,
  HPWHAssumptionsModel,
  HPWHLearnMoreModel,
  HPWHSavingsModel,
} from "./hpwh-details.model";
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterContentChecked,
  ChangeDetectorRef,
} from "@angular/core";
import * as Highcharts from "highcharts";
import { MatDialog } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HelpMeChooseComponent } from "../../shared/help-me-choose/help-me-choose.component";
import { TimeFrameEnum } from "../learn-more.enum";
import {
  BarChartDataModel,
  LearnMoreRequestModel,
  MarketPlaceDetailsModel,
} from "../learn-more.model";
import { LearnMoreService } from "../learn-more.service";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import {
  MNFAndModelListResponse,
  MNFAndModel,
} from "../../../homeowner-product/energy-choices/energy-choices.model";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { AppService } from "../../../app.service";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-hpwh-details",
  templateUrl: "hpwh-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class HPWHDetailsComponent implements OnInit, AfterContentChecked {
  showHelpMeChoose: boolean;
  hpwh_Savings: number;
  icon = false;
  icon1 = false;
  panelOpenState = false;
  HPWHType: string;
  yAxisLabel: string;
  yAxisLabel1: string;
  hpwhViewDetails: HPWHDetailsModel;
  financialDetails: HPWHFinancialModel;
  technicalDetails: HPWHTechnicalModel;
  assumptionDetails: HPWHAssumptionsModel;
  marketPlaceDetails: MarketPlaceDetailsModel;
  learnMoreDetails: HPWHLearnMoreModel;
  selected: TimeFrameEnum;
  twentyYear: boolean;
  oneYear: boolean;
  oneMonth: boolean;
  chartOptions1: Highcharts.Options = {};
  showIncentivesStatus: boolean;
  payBackYear: string;
  textUnderChart: string;
  showTooltipZero: boolean;
  hpwhForm: FormGroup;
  loader = true;
  totalIncentives: number;
  paybackPeriod: number;
  displayPayback: boolean;
  displayPaybackGTTwenty: boolean;
  haveResponse = false;
  displayMarketPlaceBox: boolean;
  marketPlaceLink: string;
  showIncentiveBreakdown: boolean;
  isPortable: boolean;
  mnfAndModel: string;
  currentHeater: string;
  vendorName: string;
  linkId: string;
  makeAndModelList: MNFAndModel[] = [];
  ageList: DropDownModel[];
  timeSegmentList: DropDownModel[];
  timeFrame: string;
  yAxisLabel2: string = "";
  adviceCardStatus: boolean;
  hpwhTypes: DropDownModel[];
  @ViewChild("loaderButton", { read: ElementRef }) saveButton: ElementRef;
  @ViewChild("container", { read: ElementRef }) container: ElementRef;
  activeIndex: number = 0;
  tabIndex: number = 0;
  tabLabel: string;
  isLangSpanish: boolean;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  isTechnicalExpanded: boolean;
  isFAQExpanded: boolean;
  favStatus: boolean;
  favIcon: string;
  financeHpwhSavings: HPWHSavingsModel;
  showCash: boolean = true;
  timeoutId: any;
  showBlackLoader: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private learnMoreService: LearnMoreService,
    private dashboardService: DashboardAPIService,
    private overviewService: OverviewService,
    private restAPIService: RestAPIService,
    private cdRef: ChangeDetectorRef,
    private appService: AppService
  ) {
    this.timeSegmentList = this.learnMoreService.timeSegmentList;
    this.hpwhTypes = this.learnMoreService.energyChoiceType;
    this.hpwhForm = this.fb.group({
      howManyPeople: [
        "2",
        [
          Validators.required,
          Validators.pattern("^([1-9]|10)$"),
          Validators.min(1),
          Validators.max(12),
        ],
      ],
      currentHeater: ["", Validators.required],
      age: ["6", Validators.required],
      model: [""],
    });
  }

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  handleActiveValue(data: string): void {
    if (data === "details") {
      this.isTechnicalExpanded = true;
      this.icon = true;
    } else if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon1 = true;
    }
  }

  expansionPanelClick(): void {
    this.icon = !this.icon;
  }

  faqExpansionClick(): void {
    this.icon1 = !this.icon1;
  }

  getHPWHViewDetails(val: TimeFrameEnum): void {
    this.learnMoreService
      .learnMoreInfo<HPWHDetailsModel>("hpwh")
      .pipe(take(1))
      .subscribe((response: HPWHDetailsModel) => {
        this.haveResponse = true;
        this.hpwhViewDetails = response;
        this.financialDetails = this.hpwhViewDetails.financialDetails;
        this.technicalDetails = this.hpwhViewDetails.technicalDetails;
        this.learnMoreDetails = this.hpwhViewDetails.learnMoreDetails;
        this.showIncentivesStatus =
          this.financialDetails.hpwhCost.showIncentives;
        this.assumptionDetails = this.hpwhViewDetails.assumptionsDetails;
        this.totalIncentives = this.financialDetails.hpwhCost.incentives;
        this.HPWHType = this.technicalDetails.currentHpwh.hpwhType;
        this.favStatus = this.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.paybackPeriod = this.financialDetails.hpwhPayback.paybackPeriod;
        this.displayPayback = this.financialDetails.hpwhPayback.displayPayback;
        this.displayPaybackGTTwenty =
          this.financialDetails.hpwhPayback.displayPaybackGTTwenty;
        if (this.displayPayback) {
          if (this.displayPaybackGTTwenty) {
            this.payBackYear = "greater than 20 years";
          } else {
            this.payBackYear = this.paybackPeriod + " years";
          }
        }
        this.mnfAndModel = this.learnMoreDetails.mnfAndModel;
        this.currentHeater = this.learnMoreDetails.currentHeater;
        this.hpwhFormDetails = this.technicalDetails;
        this.ageList = this.dashboardService.conventionalTypeRange;
        this.financeHpwhSavings = this.financialDetails.hpwhSavings;
        this.textUnderChart =
          "If you're able to add rooftop solar, the low-cost electricity from it can dramatically improve the economics of a heat pump water heater.";
        this.HPWHType =
          this.HPWHType === "Natural Gas Water Heater"
            ? "Gas Water Heater"
            : this.HPWHType;
        this.yAxisLabel = "Energy cost";
        this.yAxisLabel1 = "with heat pump";
        this.yAxisLabel2 = "water heater";
        this.updateChart(val);
        this.showTooltipZero = this.hpwh_Savings <= 0;
        this.showBlackLoader = this.loader = false;
      });
  }

  updateChart(val: TimeFrameEnum): void {
    let energyCost;
    let dataInputs: BarChartDataModel[] = [];

    this.setTimeSelector(val);
    energyCost = this.financeHpwhSavings.withHeatPumpWaterHeater[val];
    energyCost = energyCost <= 0 ? 0.0001 : energyCost;
    this.hpwh_Savings = this.financeHpwhSavings.total[val];
    dataInputs = [
      { y: energyCost, color: "#5BCADA" },
      {
        y: this.financeHpwhSavings.withoutHeatPumpWaterHeater[val],
        color: "#414141",
      },
    ];
    this.onUpdateChartOptions(
      dataInputs,
      this.yAxisLabel,
      this.yAxisLabel1,
      this.yAxisLabel2
    );
  }

  getHpwhMakeAndModelList() {
    this.restAPIService
      .getMakeAndModelList("hpwh")
      .pipe(take(1))
      .subscribe(
        (response: MNFAndModelListResponse) =>
          (this.makeAndModelList = response.mnfDropDownList)
      );
  }

  ngOnInit(): void {
    this.vendorName = this.dashboardService.getUserDetails().solarVendorCode;
    this.dashboardService
      .faqDetails()
      .pipe(take(1))
      .subscribe((list: SectionListModel) => {
        this.FAQTabsList = list.hpwh.faqList!;
      });
    this.showHelpMeChoose =
      this.dashboardService.vendorDetails.helpMeChoose.hpwh!;
    this.setTimeSelector(this.dashboardService.timeFrame);
    this.getHPWHViewDetails(this.selected);
    this.getHpwhMakeAndModelList();
    this.isPortable = this.overviewService.updateEnergyChoiceInfo(
      this.dashboardService.buildType
    );
    this.dashboardService
      .getMarketplaceDetails("hpwh")
      .pipe(take(1))
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails = response;
        this.adviceCardStatus = response.expertAdvice;
        this.displayMarketPlaceBox = this.marketPlaceDetails.displayBox;
        this.marketPlaceLink = this.marketPlaceDetails.buttonLink;
        if (this.marketPlaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketPlaceDetails.linkId;
        }
      });
  }

  isMNFAndModelListAbsent(): boolean {
    return this.makeAndModelList.length === 0;
  }

  setTimeSelector(val: TimeFrameEnum): void {
    this.selected = val;
    if (this.selected) {
      this.timeFrame = this.learnMoreService
        .renderTimeFrame(this.selected)
        .toLowerCase();
    }
  }

  onUpdateChartOptions(
    inputData: BarChartDataModel[],
    yAxisLabel,
    yAxisLabel1,
    yAxisLabel2
  ): void {
    let filteredInputData: BarChartDataModel[] = [];
    let i = 0;
    for (let entry of inputData) {
      if (entry.y != 0) {
        filteredInputData[i] = entry;
        i++;
      }
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    this.chartOptions1 = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        labels: {
          style: {
            color: "#333333",
            fontSize: "16px",
            fontFamily: "Open sans",
          },
        },
        categories: [
          `${yAxisLabel} <br> ${yAxisLabel1} <br> ${yAxisLabel2}`,
          `Energy cost with <br> ${this.HPWHType.toLowerCase()}`,
        ],
        gridLineWidth: 0,
        lineWidth: 1,
        tickWidth: 0,
      },
      yAxis: {
        tickAmount: 8,
        tickWidth: 0,
        gridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: null,
        },
        labels: {
          style: {
            color: "#333333",
            fontSize: "12px",
            fontFamily: "Open sans",
          },
          formatter: function (this) {
            if (Number(this.value) >= 1000) {
              return "$" + Number(this.value) / 1000 + "K";
            } else {
              return "$" + this.value;
            }
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          pointWidth: 50,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "Open sans",
            },
            formatter: function (this) {
              if (this.point.y && this.point.y < 1) {
                return "$0";
              } else {
                return (
                  "$" +
                  this.point.y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }
            },
            animation: {
              duration: 20,
            },
          },
        },
        series: {
          borderWidth: 0,
          animation: {
            duration: 1500,
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                className: "small-chart",
              },
            },
          },
        ],
      },
      series: [
        {
          type: "bar",
          showInLegend: false,
          data: filteredInputData,
        },
      ],
    };
    Highcharts.chart(this.container.nativeElement, this.chartOptions1);
  }

  get isNoOfPeopleOutOfRange(): boolean {
    return (
      this.hpwhForm.controls["howManyPeople"].hasError("pattern") ||
      this.hpwhForm.controls["howManyPeople"].hasError("min") ||
      this.hpwhForm.controls["howManyPeople"].hasError("max")
    );
  }

  get isNoOfPeopleRequired(): boolean {
    return this.hpwhForm.controls["howManyPeople"].hasError("required");
  }

  get isAgeRequired(): boolean {
    return this.hpwhForm.controls["age"].hasError("required");
  }

  get hpwhData(): LearnMoreRequestModel {
    return {
      hpwh: {
        howManyPeople: this.hpwhForm.get("howManyPeople")?.value,
        currentHeater: this.hpwhForm.get("currentHeater")?.value,
        age: this.hpwhForm.get("age")?.value,
        mnfAndModel: this.hpwhForm.get("model")?.value,
      },
    };
  }

  set hpwhFormDetails(details: HPWHTechnicalModel) {
    this.hpwhForm
      .get("howManyPeople")
      ?.setValue(this.learnMoreDetails.howManyPeople);
    this.hpwhForm.get("age")?.setValue(this.learnMoreDetails.age.toString());
    this.hpwhForm.get("model")?.setValue(this.mnfAndModel);
    this.hpwhForm.get("currentHeater")?.setValue(this.currentHeater);
  }

  onSaveDetails(isDetails: boolean): void {
    this.haveResponse = false;
    if (this.hpwhForm.get("currentHeater")?.value === "") {
      this.hpwhForm.get("currentHeater")?.reset();
    }
    if (
      this.learnMoreService.isFormEditted(
        this.hpwhData.hpwh!,
        this.learnMoreDetails
      )
    ) {
      if (isDetails) {
        this.saveButton.nativeElement.innerHTML =
          'Saving... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
      }
      this.dashboardService.dashboardDetailsAnalytics("save", "hpwh");
      this.learnMoreService
        .updateLearnMoreInfo("hpwh", this.hpwhData)
        .pipe(take(1))
        .subscribe(() => {
          this.timeoutId = setTimeout(() => {
            this.saveButton.nativeElement.innerHTML = "SAVE DETAILS";
            this.getHPWHViewDetails(this.selected);
          }, 800);
        });
    }
  }

  openHelpMeChoose(): void {
    let dialogRef = this.dialog.open(HelpMeChooseComponent, {
      width: "90%",
      height: "75%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "custom-dialog-container",
      data: { tech: "hpwh" },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result !== undefined) {
          this.hpwhForm.get("model")?.setValue(result);
          this.onSaveDetails(false);
        }
      });
  }

  handleFAQClick(): void {
    this.dashboardService.dashboardDetailsAnalytics("moreInfo", "hpwh");
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/heat-pump-water-heater-faq-es/",
      "_blank"
    );
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo("hpwh", ownPlan)
      .pipe(take(1))
      .subscribe(() => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getHPWHViewDetails(this.selected);

        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardService.techMessage("hpwh", action);
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
