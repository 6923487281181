import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../app.service";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import { DropDownModel } from "../../rest-api/rest-api.model";
import {
  energyEfficientDevicesModel,
  homeAppliancesModel,
  MarketPartnerModel,
  MarketPlaceCategoryListModel,
  MarketPlaceDataModel,
  MarketPlaceDetailsModel,
  MarketPlaceMakeDetailsModel,
  MarketPlaceModel,
  MarketPlaceTechModel,
  solarBatteryModel,
  transportationModel,
} from "./market-place.model";
import { MarketPlaceService } from "./market-place.service";
import { MarketPlaceAnalyticsModel } from "../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../overview/overview.service";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-market_place",
  templateUrl: "market-place.component.html",
  styleUrls: ["./market-place.component.scss"],
})
export class MarketPlaceComponent implements OnInit, OnDestroy {
  marketPlaceTabsList: DropDownModel[];
  loader = true;
  tabLabel: string = "energyEfficientDevices";
  marketplaceTechDetails: MarketPlaceDetailsModel;
  solarBattery: solarBatteryModel;
  homeAppliances: homeAppliancesModel;
  energyEfficientDevices: energyEfficientDevicesModel;
  transportationAndAccessories: transportationModel;
  communitySolar: MarketPartnerModel;
  showSuccessPopup: boolean = false;
  marketPlaceDevices: string[];
  tabIndex: number = 0;
  energySageLink: string;
  loweLink: string;
  buildingType: string;
  isPortable: boolean;
  solarLogo: string;
  showList: boolean;
  showDisclosureText: boolean = true;
  activeTech: string;
  guidanceLink: string;
  showScrollButton: boolean = false;
  scrollOffSet: number = 200;
  isLowe: boolean;
  imagePath = "assets/lifestyle/clean-choices/details/";
  energyEfficientJSON: MarketPlaceCategoryListModel;
  homeAppliancesJSON: MarketPlaceDataModel[];
  transportationJSON: MarketPlaceDataModel[];

  constructor(
    private marketPlaceService: MarketPlaceService,
    private dashboardAPI: DashboardAPIService,
    private activateRoute: ActivatedRoute,
    private overviewService: OverviewService,
    public snackBar: MatSnackBar,
    private titleService: Title,
    private appService: AppService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {}

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event): void {
    if (event.key == "Escape") {
      this.ngOnDestroy();
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.buildingType = this.dashboardAPI.getBuildingType();
    this.isLowe = this.dashboardAPI.getUserDetails().solarVendorCode === "LOWE";
    this.marketPlaceService
      .marketPlaceDetails()
      .pipe(take(1))
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketplaceTechDetails = response;
        this.homeAppliances = this.marketplaceTechDetails.homeAppliances;
        this.solarBattery = this.marketplaceTechDetails.solarBattery;
        this.energyEfficientDevices =
          this.marketplaceTechDetails.energyEfficientDevices;
        this.transportationAndAccessories =
          this.marketplaceTechDetails.transportationAndAccessories;
        this.isPortable = this.overviewService.updateEnergyChoiceInfo(
          this.buildingType
        );
        const solar = this.isPortable ? "communitySolar" : "solar";
        this.showList = response.solarBattery.showList;
        if (Object.keys(this.solarBattery).length !== 0) {
          this.communitySolar =
            this.marketplaceTechDetails.solarBattery[solar]?.installers[0]!;
          this.guidanceLink =
            this.marketplaceTechDetails.solarBattery[solar]?.guidanceLink!;
        }
        if (this.isLowe)
          this.loweLink = this.solarBattery.solar?.installers[0].link!;
        this.solarLogo = this.communitySolar?.logo;
        if (Object.keys(this.solarBattery).length)
          this.energySageLink = this.solarBattery[solar]?.installers[0].link!;
        if (this.isPortable) {
          this.marketPlaceService.marketPlaceTabsList[2].viewValue =
            "Community solar";
          this.marketPlaceService.onTabChange("communitySolar", "");
        } else {
          this.marketPlaceService.marketPlaceTabsList[2].viewValue =
            "Solar & Battery";
          this.marketPlaceService.onTabChange("solarBattery", "");
        }
        this.marketPlaceService
          .marketPlaceTechDetails()
          .pipe(take(1))
          .subscribe((marketPlaceTech: MarketPlaceTechModel) => {
            this.energyEfficientJSON = marketPlaceTech.energyEfficientDevices;
            this.homeAppliancesJSON = marketPlaceTech.homeAppliances;
            this.transportationJSON =
              marketPlaceTech.transportationAndAccessories;
          });
        this.marketPlaceService
          .getMarketPlaceMakeDetails()
          .pipe(take(1))
          .subscribe((marketPlaceMNF: MarketPlaceMakeDetailsModel) => {
            this.marketPlaceService.marketPlaceMnfList = marketPlaceMNF;
          });
        this.titleService.setTitle(
          `Marketplace | ${
            this.appService.vendorsList[this.appService.getVendorName()].title
          }`
        );
        this.getTabList();
        this.getTechLinkId();
      });
  }

  onTabChange(event: { value: string; index: number }): void {
    this.activeTech = "";
    this.tabIndex = event.index;
    this.tabLabel = event.value;
    this.marketPlaceService.onTabChange(event.value, "");
  }

  checkTabStatus(textLabel: string): boolean {
    return textLabel === this.tabLabel;
  }

  getTabList(): void {
    const label: string = this.activateRoute.snapshot.paramMap.get("details")!;
    this.activeTech = this.activateRoute.snapshot.paramMap.get("tech")!;
    let responseTabList: string[] = [];
    Object.entries(this.marketplaceTechDetails).forEach(
      (tab: [string, MarketPartnerModel]) => {
        if (Object.entries(tab[1]).length) {
          responseTabList.push(tab[0]);
        }
      }
    );
    this.marketPlaceTabsList =
      this.marketPlaceService.marketPlaceTabsList.filter((tab: DropDownModel) =>
        responseTabList.includes(tab.value)
      );
    this.tabLabel = label || this.marketPlaceTabsList[0].value;
    this.tabIndex = responseTabList.indexOf(this.tabLabel);
    setTimeout(() => {
      this.loader = false;
    }, 1500);
  }

  getTechLinkId(): void {
    Object.entries(this.marketplaceTechDetails.energyEfficientDevices).forEach(
      (tab: [string, MarketPlaceModel]) => {
        if (tab[1].installers[0].linkId === "AMAZON") {
          this.showDisclosureText = true;
        } else {
          this.showDisclosureText = false;
        }
      }
    );
  }

  offSuccessPopup(): void {
    this.showSuccessPopup = false;
  }

  onRequestQuote(tech: MarketPartnerModel, techName: string): void {
    const details: MarketPlaceAnalyticsModel = {
      tech: techName,
      linkId: tech.linkId,
    };
    this.dashboardAPI.marketplaceDetailsAnalytics(details);
    if (this.isLowe) {
      this.marketPlaceService.openShopNowTab(this.loweLink);
    } else if (this.isPortable) {
      window.open(this.energySageLink, "_blank");
    } else {
      this.marketPlaceService
        .energySageDetails()
        .pipe(take(1))
        .subscribe((response) => {
          let token = response.token;
          let energySageUrl = `${this.energySageLink}${token}`;
          window.open(energySageUrl, "_blank");
        });
    }
  }

  onReqQuoteStatus(techName: string, vendorId: string): void {
    const details: MarketPlaceAnalyticsModel = {
      tech: techName,
      linkId: vendorId,
    };
    this.dashboardAPI.getRequestQuote(vendorId).pipe(take(1)).subscribe(() => {
      this.snackBar.open(
        "Thank you! Your quote request has been submitted.",
        "OK",
        {
          duration: 8000,
        }
      );
    });
    this.dashboardAPI.marketplaceDetailsAnalytics(details);
  }

  onBackClick(): void {
    const redirectToDashboard = Boolean(
      sessionStorage.getItem("redirectToDashboard")
    );
    if (redirectToDashboard) {
      sessionStorage.removeItem("redirectToDashboard");
      this.router.navigateByUrl("/dashboard/overview");
    } else {
      window.history.back();
    }
  }

  ngOnDestroy(): void {
    this.showSuccessPopup = false;
  }

  onViewBuyingGuide(): void {
    this.marketPlaceService.openShopNowTab(this.guidanceLink);
    const details: MarketPlaceAnalyticsModel = {
      tech: "solar",
      detail: "viewBuyingGuide",
    };
    this.dashboardAPI.marketplaceDetailsAnalytics(details);
  }
}
