<scroll-top></scroll-top>
<div class="page-container yt__energy_info">
  <div class="padding-15-class">
    <div class="yt_maxWidth">
      <div *ngIf="pgeSuccess || pgeInprogress" class="error_container">
        <div *ngIf="pgeSuccess" class="error_info">
          <mat-icon class="done_icon">done</mat-icon>
          <div class="error_heading_text">You are all set!</div>
          <div class="error_subheading_text">
            You have successfully completed your energy data authorization via
            the PG&E Share My Data platform. We can now analyze your detailed
            energy data and deliver more-accurate and personalized
            recommendations.
          </div>
          <div class="error_subheading_text">
            Take me to
            <a class="link_text" (click)="onMydashboard()">My Dashboard</a>
          </div>
        </div>
        <div *ngIf="pgeInprogress" class="error_info">
          <div class="error_heading_text">Thank You!</div>
          <div class="error_subheading_text">
            You have completed your energy data authorization via the PG&E Share
            My Data platform. This process can take up to 20 minutes and your
            dashboard results will automatically be updated.
          </div>
        </div>
      </div>
      <div class="message_info">
        <div *ngIf="EnergyUsageScreen" class="energy_info">
          <div class="header_text">Energy Info Settings</div>
          <div class="subheader_text">
            Your information will help determine the right clean energy
            solutions.
          </div>

          <div class="provider_container">
            <div class="main_label">Utility</div>
            <div class="sub_label">
              <div class="electric_provider">
                <div [ngClass]="{ utility_name: utilityName }">
                  {{ utilityName }}
                </div>
                <div class="provider_label">Electric</div>
              </div>
            </div>
          </div>
          <div class="authorization_box">
            <mat-tab-group
              class="demo-tab-group"
              (selectedIndexChange)="onTabChange($event)"
              mat-stretch-tabs
            >
              <mat-tab *ngIf="authorizationSection">
                <ng-template mat-tab-label>
                  <div class="yt_inline_block">
                    <div class="tabheader_text">Share My Data</div>
                    <div class="tabsubheader_text">
                      Authorize PG&E to share your energy data
                    </div>
                  </div>
                </ng-template>
                <div class="demo-tab-content">
                  <yt-energy-sync
                    [authorizeEnergyUsageUrl]="authorizeEnergyUsageUrl"
                    [smdTabStatus]="authorizationSectionPGE"
                    [haveResponse]="haveResponse"
                    [isDashboard]="true"
                  >
                  </yt-energy-sync>
                </div>
              </mat-tab>
              <mat-tab *ngIf="revokedAuth">
                <ng-template mat-tab-label>
                  <div class="demo-tab-content">
                    <div class="tabheader_text">Manual Estimate</div>
                    <div class="tabsubheader_text">
                      Provide a rough estimate of electricity costs
                    </div>
                  </div>
                </ng-template>
                <div class="demo-tab-content">
                  <yt-manual-entry [isDashboard]="true"></yt-manual-entry>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <div
            class="confirm_authorize"
            *ngIf="authorizationSection && authorizeLink"
          >
            <div>
              After authorization,
              <a class="link_text" (click)="onRefresh()">click here</a> to check
              PG&E Share My Data authorization status.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="loader" (click)="offRefresh()">
  <div id="text">
    <div class="bg-white">
      <div class="loader_info">
        We are still retrieving your energy information...
      </div>
      <div class="loader mh-auto"></div>
    </div>
  </div>
</div>

<div id="noAuth" (click)="offRefresh()">
  <div id="text">
    <div class="bg-white">
      <div class="not_auth">Not Authorized!</div>
      <div class="not_auth_msg">
        You have not authorized your energy data yet. Please click the LOGIN TO
        PG&E button to continue..
      </div>
    </div>
  </div>
</div>
