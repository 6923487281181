<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">Electric Vehicle Charger</div>
      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isSavings]="false"
        [isDescription]="false"
        [isSettings]="true"
        [isDetails]="true"
        [isAssumptions]="false"
        [isFaq]="true"
        [techParameter]="'evChargers'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>
      <div>
        <div
          class="yt-text"
          [innerHTML]="
            'homeOwenerDashboard.learnMore.evChargers.paragraph' | translate
          "
        ></div>
        <div *ngIf="!isLangSpanish" id="aboutTechnology">
          <yt-ev-chargers-faq [showAboutTech]="true"></yt-ev-chargers-faq>
        </div>
        <div class="faq_mobile">
          <button
            (click)="onFavorite()"
            class="favorite_btn"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
      </div>
      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="assets/lifestyle/evcLearnmore.png"
              alt="technology icon"
              [ngStyle]="{ height: !displayMarketPlaceBox ? '160px' : '300px' }"
            />
          </div>
          <button
            (click)="onFavorite()"
            class="mt_20 favorite_btn"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="mt_20 faq_button"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
        <div class="width-box" id="cost" *ngIf="evChargerCostModel">
          <yt-cost-rendering
            [financialDetails]="evChargerCostModel"
            [paymentTypeLabel]="'Net cost'"
            [showCash]="showCash"
            [show_Upfront_price]="true"
            [showIncentivesStatus]="showIncentivesStatus"
            [lease]="false"
            [showIncentiveBreakdown]="showIncentiveBreakdown"
            [heading]="'Incentives Breakdown'"
            [netCost]="'Total'"
            (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
            [techName]="'evChargers'"
            [upfrontCostTooltipText]="
              'Cost of equipment, installation (including electrical work) and permits before consideration of any incentives. Excludes any cost for main service panel upgrade (which is sometimes needed).'
            "
            [netCostTooltipText]="'Net cost = Upfront cost - Incentives'"
          >
          </yt-cost-rendering>

          <yt-market-place
            [tech_name]="'evChargers'"
            [linkId]="linkId"
            [marketPlaceDetails]="marketPlaceDetails"
            *ngIf="marketPlaceDetails && displayMarketPlaceBox"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'evChargers'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div
        class="bottom_section w-100 yt__pt-0"
        [formGroup]="evChargerForm"
        id="settings"
      >
        <div class="left_section">
          <div class="details_card evc_margin-top">
            <div class="details_header">PERSONALIZE</div>
            <div class="form_label flex justify-between items-baseline">
              Make & Model
              <span
                class="help_me_find"
                (click)="openHelpMeChoose()"
                *ngIf="showHelpMeChoose"
              >
                <span class="helpme">Help me choose</span>
                <span class="choose">Choose</span>
              </span>
            </div>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker="true"
              class="w-100 formfield_padding"
              appearance="outline"
            >
              <mat-select placeholder="Make & Model" formControlName="model">
                <mat-option
                  *ngFor="let makeAndModel of makeAndModelList"
                  [value]="makeAndModel.mnfAndModel"
                  [matTooltip]="makeAndModel.mnfAndModel"
                  >{{ makeAndModel.mnfAndModel }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              class="save_button"
              [ngClass]="{
                '': !displayMarketPlaceBox,
                'ghost-button': displayMarketPlaceBox,
                disable_button: evChargerForm.invalid
              }"
            >
              <button
                mat-raised-button
                color="accent"
                #loaderButton
                [disabled]="evChargerForm.invalid"
                (click)="onSaveDetails()"
              >
                SAVE DETAILS
              </button>
            </div>
          </div>
        </div>
        <div class="right_section" id="details">
          <div class="summary_card w-100">
            <mat-expansion-panel
              (closed)="isTechnicalExpanded = false"
              [expanded]="isTechnicalExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="technicalInfoPanel()">
                <mat-panel-title class="flex items-center">
                  Technical Details &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <div class="left_side_values">
                  <div class="label_heading w-100">Summary</div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <div>Type</div>
                      <div *ngIf="evChargerSummaryModel">
                        {{ evChargerSummaryModel.type }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Output power</div>
                      <div *ngIf="evChargerSummaryModel">
                        {{ evChargerSummaryModel.outputPower }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Voltage</div>
                      <div *ngIf="evChargerSummaryModel">
                        {{ evChargerSummaryModel.voltage }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Circuit breaker size</div>
                      <div *ngIf="evChargerSummaryModel">
                        {{ evChargerSummaryModel.circuitBreakerSize }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Warranty</div>
                      <div *ngIf="evChargerSummaryModel">
                        {{ evChargerSummaryModel.warranty }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

          <div class="faq_card" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="basicInfoPanel()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon1 ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-ev-chargers-faq
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-ev-chargers-faq>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div
        class="link_text back_to_top"
        (click)="scrollToTop()"
        *ngIf="!isLangSpanish"
      >
        <mat-icon class="pointer mat_color">keyboard_arrow_up</mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<yt-breakdown-popup
  *ngIf="haveResponse && showIncentiveBreakdown"
  [heading]="'Incentives Breakdown'"
  [netCost]="'Total'"
  (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
  [techName]="'evChargers'"
>
</yt-breakdown-popup>

<!-- loader -->
<yt-loader
  [blackLoader]="showBlackLoader"
  *ngIf="loader || showBlackLoader"
  top="0"
  [relative]="true"
></yt-loader>
