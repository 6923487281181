import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { HelpMeChooseComponent } from "../../shared/help-me-choose/help-me-choose.component";
import {
  LearnMoreRequestModel,
  MarketPlaceDetailsModel,
} from "../learn-more.model";
import { LearnMoreService } from "../learn-more.service";
import {
  makeAndModelList,
  SmartThermostatAssumptionModel,
  SmartThermostatCostModel,
  SmartThermostatDetailsModel,
  SmartThermostatFinancialModel,
  SmartThermostatLearnMoreModel,
  SmartThermostatTechnicalModel,
} from "./smart-thermostat-details.model";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { AppService } from "../../../app.service";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-smart-thermostat-details",
  templateUrl: "./smart-thermostat-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class SmartThermostatDetailsComponent
  implements OnInit, AfterContentChecked
{
  loader: boolean = true;
  showHelpMeChoose: boolean;
  showIncentiveBreakdown: boolean = false;
  readMore: boolean = true;
  readLess: boolean = false;
  icon: boolean = false;
  totalIncentives: number;
  marketplaceDetails: MarketPlaceDetailsModel;
  displayMarketPlace: boolean;
  marketPlaceLink: string;
  linkId: string;
  makeAndModel: string;
  selected: string;
  twentyYear: boolean;
  oneYear: boolean;
  oneMonth: boolean;
  tenYear: boolean;
  haveResponse: boolean = false;
  incentiveStatus: boolean;
  thermostatForm: FormGroup;
  techDescription: string;
  mnfDropDownList: makeAndModelList[];
  assumptionDetails: SmartThermostatAssumptionModel;
  financialDetails: SmartThermostatFinancialModel;
  costDetails: SmartThermostatCostModel;
  learnMoreDetails: SmartThermostatLearnMoreModel;
  technicalDetails: SmartThermostatTechnicalModel;
  adviceCardStatus: boolean;
  activeIndex: number = 0;
  tabIndex: number = 0;
  tabLabel: string;
  isLangSpanish: boolean;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  isFAQExpanded: boolean;
  favStatus: boolean;
  favIcon: string;
  showCash: boolean = true;
  timeoutId: any;
  showBlackLoader: boolean;
  @ViewChild("loaderButton", { read: ElementRef }) saveButton: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private learnMoreService: LearnMoreService,
    private dialog: MatDialog,
    private restAPI: RestAPIService,
    private dashboardAPI: DashboardAPIService,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private appService: AppService
  ) {
    this.makeAndModel = this.learnMoreService.thermostatMnfModel;
    this.thermostatForm = this.fb.group({
      model: [this.makeAndModel, Validators.required],
    });
  }

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.dashboardAPI
      .faqDetails()
      .pipe(take(1))
      .subscribe((list: SectionListModel) => {
        this.FAQTabsList = list.smartThermostat.faqList!;
      });
    this.showHelpMeChoose =
      this.dashboardAPI.vendorDetails.helpMeChoose.thermostat!;
    this.getMakeAndModelList();
    this.marketPlaceAPI();
    this.getThermostatDetails();
  }

  getMakeAndModelList(): void {
    this.restAPI
      .getMakeAndModelList("smartThermostat")
      .pipe(take(1))
      .subscribe((data) => (this.mnfDropDownList = data.mnfDropDownList));
  }

  marketPlaceAPI(): void {
    this.dashboardAPI
      .getMarketplaceDetails("thermostat")
      .pipe(take(1))
      .subscribe((data: MarketPlaceDetailsModel) => {
        this.marketplaceDetails = data;
        this.adviceCardStatus = data.expertAdvice;
        this.displayMarketPlace = this.marketplaceDetails.displayBox;
        this.marketPlaceLink = this.marketplaceDetails.buttonLink;
        this.linkId = this.marketplaceDetails.linkId;
        if (this.marketplaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketplaceDetails.linkId;
        }
      });
  }

  getThermostatDetails(): void {
    this.learnMoreService
      .learnMoreInfo<SmartThermostatDetailsModel>("thermostat")
      .pipe(take(1))
      .subscribe((data: SmartThermostatDetailsModel) => {
        this.haveResponse = true;
        this.assumptionDetails = data.assumptionsDetails;
        this.financialDetails = data.financialDetails;
        this.costDetails = this.financialDetails.costDetails;
        this.totalIncentives = this.costDetails.incentives;
        this.technicalDetails = data.technicalDetails;
        this.incentiveStatus = this.costDetails.showIncentives;
        this.learnMoreDetails = data.learnMoreDetails;
        this.thermostatForm
          .get("model")
          ?.setValue(this.learnMoreDetails.thermostatMnfAndModel);
        this.favStatus = this.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.techDescription = this.technicalDetails.thermostatMoreDetails[0];
        this.showBlackLoader = this.loader = false;
      });
  }

  get smartThermostatData(): LearnMoreRequestModel {
    return {
      smartThermostat: {
        thermostatMnfAndModel: this.thermostatForm.get("model")?.value,
      },
    };
  }

  onSaveDetails(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("save", "smartThermostat");
    this.haveResponse = false;
    this.OnUpdateLearnMoreData();
  }

  openHelpMeChoose(): void {
    let dialogRef = this.dialog.open(HelpMeChooseComponent, {
      width: "90%",
      height: "80%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "custom-dialog-container",
      data: { tech: "thermostat" },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: string) => {
        if (result !== undefined) {
          this.thermostatForm.get("model")?.setValue(result);
          this.dashboardAPI.dashboardDetailsAnalytics(
            "save",
            "smartThermostat"
          );
          this.OnUpdateLearnMoreData();
        }
      });
  }

  OnUpdateLearnMoreData(): void {
    if (
      this.learnMoreService.isFormEditted(
        this.smartThermostatData.smartThermostat!,
        this.learnMoreDetails
      )
    ) {
      this.saveButton.nativeElement.innerHTML =
        'Saving... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
      this.learnMoreService
        .updateLearnMoreInfo("smartThermostat", this.smartThermostatData)
        .pipe(take(1))
        .subscribe(() => {
          this.getThermostatDetails();
          this.timeoutId = setTimeout(() => {
            this.saveButton.nativeElement.innerHTML = "SAVE DETAILS";
          }, 800);
        });
    }
  }

  handleActiveValue(data: string): void {
    if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon = true;
    }
  }

  faqExpansionClick(): void {
    this.icon = !this.icon;
  }

  onReadContent(value: string) {
    if (value === "readMore") {
      this.readMore = false;
      this.readLess = true;
      this.techDescription += this.technicalDetails.thermostatMoreDetails[1];
    } else if (value === "readLess") {
      this.readLess = false;
      this.readMore = true;
      this.techDescription = this.technicalDetails.thermostatMoreDetails[0];
    }
  }

  handleFAQClick(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("moreInfo", "smartThermostat");
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/smart-thermostat-faq-es/",
      "_blank"
    );
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo("smartThermostat", ownPlan)
      .pipe(take(1))
      .subscribe(() => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getThermostatDetails();

        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardAPI.techMessage("smartThermostat", action);
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
