<div>
  <div class="tech__summary">
    <div class="heading">{{ header }}</div>
    <div
      class="paragraph"
      [innerHTML]="'homeOwenerDashboard.learnMore.solar.paragraph' | translate"
    ></div>
  </div>
</div>

<form [formGroup]="solarForm" class="form__container">
  <div class="form__wrapper">
    <div>
      <mat-radio-group
        class="radioButton-flex"
        formControlName="own"
        (change)="condition()"
      >
        <div class="cards" *ngFor="let radioOptions of radioButtonOptions">
          <label>
            <yt-radio-buttons
              [viewValue]="radioOptions.viewValue"
              [icon]="radioOptions.icon"
              [default]="selectedRadioButton"
              [value]="radioOptions.value"
            ></yt-radio-buttons>
            <mat-radio-button
              class="hidden_button"
              [value]="radioOptions.value"
              (change)="onRadioChange($event)"
            >
            </mat-radio-button>
          </label>
        </div>
      </mat-radio-group>
      <div class="form__field-wrapper" *ngIf="isOwnNo">
        <section
          class="form__field-section"
          *ngIf="roofTopPageModel && hideFormField"
        >
          <div class="lables">Roof Slope</div>
          <mat-form-field
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-select placeholder="Roof slope" formControlName="roofSlope">
              <mat-option
                *ngFor="let roofSlope of roofTopPageModel.roofSlopes"
                [value]="roofSlope.value"
                >{{ roofSlope.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="solarForm.controls['roofSlope'].hasError('required')"
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>

        <section
          class="form__field-section"
          *ngIf="roofTopPageModel && hideFormField"
        >
          <div class="lables">Roof Shade</div>
          <mat-form-field
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-select placeholder="Roof shade" formControlName="roofShade">
              <mat-option
                *ngFor="let roofShade of roofTopPageModel.roofShades"
                [value]="roofShade.value"
                >{{ roofShade.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="solarForm.controls['roofShade'].hasError('required')"
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>

        <section
          class="form__field-section"
          *ngIf="haveFinance && hideFormField"
        >
          <div class="field__label">Financing</div>
          <mat-form-field floatLabel="auto" appearance="outline">
            <mat-select placeholder="Financing" formControlName="finance">
              <mat-option
                *ngFor="let finance of financeList"
                [value]="finance.value"
                >{{ finance.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="solarForm.controls['finance'].hasError('required')"
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>

        <section class="form__field-section" *ngIf="ownValue === 'yes'">
          <div class="field__label">Current System Size</div>
          <mat-form-field
            floatLabel="auto"
            appearance="outline"
            hideRequiredMarker="true"
          >
            <mat-select
              placeholder="Current system size"
              formControlName="systemSize"
            >
              <mat-option
                *ngFor="let systemSize of systemSizeList"
                [value]="systemSize.value"
              >
                {{ systemSize.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                solarForm.controls['systemSize'].hasError('required') &&
                solarForm.controls['systemSize'].touched
              "
              >Required!</mat-error
            >
          </mat-form-field>
        </section>
        <section class="form__field-section" *ngIf="disableDatePicker">
          <div class="field__label">Purchased month & year</div>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker="true"
            appearance="outline"
          >
            <mat-select
              placeholder="Select"
              formControlName="datePurchased"
              required
            >
              <mat-option *ngFor="let month of monthList" [value]="month"
                >{{ month | titlecase }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                (isDatePurchasedEmpty() &&
                  solarForm.controls['datePurchased'].touched) ||
                haveInputError
              "
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>
      </div>
    </div>
  </div>

  <div class="action__wrapper">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveSolar()"
      [disabled]="solarForm.invalid"
      class="primary__button"
    >
      SAVE
    </button>
  </div>
  <div class="note__message">
    NOTE: The above fields have been pre-filled with default values.
  </div>
</form>
