import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { AppService } from "../app.service";
import { AuthenticationService } from "../auth/auth.service";
import { RestAPIService } from "../rest-api/rest-api.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "yt-homeowner-dashboard",
  templateUrl: "./homeowner-dashboard.component.html",
})
export class HomeOwnerDashboardComponent implements OnInit {
  private destroy$ = new Subject<void>();
  constructor(
    private router: Router,
    private restAPI: RestAPIService,
    private titleService: Title,
    private appService: AppService,
    private authService: AuthenticationService
  ) {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        const learningHubMenu =
          document.querySelector<HTMLSpanElement>("#learning-hub-menu");
        const learningHubActive = event.url.includes("learning-hub");
        if (learningHubMenu) {
          learningHubMenu.style.fontWeight = learningHubActive ? "600" : "400";
        }
        if (!learningHubActive) {
          this.restAPI.categoryIndex = -1;
        }
      }
      if (event instanceof NavigationEnd) {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = "";
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data["title"]) {
          routeTitle = route!.snapshot.data["title"];
        }
        this.titleService.setTitle(
          `${routeTitle} | ${
            this.appService.vendorsList[this.appService.getVendorName()].title
          }`
        );
      }
    });
  }

  ngOnInit(): void {
    if (!this.restAPI.homeOwnerEmail && this.authService.jwtTokenInfo) {
      this.restAPI.homeOwnerEmail = this.authService.jwtTokenInfo.userName;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
