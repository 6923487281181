<scroll-top></scroll-top>
<div class="page-container yt__change_pwd">
  <div class="padding-93-class">
    <div class="yt_margin">
      <div class="formSection yt_maxWidth">
        <form [formGroup]="resetPassword" class="form_width">
          <div class="heading_text">Change Password</div>
          <div class="form_field_padding1">
            <div class="left-form">
              <div>
                <mat-form-field
                  class="lfield current-password_field"
                  floatLabel="auto"
                  hideRequiredMarker="true"
                  appearance="outline"
                >
                  <mat-label>Current password</mat-label>
                  <input
                    matInput
                    formControlName="currentPassword"
                    [type]="hideCurrentPassword ? 'password' : 'text'"
                    autocomplete="off"
                  />
                  <mat-icon
                    matSuffix
                    class="mat-icon-color"
                    (click)="hideCurrentPassword = !hideCurrentPassword"
                  >
                    {{
                      hideCurrentPassword ? "visibility_off" : "visibility"
                    }}</mat-icon
                  >
                  <mat-error *ngIf="userDetailsFormValidation.currentPassword">
                    {{ userDetailsFormValidation.currentPassword }}
                  </mat-error>
                </mat-form-field>
                <div
                  class="error_message"
                  *ngIf="
                    invalidCurrentPassword &&
                    !userDetailsFormValidation.currentPassword
                  "
                >
                  Invalid current password
                </div>
              </div>
              <div
                [ngStyle]="{
                  'padding-top':
                    invalidCurrentPassword &&
                    !userDetailsFormValidation.currentPassword
                      ? '20px'
                      : '41px'
                }"
              >
                <mat-form-field
                  class="new-password_field"
                  floatLabel="auto"
                  hideRequiredMarker="true"
                  appearance="outline"
                >
                  <mat-label>New password</mat-label>
                  <input
                    matInput
                    formControlName="newPassword"
                    [type]="hideNewPassword ? 'password' : 'text'"
                    autocomplete="off"
                    minlength="8"
                  />
                  <mat-icon
                    matSuffix
                    class="mat-icon-color"
                    (click)="hideNewPassword = !hideNewPassword"
                  >
                    {{
                      hideNewPassword ? "visibility_off" : "visibility"
                    }}</mat-icon
                  >
                  <mat-hint>Use at least 8 characters</mat-hint>
                  <mat-error
                    *ngIf="userDetailsFormValidation.newPassword.required"
                  >
                    {{ userDetailsFormValidation.newPassword.required }}
                  </mat-error>
                </mat-form-field>
                <yt-password-meter
                  [passwordToCheck]="resetPassword.value.newPassword"
                  [barLabel]="barLabel"
                ></yt-password-meter>
              </div>
            </div>
          </div>
          <p class="reset_password">{{ resetPasswordStatus }}</p>
          <div class="save_button">
            <button
              mat-raised-button
              [disabled]="resetPassword.invalid"
              color="accent"
              (click)="onSubmit()"
            >
              SAVE
            </button>
          </div>
        </form>
      </div>
      <div class="emailInfoMsg" *ngIf="showEmailInfoMsg">
        Please check your email inbox for your current password.
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="showSuccessMessage"
  class="successMessage"
  (click)="showSuccessMessage = false"
>
  <div id="text">
    <div class="password_change">
      <mat-icon class="done_icon pa2 flex center">done</mat-icon>
      <div class="password_message yt_popup-header">
        Password changed successfully
      </div>
    </div>
  </div>
</div>
