import { Component, OnInit, AfterContentInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AppService } from "../../app.service";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { environment } from "../../../environments/environment";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-learning-hub",
  templateUrl: "./learning-hub.component.html",
  styleUrls: ["./learning-hub.component.scss"],
})
export class LearningHubComponent implements OnInit, AfterContentInit {
  geniallyLink: string;
  loader: boolean = true;
  vendorCode: string;
  userEmail: string;
  dashboardStatus: boolean = false;
  vendorLink: string;

  constructor(
    private dashboardAPIService: DashboardAPIService,
    private appService: AppService,
    private restAPI: RestAPIService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    if (this.dashboardAPIService.getUserDetails()) {
      this.vendorCode =
        this.dashboardAPIService.getUserDetails().solarVendorCode;
    } else {
      this.vendorCode = this.appService.getVendorName();
    }
    this.vendorCode = this.vendorCode || "YLTN";
    this.titleService.setTitle(
      `Learning Hub | ${this.appService.vendorsList[this.vendorCode].title}`
    );
    this.dashboardStatus = !!this.dashboardAPIService.getUserDetails();
    this.dashboardAPIService
      .getSolarVendorDetails(this.vendorCode)
      .pipe(take(1))
      .subscribe((response) => {
        this.loader = false;
        this.geniallyLink = response.geniallyLink;
        const el = document.querySelector(".image-container__mapping");
        if (el) el.setAttribute("src", this.geniallyLink);
        this.vendorLink = response.vendorLink;
      });
  }

  getTranslateText(tabName): string {
    return this.restAPI.getTranslateText(
      `homeOwenerDashboard.dashboard.learningHub.${tabName}`
    );
  }

  onGetStarted(): void {
    const isProd = environment.production;
    if (this.vendorCode === "YSLR") {
      window.open(`${this.vendorLink}sign-up/?vendor=YSLR`, "_blank");
    } else if (isProd) {
      if (this.vendorCode === "SUNW") {
        window.open(`${this.vendorLink}sign-up`, "_blank");
      } else {
        window.open(`${this.vendorLink}register`, "_blank");
      }
    } else {
      if (this.vendorCode === "SUNW") {
        window.open(`${this.vendorLink}sign-up?vendor=SUNW`, "_blank");
      } else {
        window.open(
          `${this.vendorLink}register?vendor=${this.vendorCode}`,
          "_blank"
        );
      }
    }
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      const learningHubMenu =
        document.querySelector<HTMLSpanElement>("#learning-hub-menu");
      if (learningHubMenu) learningHubMenu.style.fontWeight = "600";
    }, 10);
  }
}
