import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { SolarDataModel } from "../../../../model/userdata.model";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { LearnMoreService } from "../../../../../learn-more/learn-more.service";
import { RadioOptionsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { MatRadioChange } from "@angular/material/radio";
import { RoofTopPageModel } from "../../../../../../homeowner-product/roof-top/roof-top.model";
import { takeWhile } from "rxjs/operators";
import { DropDownModel } from "../../../../../../rest-api/rest-api.model";
import { SettingsDialogData } from "../../setting-overlay.component.model";
import { SettingsOverlayService } from "../../settings-overlay.service";
import { Utils } from "../../../../../../utils/util";
import { Subject } from "rxjs";
import { DataModel } from "src/app/homeowner-dashboard/overview/model/dashboard.model";
import { OverviewService } from "src/app/homeowner-dashboard/overview/overview.service";

@Component({
  selector: "yt-solar",
  templateUrl: "solar.component.html",
  styleUrls: ["../../common-tech.scss"],
})
export class SolarComponent implements OnInit, OnDestroy, AfterContentChecked {
  panelOpenState: boolean = false;
  solarForm: FormGroup;
  disableDatePicker: boolean = false;
  isOwnNo: boolean = true;
  solarDataModel: SolarDataModel[];
  monthList: string[];
  lastTwelthMonth: string;
  ownValue: string;
  buildingType: string;
  header: string = "Rooftop Solar";
  solarInfo: DataModel;
  haveFinance: boolean;
  selectedRadioButton: string;
  radioButtonOptions: RadioOptionsModel[];
  financeList: DropDownModel[];
  systemSizeList: DropDownModel[];
  roofTopPageModel: RoofTopPageModel;
  hideFormField: boolean;
  @Input() solarDetails;
  @Input() activeIcon: string;
  subject: Subject<any> = new Subject();
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();
  private alive = true;
  current_range: DropDownModel[];
  solarSettings: SolarDataModel[];
  haveInputError: boolean;

  constructor(
    private fb: FormBuilder,
    private dashboardAPIService: DashboardAPIService,
    private overviewService: OverviewService,
    private utils: Utils,
    private learnMoreService: LearnMoreService,
    private settingService: SettingsOverlayService
  ) {
    this.current_range = this.learnMoreService.typeOfGas;
    this.financeList = this.learnMoreService.financeList;
    this.systemSizeList = this.settingService.systemSizeList;
    this.solarForm = this.fb.group({
      own: ["", Validators.required],
      roofSlope: ["", Validators.required],
      roofShade: ["", Validators.required],
      finance: ["cash", Validators.required],
      systemSize: [
        "5",
        [
          Validators.required,
          Validators.min(0),
          Validators.max(20),
          Validators.pattern("^[0-9]*(.[0-9]{0,2})?$"),
        ],
      ],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngAfterContentChecked(): void {
    const datePurchased = this.solarForm.get("datePurchased")!;
    if (this.haveInputError) this.haveInputError = datePurchased.invalid;
  }

  ngOnInit() {
    this.buildingType = this.dashboardAPIService.buildType;
    this.haveFinance =
      this.dashboardAPIService.vendorDetails.solutionType !== "vendor";
    if (this.overviewService.updateEnergyChoiceInfo(this.buildingType))
      this.header = "Community Solar";
    this.dashboardAPIService.getRadioButtonDetails().subscribe((data) => {
      this.radioButtonOptions = data.radioButtons;
    });
    this.getRoofTopQA();
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    this.haveInputError = this.settingService.checkSettingsInfoError("solar");
    this.solarDataModel = this.haveInputError
      ? this.settingService.settingsInfoErrors.solarBattery?.solar!
      : this.settingService.checkSettingsInfo("solar")
      ? this.settingService.settingsInfo.solarBattery?.solar!
      : this.dashboardAPIService.energyChoicesData.solarBattery.solar!;
    this.solarDataModel.forEach((solar: SolarDataModel) => {
      this.ownValue = solar.own;
      this.setSelectedRadioValues(this.ownValue);
      this.solarForm.patchValue(solar);
      this.solarForm.get("roofSlope")?.setValue(solar.roofSlope);
      this.solarForm.get("roofShade")?.setValue(solar.roofShade);
      if (this.ownValue === "yes") {
        this.hideFormField = false;
        this.solarForm.get("systemSize")?.enable();
        if (!solar.bought) {
          this.solarForm.get("datePurchased")?.setValue(this.lastTwelthMonth);
        }
        this.solarForm.get("roofSlope")?.disable();
        this.solarForm.get("roofShade")?.disable();
        this.disableDatePicker = true;
        this.isOwnNo = true;
      } else if (this.ownValue === "yesPlan") {
        this.hideFormField = true;
        this.solarForm.enable();
        this.solarForm.get("systemSize")?.disable();
        this.solarForm.get("datePurchased")?.disable();
        if (!this.haveFinance && this.hideFormField) {
          this.solarForm.get("finance")?.disable();
        } else {
          this.solarForm.get("finance")?.enable();
          this.solarForm.get("finance")?.setValue(solar.financial);
        }
        this.disableDatePicker = false;
        this.isOwnNo = true;
      } else if (this.ownValue === "noPlan") {
        this.hideFormField = true;
        this.solarForm.enable();
        this.solarForm.get("systemSize")?.disable();
        this.solarForm.get("datePurchased")?.disable();
        this.disableDatePicker = false;
        this.isOwnNo = true;
        if (!this.haveFinance && this.hideFormField) {
          this.solarForm.get("finance")?.disable();
        } else {
          this.solarForm.get("finance")?.enable();
          this.solarForm.get("finance")?.setValue(solar.financial);
        }
      } else if (this.ownValue === "no") {
        this.isOwnNo = false;
      }

      this.solarForm.get("own")?.enable();
    });
    if (this.activeIcon) {
      if (this.activeIcon === "favorite") {
        this.solarForm.get("own")?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.solarForm.get("own")?.setValue("yes");
      }
      this.onValueChanges();
      this.setSelectedRadioValues(this.solarForm.get("own")?.value);
      this.condition();
    } else {
      this.onValueChanges();
    }
  }

  getRoofTopQA() {
    const vendorCode = this.dashboardAPIService.userDetails.solarVendorCode;
    this.dashboardAPIService
      .getAllRoofTopQA(vendorCode)
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.roofTopPageModel = data;
      });
  }

  onRadioChange(event: MatRadioChange) {
    this.setSelectedRadioValues(event.value);
  }

  setSelectedRadioValues(value: string): void {
    this.selectedRadioButton = value;
  }

  condition() {
    this.ownValue = this.solarForm.get("own")?.value;
    const solar =
      this.dashboardAPIService.energyChoicesData.solarBattery.solar![0];
    if (this.ownValue === "yes") {
      this.hideFormField = false;
      this.solarForm.get("roofSlope")?.disable();
      this.solarForm.get("roofShade")?.disable();
      this.solarForm.get("systemSize")?.enable();
      this.solarForm.get("bought")?.enable();
      this.solarForm.get("datePurchased")?.enable();
      this.disableDatePicker = true;
      this.isOwnNo = true;
    } else if (this.ownValue === "yesPlan") {
      this.hideFormField = true;
      this.solarForm.get("roofSlope")?.enable();
      this.solarForm.get("roofShade")?.enable();
      this.solarForm.get("roofSlope")?.setValue(solar.roofSlope);
      this.solarForm.get("roofShade")?.setValue(solar.roofShade);
      this.solarForm.get("systemSize")?.disable();
      this.solarForm.get("bought")?.disable();
      this.solarForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
      this.isOwnNo = true;
      if (!this.haveFinance && this.hideFormField) {
        this.solarForm.get("finance")?.disable();
      } else {
        this.solarForm.get("finance")?.enable();
        this.solarForm.get("finance")?.setValue(solar.financial);
      }
    } else if (this.ownValue === "noPlan") {
      this.hideFormField = true;
      this.solarForm.get("roofSlope")?.enable();
      this.solarForm.get("roofShade")?.enable();
      this.solarForm.get("roofSlope")?.setValue(solar[0].roofSlope);
      this.solarForm.get("roofShade")?.setValue(solar[0].roofShade);
      this.solarForm.get("systemSize")?.disable();
      this.solarForm.get("bought")?.disable();
      this.solarForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
      this.isOwnNo = true;
      if (!this.haveFinance && this.hideFormField) {
        this.solarForm.get("finance")?.disable();
      } else {
        this.solarForm.get("finance")?.enable();
        this.solarForm.get("finance")?.setValue(solar[0].financial);
      }
    } else if (this.ownValue === "no") {
      this.isOwnNo = false;
    }
  }

  isDatePurchasedEmpty() {
    if (this.solarForm.get("own")?.value === "yes") {
      if (!this.solarForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  onValueChanges(): void {
    this.solarForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        if (data.own === "yes") {
          const datePurchased = data.datePurchased;
          const bought = this.utils.isDatePurchaseSelected(datePurchased);
          this.solarSettings = [
            {
              own: data.own,
              systemSize: data.systemSize,
              bought: bought,
              datePurchased: bought ? datePurchased : null,
            },
          ];
        } else if (data.own === "yesPlan" || data.own === "noPlan") {
          this.solarSettings = [
            {
              own: data.own,
              roofShade: data.roofShade,
              roofSlope: data.roofSlope,
              financial: data.finance,
            },
          ];
        }
        if (this.solarForm.valid) {
          this.settingService.updateSettingsInfo(
            this.dashboardAPIService.energyChoicesData.solarBattery,
            this.solarSettings,
            "solarBattery",
            "solar"
          );
        } else {
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPIService.energyChoicesData.solarBattery,
            this.solarSettings,
            "solarBattery",
            "solar"
          );
        }
      });
      this.subject.next();
    });
  }

  onSaveSolar() {
    this.settingService.putSettingsInfo(this.getSettingsInfo);
  }

  getSettingsInfo = () => {
    if (this.activeIcon) {
      this.techSave.emit({ own: this.ownValue, tech: "solar" });
    }
  };

  ngOnDestroy() {
    this.alive = false;
  }
}
