import { AuthenticationService } from "../../auth/auth.service";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { DashboardLayoutService } from "../shared/dashboard-layout/dashboard-layout.service";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../app.service";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { take } from "rxjs/operators";
import { SolarVendorModel } from "../../rest-api/rest-api.model";
import { environment } from "../../../environments/environment";
import { customerVendors } from "../../utils/util";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "yt-login",
  templateUrl: "login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public loginError = false;
  LoginForm: FormGroup;
  loadingSpinner: boolean;
  loginPrompt = false;
  vendorName: string;
  vendorYLTN: boolean;
  vendorSUNW: boolean;
  signUpLink: string;
  logMessage: string;
  hidenewPassword: boolean = true;
  vendorInfo: SolarVendorModel;
  public loginFormValue: { email: string; password: string };

  constructor(
    private fb: FormBuilder,
    private appService: AppService,
    private dashboardAPI: DashboardAPIService,
    private restAPI: RestAPIService,
    private layoutService: DashboardLayoutService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.vendorName = this.appService.getVendorName();
    this.vendorYLTN = this.vendorName === "YLTN" || this.vendorName === "ADOB";
    this.vendorSUNW = this.vendorName === "SUNW";
    this.authenticationService.logout();
    this.appService.solarVendorModel
      .pipe(take(1))
      .subscribe((response: SolarVendorModel) => {
        this.vendorInfo = response;
        this.logMessage = this.vendorInfo.tagLine_en;
        const selectedLanguage = sessionStorage.getItem("language");
        this.onLanguageChange(selectedLanguage!);
        const isProd = environment.production;
        if (this.vendorName === "YSLR") {
          this.signUpLink = `${this.vendorInfo.vendorLink}sign-up/?vendor=YSLR`;
        } else if (!isProd && this.vendorName !== "SUNW") {
          this.signUpLink = `${this.vendorInfo.vendorLink}register/?vendor=${this.vendorName}`;
        } else if (this.vendorSUNW && !isProd) {
          this.signUpLink = `${this.vendorInfo.vendorLink}sign-up/?vendor=SUNW`;
        } else {
          this.signUpLink = `${this.vendorInfo.vendorLink}register`;
        }
        this.appService.selectedLanguage$
          .pipe(take(1))
          .subscribe((data: string) => {
            this.onLanguageChange(data);
          });
      });

    this.loginDefault();
    this.LoginForm = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", Validators.required],
    });
    this.LoginForm.valueChanges
      .pipe(take(1))
      .subscribe((data) => (this.loginFormValue = data));
    this.titleService.setTitle(
      `Login | ${this.appService.vendorsList[this.vendorName].title}`
    );
  }

  onLanguageChange(lang: string): void {
    if (lang === "sp") {
      this.logMessage = this.vendorInfo.tagLine_sp;
    } else {
      this.logMessage = this.vendorInfo.tagLine_en;
    }
  }

  loginDefault() {
    this.layoutService.navRouterSubject.next({
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      lifeStyle: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      additionalinfo: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      upgrades: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      energyUsage: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      poweredbyYt: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      topnav: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      topnavlogin: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
    });
  }

  get email() {
    return this.LoginForm.get("email") as FormControl;
  }

  get password() {
    return this.LoginForm.get("password") as FormControl;
  }

  onForgotpassword() {
    this.router.navigateByUrl("/dashboard/forgot-password");

    this.layoutService.navRouterSubject.next({
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      lifeStyle: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      additionalinfo: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      upgrades: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      energyUsage: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      poweredbyYt: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      topnav: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      topnavlogin: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
    });
  }

  onSubmit() {
    const form = this.LoginForm.value;
    this.authenticationService
      .login(form.email, form.password)
      .pipe(take(1))
      .subscribe(
        (data) => {
          if (data) {
            this.dashboardAPI.setUserDetails(
              this.authenticationService.jwtTokenInfo
            );
            this.dashboardAPI.getUserDetails().userName =
              this.authenticationService.jwtTokenInfo.userName;
            let solarVendorCode =
              this.authenticationService.jwtTokenInfo.solarVendorCode;
            const language = sessionStorage.getItem("language");
            if (customerVendors.includes(this.vendorName)) {
              if (this.vendorName === solarVendorCode) {
                this.appService.jwtToken.next(
                  this.authenticationService.jwtTokenInfo
                );
                this.restAPI.homeOwnerEmail =
                  this.authenticationService.jwtTokenInfo.userName;
                if (
                  this.restAPI.solarVendorInformation.languageTranslator
                    .length > 1
                ) {
                  if (language)
                    this.restAPI
                      .updateLanguage(language)
                      .pipe(take(1))
                      .subscribe();
                }
                this.loginSuccess(solarVendorCode);
              } else {
                this.loginError = true;
                this.authenticationService.setJWTToken("");
                this.loginDefault();
              }
            } else {
              this.appService.jwtToken.next(
                this.authenticationService.jwtTokenInfo
              );
              this.appService.currentVendorName.next(
                this.authenticationService.jwtTokenInfo.solarVendorCode
              );
              this.restAPI.homeOwnerEmail =
                this.authenticationService.jwtTokenInfo.userName;
              if (
                this.restAPI.solarVendorInformation.languageTranslator.length >
                1
              ) {
                if (language)
                  this.restAPI
                    .updateLanguage(language)
                    .pipe(take(1))
                    .subscribe();
              }
              this.loginSuccess(solarVendorCode);
            }
          } else {
            this.router.navigate(["/dashboard/login"]);
          }
        },
        (error) => (this.loginError = true)
      );
  }

  loginSuccess(solarVendorCode: string): void {
    this.authenticationService.updateTheme(solarVendorCode);
    this.dashboardAPI.getVendorDetails(solarVendorCode, false);

    this.layoutService.navRouterSubject.next({
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
      },
      lifeStyle: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
      },
      additionalinfo: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
      },
      upgrades: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
      },
      energyUsage: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
      },
      overview: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
      },
      logo1: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      logo2: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      usermenu: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      logout: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      poweredbyYt: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      topnav: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      topnavlogin: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
    });
  }
}
