import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import {
  MarketPlaceDetailsModel,
  LearnMoreRequestModel,
} from "../learn-more.model";
import { LearnMoreService } from "../learn-more.service";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { AppService } from "../../../app.service";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import {
  FinancialModel,
  LearnMoreModel,
  PortableBatteryDetailsModel,
  TechnicalModel,
} from "./portable-battery-details.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-portable-battery-details",
  templateUrl: "portable-battery-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class PortableBatteryDetailsComponent implements AfterContentChecked {
  icon: boolean = false;
  icon1: boolean = false;
  portableBatteryDetails: PortableBatteryDetailsModel;
  financialDetails: FinancialModel;
  technicalDetails: TechnicalModel;
  learnMoreDetails: LearnMoreModel;
  marketPlaceDetails: MarketPlaceDetailsModel;
  showIncentivesStatus: boolean;
  portableBatteryForm: FormGroup;
  loader: boolean = true;
  totalIncentives: number;
  haveResponse = false;
  showIncentiveBreakdown = false;
  displayMarketPlaceBox: boolean;
  marketPlaceLink: string;
  linkId: string;
  adviceCardStatus: boolean;
  tabIndex: number = 0;
  tabLabel: string;
  isLangSpanish: boolean = false;
  FAQTabsList: DropDownModel[];
  showReadMore: boolean = true;
  showReadLess: boolean = false;
  selectedSection: string = "aboutTechnology";
  isTechnicalExpanded: boolean;
  isFAQExpanded: boolean;
  favStatus: boolean;
  favIcon: string;
  showCash: boolean = true;
  timeoutId: any;
  showBlackLoader: boolean;
  @ViewChild("loaderButton", { read: ElementRef }) saveButton: ElementRef;
  @ViewChild("container", { read: ElementRef }) container: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private fb: FormBuilder,
    private dashboardService: DashboardAPIService,
    private learnMoreService: LearnMoreService,
    private appService: AppService,
    private cdRef: ChangeDetectorRef
  ) {
    this.portableBatteryForm = this.fb.group({
      hrs: [
        "4",
        [
          Validators.required,
          Validators.pattern(/^(?:[1-9]|0[1-9]|1[0-8])$/),
          Validators.min(1),
          Validators.max(24),
        ],
      ],
    });
  }

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.dashboardService
      .faqDetails()
      .pipe(take(1))
      .subscribe((list: SectionListModel) => {
        this.FAQTabsList = list.battery.faqList!;
      });
    this.getPortableBatteryViewDetails();
    this.dashboardService
      .getMarketplaceDetails("portableBattery")
      .pipe(take(1))
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails = response;
        this.adviceCardStatus = response.expertAdvice;
        this.displayMarketPlaceBox = this.marketPlaceDetails.displayBox;
        this.marketPlaceLink = this.marketPlaceDetails.buttonLink;
        if (this.marketPlaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketPlaceDetails.linkId;
        }
      });
  }

  getPortableBatteryViewDetails(): void {
    this.learnMoreService
      .learnMoreInfo<PortableBatteryDetailsModel>("portableBattery")
      .pipe(take(1))
      .subscribe((response: PortableBatteryDetailsModel) => {
        this.haveResponse = true;
        this.portableBatteryDetails = response;
        this.financialDetails = this.portableBatteryDetails.financialDetails;
        this.technicalDetails = this.portableBatteryDetails.technicalDetails;
        this.learnMoreDetails = this.portableBatteryDetails.learnMoreDetails;
        this.favStatus = this.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.showIncentivesStatus =
          this.financialDetails.portableBatteryCost.showIncentives;
        this.totalIncentives =
          this.financialDetails.portableBatteryCost.incentives;
        this.batteryFormDetails = this.learnMoreDetails;
        this.showBlackLoader = this.loader = false;
      });
  }

  handleActiveValue(data: string): void {
    if (data === "details") {
      this.isTechnicalExpanded = true;
      this.icon = true;
    } else if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon1 = true;
    }
  }

  expansionPanelClick(): void {
    this.icon = !this.icon;
  }

  faqExpansionClick(): void {
    this.icon1 = !this.icon1;
  }

  onSaveDetails(): void {
    this.haveResponse = false;
    if (
      this.learnMoreService.isFormEditted(
        this.batteryData.portableBattery!,
        this.learnMoreDetails
      )
    ) {
      this.saveButton.nativeElement.innerHTML =
        'Saving... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
      this.dashboardService.dashboardDetailsAnalytics(
        "save",
        "portableBattery"
      );
      this.learnMoreService
        .updateLearnMoreInfo("portableBattery", this.batteryData)
        .pipe(take(1))
        .subscribe(() => {
          this.timeoutId = setTimeout(() => {
            this.saveButton.nativeElement.innerHTML = "SAVE DETAILS";
            this.getPortableBatteryViewDetails();
          }, 800);
        });
    }
  }

  get batteryData(): LearnMoreRequestModel {
    return {
      portableBattery: {
        hrs: this.portableBatteryForm.get("hrs")?.value,
      },
    };
  }

  get isHoursOutOfRange(): boolean {
    return (
      this.portableBatteryForm.controls["hrs"].hasError("pattern") ||
      this.portableBatteryForm.controls["hrs"].hasError("min") ||
      this.portableBatteryForm.controls["hrs"].hasError("max")
    );
  }

  get isHoursRequired(): boolean {
    return this.portableBatteryForm.controls["hrs"].hasError("required");
  }

  set batteryFormDetails(details: LearnMoreModel) {
    this.portableBatteryForm.get("hrs")?.setValue(details.hrs);
  }

  handleFAQClick(): void {
    this.dashboardService.dashboardDetailsAnalytics(
      "moreInfo",
      "portableBattery"
    );
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/portable-battery-faq-es/",
      "_blank"
    );
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  onReadContent(value: string) {
    if (value === "readMore") {
      this.showReadMore = false;
      this.showReadLess = true;
    } else if (value === "readLess") {
      this.showReadMore = true;
      this.showReadLess = false;
    }
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo("portableBattery", ownPlan)
      .pipe(take(1))
      .subscribe(() => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getPortableBatteryViewDetails();

        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardService.techMessage(
          "portableBattery",
          action
        );
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
