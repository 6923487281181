import { AfterViewInit, Component, Input } from "@angular/core";
import { LogoModel } from "../../rest-api/rest-api.model";
import { AppService } from "../../app.service";

@Component({
  selector: "yt-redirect-loader",
  templateUrl: "redirect-loader.component.html",
  styleUrls: ["./redirect-loader.component.scss"],
})
export class RedirectLoaderComponent implements AfterViewInit {
  defaultTextStatus: boolean = true;
  logo: LogoModel;
  timeoutId: any;
  @Input() vendorCode: string;

  constructor(private appService: AppService) {}

  ngOnInit() {
    this.appService.dashboardlogoModel$.subscribe((response) => {
      this.logo = response;
    });
  }

  ngAfterViewInit() {
    this.timeoutId =  setTimeout(() => {
      this.defaultTextStatus = false;
    }, 3000);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
