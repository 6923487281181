import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { MarketPlaceDetailsModel } from "../learn-more.model";
import { LearnMoreService } from "../learn-more.service";
import {
  EvergreenDetailsModel,
  EvergreenFinancialDetailsModel,
  EvergreenLearnMoreModel,
} from "./evergreen.model";
import { Title } from "@angular/platform-browser";
import { AppService } from "../../../app.service";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-evergreen-details",
  templateUrl: "./evergreen-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class EvergreenDetailsComponent implements OnInit, AfterContentChecked {
  loader: boolean = true;
  icon1 = false;
  displayMarketPlaceBox: boolean = false;
  linkId: string;
  header: string;
  readMore: boolean = true;
  readLess: boolean = false;
  financialDetails: EvergreenFinancialDetailsModel;
  everGreenDetails: EvergreenDetailsModel;
  marketPlaceDetails: MarketPlaceDetailsModel;
  adviceCardStatus: boolean;
  isLangSpanish: boolean;
  tabIndex: number = 0;
  tabLabel: string;
  FAQTabsList: DropDownModel[];
  isFAQExpanded: boolean;
  selectedSection: string = "aboutTechnology";
  favStatus: boolean;
  favIcon: string;
  learnMoreDetails: EvergreenLearnMoreModel;
  upfrontCost: number;
  showBlackLoader: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private learnMoreService: LearnMoreService,
    private dashboardAPI: DashboardAPIService,
    private titleService: Title,
    private appService: AppService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.getEverGreenDetails();
    this.marketplaceAPI();
  }

  handleActiveValue(data: string): void {
    if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon1 = true;
    }
  }

  basicInfoPanelClick(): void {
    this.icon1 = !this.icon1;
  }

  marketplaceAPI(): void {
    this.dashboardAPI
      .getMarketplaceDetails("everGreen")
      .pipe(take(1))
      .subscribe((data) => {
        this.marketPlaceDetails = data;
        this.adviceCardStatus = data.expertAdvice;
        this.displayMarketPlaceBox = this.marketPlaceDetails.displayBox;
        if (this.marketPlaceDetails.linkId) {
          this.linkId = "";
        } else {
          this.linkId = this.marketPlaceDetails.buttonLink;
        }
      });
  }

  getEverGreenDetails(): void {
    this.learnMoreService
      .learnMoreInfo<EvergreenDetailsModel>("everGreen")
      .pipe(take(1))
      .subscribe((data: EvergreenDetailsModel) => {
        this.everGreenDetails = data;
        this.financialDetails = this.everGreenDetails.financialDetails;
        this.dashboardAPI
          .faqDetails()
          .pipe(take(1))
          .subscribe((list: SectionListModel) => {
            this.FAQTabsList = list.everGreen.faqList!;
          });
        this.upfrontCost = this.financialDetails.costDetails.upfrontCost;
        this.header = this.everGreenDetails.learnMoreDetails.name;
        this.favStatus = this.everGreenDetails.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.titleService.setTitle(
          `${this.header.match(/\(([^)]+)\)/)![1]} | ${
            this.appService.vendorsList[
              this.dashboardAPI.getUserDetails().solarVendorCode
            ].title
          }`
        );
        this.showBlackLoader = this.loader = false;
      });
  }

  onReadContent(value: string) {
    if (value === "readMore") {
      this.readMore = false;
      this.readLess = true;
    } else if (value === "readLess") {
      this.readLess = false;
      this.readMore = true;
    }
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  handleFAQClick(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("moreInfo", "everGreen");
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/gpp-faq-es/",
      "_blank"
    );
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo("everGreen", ownPlan)
      .pipe(take(1))
      .subscribe(() => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getEverGreenDetails();

        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardAPI.techMessage("everGreen", action);
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }
}
