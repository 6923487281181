<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">Heat Pump Heating & Cooling</div>

      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isSavings]="true"
        [isDescription]="false"
        [isSettings]="true"
        [isDetails]="true"
        [isAssumptions]="!!assumptionDetails"
        [isFaq]="true"
        [techParameter]="'ashp'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>

      <div
        class="yt-text"
        [innerHTML]="'homeOwenerDashboard.learnMore.hpsc.paragraph' | translate"
      ></div>

      <div *ngIf="!isLangSpanish" id="aboutTechnology">
        <yt-hpsc-FAQ [showAboutTech]="true"></yt-hpsc-FAQ>
      </div>

      <div class="faq_mobile">
        <button (click)="onFavorite()" class="favorite_btn" mat-raised-button>
          <mat-icon
            class="favorite_icon"
            [ngClass]="{ favorite_icon_color: favStatus }"
            >{{ favIcon }}</mat-icon
          >FAVORITE
        </button>
        <button
          *ngIf="isLangSpanish"
          class="faq_button"
          mat-raised-button
          (click)="handleFAQClick()"
        >
          MORE INFO
        </button>
      </div>

      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="assets/lifestyle/ashp.svg"
              alt="technology icon"
              [ngStyle]="{ height: !displayMarketPlaceBox ? '160px' : '250px' }"
            />
          </div>

          <button
            (click)="onFavorite()"
            class="favorite_btn mt_20"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button mt_20"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>

        <div class="width-box" id="cost" *ngIf="financialDetails">
          <yt-cost-rendering
            [financialDetails]="financialDetails.hpscCost"
            [paymentTypeLabel]="'Net cost'"
            [showCash]="showCash"
            [show_Upfront_price]="true"
            [showIncentivesStatus]="showIncentivesStatus"
            [lease]="false"
            [showIncentiveBreakdown]="showIncentiveBreakdown"
            [techName]="'ashp'"
            [heading]="'Incentives Breakdown'"
            [netCost]="'Total'"
            (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
            [upfrontCostTooltipText]="
              'Includes equipment, labor, permits, etc. Excludes charges for main service panel upgrade or duct repair/replacement (which are sometimes needed.)'
            "
            [netCostTooltipText]="'Net cost = Upfront cost - Incentives'"
          ></yt-cost-rendering>

          <yt-market-place
            [tech_name]="'ashp'"
            [linkId]="linkId"
            [marketPlaceDetails]="marketPlaceDetails"
            *ngIf="marketPlaceDetails && displayMarketPlaceBox"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'ashp'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div class="bottom_section w-100">
        <div class="left_section">
          <div class="highChart_card highChart_card_mobile">
            <div class="details_header pb_14">Savings</div>
            <div class="w-100 toggle_buttons">
              <mat-button-toggle-group
                [(value)]="selected"
                (change)="updateChart($event.value)"
              >
                <mat-button-toggle
                  *ngFor="let time of timeSegmentList"
                  [value]="time.value"
                >
                  <span class="toggle_label">{{
                    time.viewValue?.toUpperCase()
                  }}</span></mat-button-toggle
                >
              </mat-button-toggle-group>
            </div>
            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="Using a natural gas space heating is cheaper than using an air-source heat pump in some situations. However, switching from gas reduces carbon emissions."
              >
                energy savings
              </span>
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span
                class="savings_value"
                [ngClass]="{ value_color: hpsc_Savings < 0 }"
                >{{ hpsc_Savings | currency : "$" : "symbol" : "1.0-0" }}</span
              >
              <span *ngIf="displayPayback && !showTooltipZero">
                with a
                <span
                  class="tooltip_text_border pointer"
                  matTooltip="This is a simple payback based on the net cost, annual energy cost savings, value based on remaining life of your current heating system and the cost of additional solar panels (if you added solar in your selections) to power your air source heat pump."
                  >payback</span
                >
                {{ payBackStatus }}
              </span>
            </div>
            <div class="w-100 chart_section">
              <div class="mobile_chart_text">
                To view and compare graphs of energy costs, please login to your
                dashboard using a desktop.
                <!-- Please log into your dashboard on a desktop to view and compare <span class="fw6">graphs</span> of
                energy costs. -->
              </div>
            </div>
          </div>

          <div class="details_card" [formGroup]="ashpForm" id="settings">
            <div class="details_header">PERSONALIZE</div>
            <div class="form_label">Home‘s living space, sq.ft</div>
            <div class="formfield_padding">
              <mat-form-field
                floatLabel="auto"
                hideRequiredMarker="true"
                appearance="outline"
              >
                <input
                  matInput
                  type="text"
                  placeholder="Enter 200-5000"
                  autocomplete="off"
                  maxlength="5"
                  formControlName="sqfoot"
                />
                <mat-error *ngIf="isSqFootOutOfRange">
                  Please enter 200-5000
                </mat-error>
                <mat-error *ngIf="isSqFootRequired"> Required! </mat-error>
              </mat-form-field>
            </div>
            <div class="form_label">Do you have ducts?</div>
            <div class="formfield_padding">
              <mat-form-field
                floatLabel="auto"
                hideRequiredMarker="true"
                appearance="outline"
              >
                <mat-select
                  placeholder="Current heating system type"
                  formControlName="ducts"
                >
                  <mat-option *ngFor="let duct of ducts" [value]="duct.value">
                    {{ duct.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="isDuctsRequired"> Required! </mat-error>
              </mat-form-field>
            </div>
            <div class="sub_header">
              Current heating system
              <mat-icon
                class="tooltip_icon pointer"
                matTooltip="This information relates to the heating system currently in your home."
              >
                help_outline
              </mat-icon>
            </div>
            <div class="form_label">Type</div>
            <div class="formfield_padding">
              <mat-form-field floatLabel="auto" appearance="outline">
                <mat-select
                  placeholder="Current heating system type"
                  formControlName="hpscType"
                  (selectionChange)="onTypeChanges()"
                >
                  <mat-option
                    *ngFor="let heater of hpscType"
                    [value]="heater.value"
                  >
                    {{ heater.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="isTypeRequired"> Required! </mat-error>
              </mat-form-field>
            </div>
            <div class="form_label">Age</div>
            <div class="formfield_padding">
              <mat-form-field floatLabel="auto" appearance="outline">
                <mat-select formControlName="age" placeholder="Select Age">
                  <mat-option
                    *ngFor="let range of ageList"
                    [value]="range.value"
                    >{{ range.viewValue }}</mat-option
                  >
                </mat-select>
                <mat-error *ngIf="isAgeRequired"> Required! </mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="yt_width285">
              <div class="yt_width125">
                <div class="pt_16">Type</div>
                <div class="formfield_padding">
                  <mat-form-field floatLabel="auto" appearance="outline">
                    <mat-select
                      placeholder="Current heating system type"
                      formControlName="hpscType"
                      (selectionChange)="onTypeChanges()"
                    >
                      <mat-option
                        *ngFor="let heater of hpscType"
                        [value]="heater.value"
                      >
                        {{ heater.viewValue }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="isTypeRequired"> Required! </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="yt_width125">
                <div class="pt_16">Age</div>
                <div class="formfield_padding">
                  <mat-form-field floatLabel="auto" appearance="outline">
                    <mat-select formControlName="age" placeholder="Select Age">
                      <mat-option
                        *ngFor="let range of ageList"
                        [value]="range.value"
                        >{{ range.viewValue }}</mat-option
                      >
                    </mat-select>
                    <mat-error *ngIf="isAgeRequired"> Required! </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div> -->
            <div
              class="save_button yt_pt2-75"
              [ngClass]="{
                '': !displayMarketPlaceBox,
                'ghost-button': displayMarketPlaceBox,
                disable_button: ashpForm.invalid
              }"
            >
              <button
                mat-raised-button
                (click)="onSaveDetails()"
                #loaderButton
                [disabled]="ashpForm.invalid"
                color="accent"
              >
                SAVE DETAILS
              </button>
            </div>
          </div>
          <yt-assumption-card
            [assumptionDetails]="assumptionDetails"
          ></yt-assumption-card>
        </div>

        <div class="right_section">
          <div class="highChart_card" id="savings">
            <div class="details_header pb_14">Savings</div>

            <app-Time-Rendering
              [selected]="selected"
              (selectionChange)="updateChart($event)"
            >
            </app-Time-Rendering>

            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="Using natural gas space heating is cheaper than using an air-source heat pump in some situations. However, switching from gas reduces carbon emissions."
              >
                energy savings
              </span>
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span
                class="savings_value"
                [ngClass]="{ value_color: hpsc_Savings < 0 }"
                >{{ hpsc_Savings | currency : "$" : "symbol" : "1.0-0" }}</span
              >
              <span *ngIf="displayPayback && !showTooltipZero">
                with a
                <span
                  class="tooltip_text_border pointer"
                  matTooltip="This is a simple payback based on the net cost, annual energy cost savings, value based on remaining life of your current heating system and the cost of additional solar panels (if you added solar in your selections) to power your air source heat pump."
                  >payback</span
                >
                {{ payBackStatus }}
              </span>
            </div>
            <div class="formula_text">
              Energy savings &nbsp; = &nbsp; Energy cost with current system -
              Energy cost with air-source heat pump
            </div>
            <div class="w-100 chart_section">
              <div class="hide_chart">
                <div class="chart" #container></div>
              </div>
            </div>
            <div class="w-100 chart_description_text">{{ textUnderChart }}</div>
          </div>
          <div class="summary_card" id="details">
            <mat-expansion-panel
              (closed)="isTechnicalExpanded = false"
              [expanded]="isTechnicalExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="expansionPanelClick()">
                <mat-panel-title class="flex items-center">
                  Technical Details &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <div class="left_side_values">
                  <div class="label_heading w-100">
                    Recommended Air-Source Heat Pump
                  </div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <div>Type</div>
                      <div *ngIf="hpscViewDetails">
                        {{ technicalDetails.recommendedHpsc.hpscType }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Capacity</div>
                      <div *ngIf="hpscViewDetails">
                        {{ technicalDetails.recommendedHpsc.hpscCapacity }}
                        btu/hr
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Manufacturer</div>
                      <div *ngIf="hpscViewDetails">
                        {{ technicalDetails.recommendedHpsc.manufacturer }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Warranty</div>
                      <div *ngIf="hpscViewDetails">
                        {{ technicalDetails.recommendedHpsc.warranty }}
                        years
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right_side_values">
                  <div class="label_heading w-100">
                    Current heating / cooling
                  </div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <div>Heating system</div>
                      <div *ngIf="hpscViewDetails">
                        {{ technicalDetails.currentHpsc.heatingSystem }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Ducted system?</div>
                      <div *ngIf="hpscViewDetails">
                        {{ technicalDetails.currentHpsc.ductedSystem }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Age</div>
                      <div *ngIf="hpscViewDetails">
                        {{ technicalDetails.currentHpsc.hpscAge }}
                        years
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Expected remaining life</div>
                      <div *ngIf="hpscViewDetails">
                        {{ technicalDetails.currentHpsc.remainingLife }}
                        years
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

          <div class="faq_card w-100" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="faqExpansionClick()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon1 ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-hpsc-FAQ
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-hpsc-FAQ>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div
        class="link_text back_to_top"
        (click)="scrollToTop()"
        *ngIf="!isLangSpanish"
      >
        <mat-icon class="pointer mat_color">keyboard_arrow_up</mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<yt-breakdown-popup
  *ngIf="showIncentiveBreakdown && haveResponse"
  (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
  [heading]="'Incentives Breakdown'"
  [netCost]="'Total'"
  [techName]="'ashp'"
>
</yt-breakdown-popup>

<!-- loader -->
<yt-loader
  [blackLoader]="showBlackLoader"
  *ngIf="loader || showBlackLoader"
  top="0"
  [relative]="true"
></yt-loader>
