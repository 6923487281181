import {
  Component,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as Highcharts from "highcharts";
import { MatDialog } from "@angular/material/dialog";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";

import { JWTTokenInfoModel } from "../../../auth/auth.model";
import { SolarVendorDetailsModel } from "../model/solarvendordetails.model";
import {
  CarbonSavingsModel,
  CategorySavingsModel,
  ChartDataInputsModel,
  HelpMeChooseModel,
  RecommendationTechModel,
  SavingsTypeModel,
  SolarDashboardUserConsModel,
  TechInfoModel,
} from "../model/dashboard.model";
import { TourGuideModel } from "../../shared/tour-guide/tour-guide.model";
import {
  CardDetailsModel,
  SustainableMiniCardModel,
} from "../../shared/mini-cards/mini-cards.model";
import { LearnMoreService } from "../../learn-more/learn-more.service";
import { errorCheckingDetailsModel } from "../model/dashboard.model";
import { TechCardInitial } from "../../shared/mini-cards/tech-cards/tech-card.init";
import {
  CategoryColorsModel,
  TechCategoryModel,
  TechDetailsModel,
} from "../model/dashboard.init.model";
import {
  TimeFrameEnum,
  SavingsTypeEnum,
} from "../../learn-more/learn-more.enum";
import { UserDataModel } from "../model/userdata.model";
import { CleanChoicesSections, CleanChoicesTabs } from "../../../utils/enum";
import { Utils } from "../../../utils/util";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { OverviewService } from "../overview.service";

@Component({
  selector: "yt-dashboard-v2",
  templateUrl: "dashboard-v2.component.html",
  styleUrls: ["./dashboard-v2.component.scss"],
})
export class DashboardV2Component implements OnDestroy {
  savingsPopup: boolean;
  showSavingsPopup: boolean;
  showMaxPanelError = false;
  showMinPanelError = false;
  showDashboard: boolean = true;
  twentyYear: boolean;
  oneYear: boolean;
  oneMonth: boolean;
  tenYear: boolean;
  showSolarTabContent: boolean = true;
  showErrorMessage: boolean;
  solarDashboardUserConsModel: SolarDashboardUserConsModel;
  solarVendorDetailsModel: SolarVendorDetailsModel;
  selected: SavingsTypeEnum;
  timeSelected: TimeFrameEnum;
  showErrorMessageTab1: boolean = false;
  showErrorMessageTab2: boolean = false;
  solarErrorMessage: string;
  totalSavings: number;
  isSavingsZero: boolean;
  isCarbonZero: boolean;
  totalCarbonSavings: number;
  totalTrees: number;
  jwtTokenInfo: JWTTokenInfoModel;
  baseURL: string;
  SolarVendorDetailsURL: string;
  errorPhrase1: string;
  errorPhrase2: string;
  isInstaller: boolean = false;
  chartOptions1: Highcharts.Options = {};
  techMessage: string;
  techMessageHeader: string;
  timer;
  buildingType: string;
  showWhatChoices: boolean;
  buildingTypeCondo: boolean;
  buildingTypeRenter: boolean;
  menuID: string;
  showAlert: boolean;
  sliderAlert: boolean;
  revokedAlert: boolean;
  partialAlert: boolean;
  loader: boolean = true;
  isCarbon: boolean;
  noSavingsImage: string;
  referFriendPopup: boolean;
  vendorCode: string;
  showGreetAlert: boolean;
  techInfo: TechInfoModel;
  tourGuideObject: TourGuideModel[];
  showTourGuide: boolean;
  showTechAlert = false;
  techAlertColor: string;
  categoryColorList: CategoryColorsModel;
  renterCondoStatus: boolean;
  showErrorAlert = true;
  errorMsg: string;
  sustainableCardsList: SustainableMiniCardModel;
  techCardsList: TechCategoryModel;
  selectionList: CardDetailsModel[] = [];
  timeFrameOptions: DropDownModel[];
  errorCheckingDetailsList: errorCheckingDetailsModel;
  errorTechnologiesList: string[] = [];
  showErrorAlertStatus = true;
  showEmailAlert: boolean;
  showRedirectLoader: boolean = false;
  alertCounter: number = 0;
  buildPlanText: string;
  solutionType: string;
  totalIncentives: number;
  categorySavings: CategorySavingsModel;
  categoriesList: string[];
  timeValue: string;
  techSavings: SavingsTypeModel;
  alreadyHaveTechs: TechDetailsModel[] = [];
  helpMeChoose: HelpMeChooseModel;
  recommendationList: RecommendationTechModel;
  expertAdviceCardStatus: boolean;
  carbonSavings: CarbonSavingsModel;
  evName1: string;
  evName2: string;
  showHomeSettings: boolean;
  isSimpleLowCost: boolean = true;
  activeTab: string;
  section: string;
  activeTech: string;
  isApproach2RC: boolean;
  progressBarValue: number;
  isNewUser: boolean;
  showBlackLoader: boolean = false;
  isSavingsAPIDispatched: boolean = false;
  timeoutId: any;
  @ViewChild("container", { read: ElementRef }) container: ElementRef;
  @ViewChild("errorCheckingAlert", { read: ElementRef })
  errorMessageRef: ElementRef;
  @ViewChild("errorAlertOverlay", { read: ElementRef }) modal: ElementRef;

  constructor(
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dashboardAPI: DashboardAPIService,
    private overviewService: OverviewService,
    private techCards: TechCardInitial,
    private learnMoreService: LearnMoreService,
    private utils: Utils
  ) {
    this.timeFrameOptions = this.learnMoreService.timeSegmentList;
    this.categoryColorList = this.techCards.categoryColorList;
  }
  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key == "Escape") {
      this.closeWhatChoices();
      this.closeSavingsDialog();
    }
  }

  ngOnInit() {
    this.showErrorAlert = this.dashboardAPI.showErrorAlertStatus;
    this.showEmailAlert = this.dashboardAPI.showEmailAlertStatus;
    this.showAlert =
      this.partialAlert =
      this.sliderAlert =
      this.revokedAlert =
        this.dashboardAPI.showPgeAlertStatus;
    this.vendorCode = this.dashboardAPI.getUserDetails().solarVendorCode;
    if (this.dashboardAPI.timeFrame === undefined) {
      this.dashboardAPI.setTimeFrame(TimeFrameEnum.TenYear);
    }
    if (this.dashboardAPI.getDisplayFrame() === undefined) {
      this.dashboardAPI.setDisplayFrame(SavingsTypeEnum.DOLLAR);
    }
    this.timeSelected = this.dashboardAPI.timeFrame;
    this.selected = this.dashboardAPI.getDisplayFrame();
    this.getVendorDetails();
    this.twentyYear = true;
    this.onValueChange(this.timeSelected, this.selected);
    this.showTechCardLoader();
    this.tourGuideObject = [
      {
        title: "Welcome to your Dashboard",
        bodyText:
          "Let us walk you through a quick platform tour so you can start building your plan to save money and cut emissions.",
        buttonText: "Next",
        selector: "",
      },
      {
        title: "Browse Recommendations",
        bodyText: `See choices by category and simply
        click <mat-icon class="material-icons favorite_icon">favorite_border</mat-icon> to customize and add to 'Favorites'.`,
        buttonText: "Next",
        selector: ".tech_card",
        childSelector: ".favorite_icon",
      },
      {
        title: "Learn more",
        bodyText:
          "Click here to deep dive into any specific choice to understand Costs, Incentives, etc.",
        buttonText: "Next",
        selector: ".tech_card",
        childSelector: ".lm_btn",
      },
      {
        title: `<mat-icon class="material-icons already_icon">check_circle_outline</mat-icon> Already Have`,
        bodyText: `Click the tick mark if already purchased/installed and we will move it to the 'Already Have' section`,
        buttonText: "Next",
        selector: ".tech_card",
        childSelector: ".check_circle",
      },
      {
        title: "Complete Setup",
        bodyText:
          "Click to review and customize energy option settings to get the most accurate results.",
        buttonText: "Do it later",
        selector: ".settings_box",
      },
    ];
  }

  showTechCardLoader(): void {
    this.dashboardAPI.alreadyHaveLoading.subscribe((response) => {
      this.showBlackLoader = !!response;
    });
    this.dashboardAPI.favoriteLoading.subscribe((response) => {
      this.showBlackLoader = !!response;
    });
    this.dashboardAPI.removeAlreadyHave.subscribe((response) => {
      this.showBlackLoader = !!response;
    });
  }

  getVendorDetails() {
    const response = this.dashboardAPI.vendorDetails;
    if (response && Object.keys(response).length) {
      this.isApproach2RC = this.dashboardAPI.isApproach2RC;
      this.selected = response.dashboard.overViewTab;
      this.categoriesList = response.categoriesList;
      this.isCarbon = !!(this.selected === SavingsTypeEnum.CARBON);
      this.isNewUser = response.loggedInCount === 1;
      this.showEmailAlert =
        this.isNewUser && this.dashboardAPI.showEmailAlertStatus;
      if (this.isNewUser) {
        this.showGreetAlert = false;
      }
      this.expertAdviceCardStatus = response.dashboard.expertAdvice;
      this.solutionType = response.solutionType;
      this.isInstaller = this.solutionType === "vendor";
      this.solarVendorDetailsModel = response;
      this.dashboardAPI.rewardBonus = response.rewardBonus;
    }
  }

  updateChartOptions(inputData: ChartDataInputsModel[]) {
    let filteredInputData: ChartDataInputsModel[] = [],
      i = 0,
      prefix,
      suffix;
    prefix = this.isCarbon ? "" : "$";
    suffix = this.isCarbon ? " lbs" : "";
    for (let entry of inputData) {
      if (entry.y != 0) {
        filteredInputData[i] = entry;
        i++;
      }
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });
    this.chartOptions1 = {
      credits: {
        enabled: false,
      },
      chart: {
        height: 270,
        type: "pie",
      },
      title: {
        text: undefined,
      },
      tooltip: {
        enabled: true,
        formatter: function (this) {
          return `${prefix} ${this.point
            .y!.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${suffix}`;
        },
        style: {
          fontSize: "14px",
          fontFamily: "Open sans",
        },
      },
      plotOptions: {
        pie: {
          size: "150",
          innerSize: "65px",
          dataLabels: {
            style: {
              fontSize: "14px",
              fontWeight: "400",
            },
          },
        },
        series: {
          animation: {
            duration: 500,
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 350,
            },
            chartOptions: {
              plotOptions: {
                pie: {
                  size: "130",
                  dataLabels: {
                    enabled: true,
                    format: "{point.name}",
                    distance: 20,
                    connectorColor: "black",
                    connectorWidth: 0.5,
                  },
                },
              },
            },
          },
        ],
      },
      series: [
        {
          name: undefined,
          data: filteredInputData,
          type: "pie",
        },
      ],
    };
    Highcharts.chart(this.container.nativeElement, this.chartOptions1);
  }

  onValueChange(
    time: TimeFrameEnum,
    savings: SavingsTypeEnum,
    onClickStatus?: boolean
  ) {
    this.timeSelected = time;
    this.dashboardAPI.setTimeFrame(this.timeSelected);
    this.dashboardAPI.setDisplayFrame(savings);
    if (onClickStatus) {
      this.dashboardAPI.dashboardDetailsAnalytics(savings);
    }
    this.selected = savings;
    const timeMapper = {};
    Object.values(TimeFrameEnum).forEach((timeSpan) => {
      timeMapper[timeSpan] = () => this.setTimeSpan(timeSpan);
    });
    timeMapper[this.timeSelected]();
    let param = window.history.state;
    let count = sessionStorage.getItem("count");
    if (!count) {
      sessionStorage.setItem("count", "redirected");
      this.showRedirectLoader = param?.data === "autoLogin";
      this.loader = !this.showRedirectLoader;
    } else {
      this.showRedirectLoader = false;
      this.loader = true;
    }
    const updateSettingsAPI: boolean = param?.fromService || false;
    this.updateRecommendationTab();
    this.getDashboardData(
      this.timeSelected,
      savings,
      null,
      null,
      !updateSettingsAPI
    );
  }

  updateSavings(): void {
    this.getDashboardData(this.timeSelected, this.selected, null, null, false);
    this.getUserSettingInfo(true);
  }

  getDashboardData(
    val: TimeFrameEnum,
    val1: SavingsTypeEnum,
    addRemoveEV1: string | null,
    addRemoveEV2: string | null,
    callSettings: boolean
  ) {
    this.isSavingsAPIDispatched = true;
    this.techCardsList = JSON.parse(JSON.stringify(this.techCards.techList));
    this.isCarbon = val1 === SavingsTypeEnum.CARBON;
    let query = "";
    if (addRemoveEV1 != null) {
      query = query + addRemoveEV1;
    }
    if (addRemoveEV2 != null) {
      query = query + addRemoveEV2;
    }
    if (!query) {
      this.getUserSettingInfo(callSettings);
    }

    // this.dashboardAPI.getSavingsDetails(val, query).subscribe(
    //   (response) => {
    //     this.dashboardAPI.alreadyHaveLoading.next("");
    //     this.dashboardAPI.favoriteLoading.next("");
    //     this.dashboardAPI.removeAlreadyHave.next("");
    //     this.isSavingsAPIDispatched = false;
    //     const upfrontCost = "Upfront cost";
    //     const defaultNetCostLabel =
    //       this.dashboardAPI.getPaymentTypeLabel("cash");
    //     // HIGH CHART LABELS
    //     const solarBatteryLabel = "Solar & Battery";
    //     const homeAppliancesLabel = "Appliances";
    //     const transportationLabel = "Transportation";
    //     const simpleLowCostLabel = "Simple/Low Cost";
    //     if (query) {
    //       this.getUserSettingInfo(callSettings);
    //     }
    //     this.solarDashboardUserConsModel = response;
    //     this.progressBarValue = response.progressBar;
    //     this.showErrorMessageTab2 = response.errorMsg_2;
    //     this.categorySavings = response.categorySavings;
    //     this.totalIncentives = response.totalIncentives;
    //     this.carbonSavings = response.carbonSavings;
    //     this.totalTrees = this.categorySavings.totalTreesPlanted;
    //     const {
    //       ledCflBulbs,
    //       showerHeadsAerators,
    //       smartPowerStrips,
    //       smartThermostat,
    //       weatherStripping,
    //       everGreen,
    //     } = response.simpleLowCost;
    //     const simpleLowCost: SimpleCostCategoryModel = {
    //       ...this.techCardsList.simpleLowCost!,
    //       everGreen: everGreen && {
    //         ...this.techCardsList.simpleLowCost.everGreen,
    //         showTech: true,
    //         techName: everGreen.name,
    //         priceAfterIncentives: everGreen.upfrontCost,
    //         link: everGreen.techDetails.link,
    //         netCostLabel: upfrontCost,
    //         own: everGreen.techDetails.own,
    //         dollarSavings: everGreen.techDetails.dollarSavings,
    //         carbonSavings: everGreen.techDetails.carbonSavings,
    //         leaves: everGreen.techDetails.leaves,
    //         isNegative: everGreen.techDetails.dollarSavings < 0,
    //       },
    //       ledCflBulbs: ledCflBulbs && {
    //         ...this.techCardsList.simpleLowCost?.ledCflBulbs!,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: ledCflBulbs.own,
    //         priceAfterIncentives: ledCflBulbs.priceAfterIncentives,
    //         link: ledCflBulbs.link,
    //         rebateStatus: ledCflBulbs.rebateStatus,
    //         shopNow: ledCflBulbs.shopNow,
    //         dollarSavings: ledCflBulbs.dollarSavings,
    //         carbonSavings: ledCflBulbs.carbonSavings,
    //         leaves: ledCflBulbs.leaves,
    //         isNegative: ledCflBulbs.dollarSavings < 0,
    //       },
    //       showerHeadsAerators: showerHeadsAerators && {
    //         ...this.techCardsList.simpleLowCost?.showerHeadsAerators!,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: showerHeadsAerators.own,
    //         priceAfterIncentives: showerHeadsAerators.priceAfterIncentives,
    //         link: showerHeadsAerators.link,
    //         rebateStatus: showerHeadsAerators.rebateStatus,
    //         shopNow: showerHeadsAerators.shopNow,
    //         dollarSavings: showerHeadsAerators.dollarSavings,
    //         carbonSavings: showerHeadsAerators.carbonSavings,
    //         leaves: showerHeadsAerators.leaves,
    //         isNegative: showerHeadsAerators.dollarSavings < 0,
    //       },
    //       smartPowerStrips: smartPowerStrips && {
    //         ...this.techCardsList.simpleLowCost?.smartPowerStrips!,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: smartPowerStrips.own,
    //         priceAfterIncentives: smartPowerStrips.priceAfterIncentives,
    //         link: smartPowerStrips.link,
    //         rebateStatus: smartPowerStrips.rebateStatus,
    //         shopNow: smartPowerStrips.shopNow,
    //         dollarSavings: smartPowerStrips.dollarSavings,
    //         carbonSavings: smartPowerStrips.carbonSavings,
    //         leaves: smartPowerStrips.leaves,
    //         isNegative: smartPowerStrips.dollarSavings < 0,
    //       },
    //       smartThermostat: smartThermostat && {
    //         ...this.techCardsList.simpleLowCost?.smartThermostat!,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: smartThermostat.own,
    //         priceAfterIncentives: smartThermostat.priceAfterIncentives,
    //         link: smartThermostat.link,
    //         rebateStatus: smartThermostat.rebateStatus,
    //         shopNow: smartThermostat.shopNow,
    //         dollarSavings: smartThermostat.dollarSavings,
    //         carbonSavings: smartThermostat.carbonSavings,
    //         leaves: smartThermostat.leaves,
    //         isNegative: smartThermostat.dollarSavings < 0,
    //       },
    //       weatherStripping: weatherStripping && {
    //         ...this.techCardsList.simpleLowCost?.weatherStripping!,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: weatherStripping.own,
    //         priceAfterIncentives: weatherStripping.priceAfterIncentives,
    //         link: weatherStripping.link,
    //         rebateStatus: weatherStripping.rebateStatus,
    //         shopNow: weatherStripping.shopNow,
    //         dollarSavings: weatherStripping.dollarSavings,
    //         carbonSavings: weatherStripping.carbonSavings,
    //         leaves: weatherStripping.leaves,
    //         isNegative: weatherStripping.dollarSavings < 0,
    //       },
    //     };

    //     const { ev, eBike, evChargers } = response.transportation;
    //     const { ev1, ev2 } = ev;
    //     this.evName1 = ev1?.makeAndModel;
    //     this.evName2 = ev2
    //       ? ev2.makeAndModel
    //       : this.techCardsList.transportation?.ev2?.techName!;
    //     const ev1PriceAfterIncentives =
    //       ev1?.financial === "cash"
    //         ? ev1?.techDetails.priceAfterIncentives
    //         : ev1?.monthlyPayment;
    //     const ev2PriceAfterIncentives =
    //       ev2?.financial === "cash"
    //         ? ev2?.techDetails.priceAfterIncentives
    //         : ev2?.monthlyPayment;

    //     const transportation: TransportationCategoryModel = {
    //       ...this.techCardsList.transportation!,
    //       ev1: ev1 && {
    //         ...this.techCardsList.transportation?.ev1!,
    //         showTech: true,
    //         priceAfterIncentives: ev1PriceAfterIncentives,
    //         techName: this.evName1,
    //         netCostLabel: this.dashboardAPI.getPaymentTypeLabel(ev1.financial),
    //         own: ev1.techDetails.own,
    //         financial: ev1.financial,
    //         monthlyPayment: ev1.monthlyPayment,
    //         shopNow: ev1.techDetails.shopNow,
    //         link: ev1.techDetails.link,
    //         rebateStatus: ev1.techDetails.rebateStatus,
    //         dollarSavings: ev1.techDetails.dollarSavings,
    //         carbonSavings: ev1.techDetails.carbonSavings,
    //         leaves: ev1.techDetails.leaves,
    //         isNegative: ev1.techDetails.dollarSavings < 0,
    //       },
    //       ev2: ev2 && {
    //         ...this.techCardsList.transportation?.ev2!,
    //         showTech: true,
    //         priceAfterIncentives: ev2PriceAfterIncentives,
    //         techName: this.evName2,
    //         netCostLabel: this.dashboardAPI.getPaymentTypeLabel(
    //           ev2?.financial!
    //         ),
    //         own: ev2?.techDetails.own!,
    //         financial: ev2?.financial,
    //         monthlyPayment: ev2?.monthlyPayment,
    //         shopNow: ev2?.techDetails.shopNow!,
    //         link: ev2?.techDetails.link,
    //         rebateStatus: ev2?.techDetails.rebateStatus,
    //         dollarSavings: ev2?.techDetails.dollarSavings!,
    //         carbonSavings: ev2?.techDetails.carbonSavings!,
    //         leaves: ev2?.techDetails.leaves!,
    //         isNegative: ev2?.techDetails.dollarSavings < 0,
    //       },
    //       eBike: eBike && {
    //         ...this.techCardsList.transportation?.eBike!,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: eBike?.own!,
    //         priceAfterIncentives: eBike?.priceAfterIncentives,
    //         link: eBike?.link,
    //         shopNow: eBike?.shopNow!,
    //         rebateStatus: eBike?.rebateStatus,
    //         dollarSavings: eBike?.dollarSavings!,
    //         carbonSavings: eBike?.carbonSavings!,
    //         leaves: eBike?.leaves!,
    //         isNegative: eBike?.dollarSavings < 0,
    //       },
    //       evChargers: evChargers && {
    //         ...this.techCardsList.transportation?.evChargers!,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: evChargers.own,
    //         priceAfterIncentives: evChargers.priceAfterIncentives,
    //         link: evChargers.link,
    //         shopNow: evChargers.shopNow,
    //         rebateStatus: evChargers.rebateStatus,
    //         dollarSavings: evChargers.dollarSavings,
    //         carbonSavings: evChargers.carbonSavings,
    //         leaves: evChargers.leaves,
    //         isNegative: evChargers.dollarSavings < 0,
    //       },
    //     };

    //     const { solar, communitySolar, battery, portableBattery } =
    //       response.solarBattery;
    //     const solarBattery: SolarCategoryModel = {
    //       ...this.techCardsList.solarBattery!,
    //       solar: solar && {
    //         ...this.techCardsList.solarBattery?.solar!,
    //         panelStatus: response.panelCount > 30,
    //         error1: this.showErrorMessageTab1,
    //         error2: this.showErrorMessageTab2,
    //         showTech: true,
    //         own: solar?.own!,
    //         financial: solar?.financial!,
    //         link: solar?.link,
    //         priceAfterIncentives:
    //           solar?.financial === "cash"
    //             ? solar?.upfrontCost
    //             : solar?.monthlyPayment,
    //         netCostLabel: this.dashboardAPI.getPaymentTypeLabel(
    //           solar?.financial!
    //         ),
    //         systemSize: solar?.systemSize,
    //         paybackPeriod: solar?.paybackPeriod,
    //         datePurchased: solar?.datePurchased,
    //         shopNow: solar?.shopNow!,
    //         rebateStatus: solar?.rebateStatus,
    //         dollarSavings: solar?.dollarSavings!,
    //         carbonSavings: solar?.carbonSavings!,
    //         leaves: solar?.leaves!,
    //         isNegative: solar?.dollarSavings < 0,
    //       },
    //       communitySolar: communitySolar && {
    //         ...this.techCardsList.solarBattery.communitySolar!,
    //         showTech: true,
    //         netCostLabel: upfrontCost,
    //         priceAfterIncentives: communitySolar?.upfrontCost,
    //         link: communitySolar?.link,
    //         own: communitySolar?.own!,
    //         shopNow: communitySolar?.shopNow!,
    //         dollarSavings: communitySolar?.dollarSavings!,
    //         carbonSavings: communitySolar?.carbonSavings!,
    //         leaves: communitySolar?.leaves!,
    //         isNegative: communitySolar?.dollarSavings < 0,
    //       },
    //       battery: battery && {
    //         ...this.techCardsList.solarBattery?.battery!,
    //         priceAfterIncentives:
    //           battery?.financial === "cash"
    //             ? battery?.priceAfterIncentives
    //             : battery?.monthlyPayment,
    //         netCostLabel: this.dashboardAPI.getPaymentTypeLabel(
    //           battery?.financial!
    //         ),
    //         showTech: true,
    //         own: battery?.own!,
    //         financial: battery?.financial!,
    //         makeAndModel: battery?.model,
    //         datePurchased: battery?.datePurchased,
    //         shopNow: battery?.shopNow!,
    //         link: battery?.link,
    //         rebateStatus: battery?.rebateStatus,
    //         dollarSavings: battery?.dollarSavings!,
    //         carbonSavings: battery?.carbonSavings!,
    //         leaves: battery?.leaves!,
    //         isNegative: battery?.dollarSavings < 0,
    //       },
    //       portableBattery: portableBattery && {
    //         ...this.techCardsList.solarBattery?.portableBattery!,
    //         priceAfterIncentives: portableBattery?.priceAfterIncentives,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: portableBattery?.own!,
    //         financial: portableBattery?.financial!,
    //         makeAndModel: portableBattery?.model,
    //         datePurchased: portableBattery?.datePurchased,
    //         shopNow: portableBattery?.shopNow!,
    //         link: portableBattery.link,
    //         rebateStatus: portableBattery?.rebateStatus,
    //         dollarSavings: portableBattery?.dollarSavings!,
    //         carbonSavings: portableBattery?.carbonSavings!,
    //         leaves: portableBattery?.leaves!,
    //         isNegative: portableBattery?.dollarSavings < 0,
    //       },
    //     };

    //     const { hpwh, hpd, hpsc, ic } = response.appliances;
    //     this.dashboardAPI.icType = ic?.inductionCooktopType;
    //     this.dashboardAPI.getICInfo(ic?.inductionCooktopType);
    //     const appliances: AppliancesCategoryModel = {
    //       ...this.techCardsList.appliances!,
    //       hpwh: hpwh && {
    //         ...this.techCardsList.appliances?.hpwh!,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: hpwh.own,
    //         priceAfterIncentives: hpwh.priceAfterIncentives,
    //         link: hpwh.link,
    //         shopNow: hpwh.shopNow,
    //         rebateStatus: hpwh.rebateStatus,
    //         dollarSavings: hpwh.dollarSavings,
    //         carbonSavings: hpwh.carbonSavings,
    //         leaves: hpwh.leaves,
    //         isNegative: hpwh.dollarSavings < 0,
    //       },
    //       hpd: hpd && {
    //         ...this.techCardsList.appliances?.hpd!,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: hpd.own,
    //         priceAfterIncentives: hpd.priceAfterIncentives,
    //         link: hpd.link,
    //         shopNow: hpd.shopNow,
    //         rebateStatus: hpd.rebateStatus,
    //         dollarSavings: hpd.dollarSavings,
    //         carbonSavings: hpd.carbonSavings,
    //         leaves: hpd.leaves,
    //         isNegative: hpd.dollarSavings < 0,
    //       },
    //       hpsc: hpsc && {
    //         ...this.techCardsList.appliances?.hpsc!,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: hpsc.own,
    //         priceAfterIncentives: hpsc.priceAfterIncentives,
    //         link: hpsc.link,
    //         shopNow: hpsc.shopNow,
    //         rebateStatus: hpsc.rebateStatus,
    //         dollarSavings: hpsc.dollarSavings,
    //         carbonSavings: hpsc.carbonSavings,
    //         leaves: hpsc.leaves,
    //         isNegative: hpsc.dollarSavings < 0,
    //         negativeTooltip:
    //           "Using a natural gas space heating is cheaper than using an air-source heat pump. However, switching from gas reduces carbon emissions.",
    //       },
    //       ic: ic && {
    //         ...this.techCardsList.appliances?.ic!,
    //         netCostLabel: defaultNetCostLabel,
    //         showTech: true,
    //         own: ic.techDetails.own,
    //         priceAfterIncentives: ic.techDetails.priceAfterIncentives,
    //         link: ic.techDetails.link,
    //         shopNow: ic.techDetails.shopNow,
    //         rebateStatus: ic.techDetails.rebateStatus,
    //         dollarSavings: ic.techDetails.dollarSavings,
    //         carbonSavings: ic.techDetails.carbonSavings,
    //         leaves: ic.techDetails.leaves,
    //         techName: this.dashboardAPI.icInfo.title,
    //         img: this.dashboardAPI.icInfo.image,
    //         techParameter:
    //           this.dashboardAPI.icType === "portable" ? "portableIC" : "ic",
    //         isNegative: ic.techDetails.dollarSavings < 0,
    //         negativeTooltip:
    //           "Cooking with natural gas is cheaper than cooking with an induction cooktop. However, switching from natural gas improves indoor air quality and reduces carbon emissions.",
    //       },
    //     };

    //     this.techCardsList = {
    //       simpleLowCost,
    //       transportation,
    //       solarBattery,
    //       appliances,
    //       favorite: {},
    //     };

    //     if (this.showDashboard) {
    //       //OVERVIEW (DOUGHNUT CHART)
    //       const lowCostCarbonSavings =
    //         this.categorySavings.simpleLowCost.carbonSavings;
    //       const lowCostSavings =
    //         this.categorySavings.simpleLowCost.dollarSavings;

    //       const transportCarbonSavings =
    //         this.categorySavings.transportation.carbonSavings;
    //       const transportSavings =
    //         this.categorySavings.transportation.dollarSavings;

    //       const applianceCarbonSavings =
    //         this.categorySavings.appliances.carbonSavings;
    //       const appliancesSavings =
    //         this.categorySavings.appliances.dollarSavings;

    //       const solarBatteryCarbonSavings =
    //         this.categorySavings.solarBattery.carbonSavings;
    //       const solarBatterySavings =
    //         this.categorySavings.solarBattery.dollarSavings;

    //       const dataInputs: ChartDataInputsModel[] = [
    //         {
    //           name: solarBatteryLabel,
    //           y: this.isCarbon
    //             ? solarBatteryCarbonSavings
    //             : solarBatterySavings,
    //           color: this.categoryColorList.solarBattery,
    //         },
    //         {
    //           name: simpleLowCostLabel,
    //           y: this.isCarbon ? lowCostCarbonSavings : lowCostSavings,
    //           color: this.categoryColorList.simpleLowCost,
    //         },
    //         {
    //           name: transportationLabel,
    //           y: this.isCarbon ? transportCarbonSavings : transportSavings,
    //           color: this.categoryColorList.transportation,
    //         },
    //         {
    //           name: homeAppliancesLabel,
    //           y: this.isCarbon ? applianceCarbonSavings : appliancesSavings,
    //           color: this.categoryColorList.appliances,
    //         },
    //       ];
    //       this.updateChartOptions(dataInputs);
    //       this.totalSavings = this.categorySavings.total.dollarSavings;
    //       this.totalCarbonSavings = this.categorySavings.total.carbonSavings;
    //       this.isSavingsZero = this.totalSavings <= 0;
    //       this.isCarbonZero = this.totalCarbonSavings <= 0;
    //     }

    //     // RECOMMENDATIONS CATEGORIES //
    //     this.alreadyHaveTechs.length = 0;
    //     this.recommendationList = {
    //       simpleLowCost: [],
    //       appliances: [],
    //       favorite: [],
    //       solarBattery: [],
    //       transportation: [],
    //     };
    //     Object.entries(this.techCardsList).forEach(
    //       (category: [string, TechCategoryModel]) => {
    //         Object.entries(category[1]).forEach(
    //           (tech: [string, TechDetailsModel]) => {
    //             if (tech[1] && tech[1].own === "yes") {
    //               this.alreadyHaveTechs.push(tech[1]);
    //             } else if (tech[1] && tech[1].own === "yesPlan") {
    //               if (
    //                 this.showErrorMessageTab2 &&
    //                 tech[1].techParameter === "solar"
    //               ) {
    //                 this.recommendationList[category[0]].push(tech[1]);
    //               } else {
    //                 this.recommendationList.favorite.push(tech[1]);
    //                 this.recommendationList[category[0]].push(tech[1]);
    //               }
    //             } else if (tech[1] && tech[1].own) {
    //               this.recommendationList[category[0]].push(tech[1]);
    //             }
    //           }
    //         );
    //       }
    //     );
    //     if (this.showRedirectLoader) {
    //       setTimeout(() => {
    //         this.showRedirectLoader = false;
    //       }, 6000);
    //     } else {
    //       this.loader = false;
    //     }
    //     if (this.dashboardAPI.showTourGuide) {
    //       this.showTourGuide = this.isNewUser;
    //     }
    //     this.getErrorMessage(this.solarDashboardUserConsModel);
    //     this.getErrorCheckingDetails();
    //   },
    //   (error) => {
    //     this.dashboardAPI.alreadyHaveLoading.next("");
    //     this.dashboardAPI.favoriteLoading.next("");
    //     if (error.status === 500) {
    //       this.showErrorMessage = true;
    //       this.loader = false;
    //       this.showTourGuide = false;
    //       this.showDashboard = false;
    //     }
    //   }
    // );
  }

  getUserSettingInfo(callSettings: boolean): void {
    callSettings &&
      this.dashboardAPI
        .getUserSettingInfo()
        .subscribe((response: UserDataModel) => {
          this.dashboardAPI.energyChoicesData = response;
        });
    this.buildingType = this.dashboardAPI.getBuildingType();
    this.renterCondoStatus = this.overviewService.updateEnergyChoiceInfo(
      this.buildingType
    );
    this.buildingTypeRenter = this.buildingType === "renter";
    this.buildingTypeCondo = this.buildingType === "condominium";

    // If approach-1 available based on building type enable the option to show, What Choices Applies to me?
    const renterApproach = this.dashboardAPI.vendorDetails.renterApproach;
    const condoApproach = this.dashboardAPI.vendorDetails.condominiumApproach;
    this.showWhatChoices =
      (this.buildingTypeRenter && renterApproach === "approach1") ||
      (this.buildingTypeCondo && condoApproach === "approach1");
  }

  noSelectionText(): boolean {
    return this.selectionList.length === 0;
  }

  setTimeSpan(span: string) {
    this.twentyYear = span === "twentyYear";
    this.tenYear = span === "tenYear";
    this.oneYear = span === "oneYear";
    this.oneMonth = span === "oneMonth";
  }

  onSolarRemove() {
    this.getDashboardData(
      this.timeSelected,
      this.selected,
      "solar=remove",
      null,
      false
    );
    clearTimeout(this.timer);
    this.timerFunction(15000);
  }

  onSolarAdd() {
    this.getDashboardData(
      this.timeSelected,
      this.selected,
      "solar=add",
      null,
      false
    );
  }

  onRemove(remove_tech): void {
    remove_tech === "solar"
      ? this.onSolarRemove()
      : this.getDashboardData(
          this.timeSelected,
          this.selected,
          "remove=" + remove_tech,
          null,
          false
        );
  }

  onAdd(add_tech): void {
    add_tech === "solar"
      ? this.onSolarAdd()
      : this.getDashboardData(
          this.timeSelected,
          this.selected,
          "add=" + add_tech,
          null,
          false
        );
    let scroll = document.getElementById("selectionOverview");
    if (scroll !== null) {
      scroll.scrollIntoView({ behavior: "smooth" });
      scroll = null;
    }
  }

  onAlreadyHave(tech) {
    this.menuID = tech;
    this.menuID === "solar"
      ? this.getDashboardData(
          this.timeSelected,
          this.selected,
          "solar=have",
          null,
          false
        )
      : this.getDashboardData(
          this.timeSelected,
          this.selected,
          "have=" + this.menuID,
          null,
          false
        );
    this.getVendorDetails();
  }

  closePopUp(val: string) {
    const element = document.getElementById(val);
    if (element) element.style.display = "none";
  }

  onAlertClose() {
    this.dashboardAPI.showPgeAlertStatus = false;
    this.showAlert = false;
  }

  onCloseOfErrorAlert() {
    this.dashboardAPI.showErrorAlertStatus = false;
    this.showErrorAlert = false;
  }

  onCloseOfEmailAlert() {
    this.dashboardAPI.showEmailAlertStatus = false;
    this.showEmailAlert = false;
  }

  onGreetClose(): void {
    this.showGreetAlert = false;
  }

  onEnergyUsage(value: boolean): void {
    this.showHomeSettings = value;
  }

  timerFunction(time) {
    this.timer = setTimeout(() => {
      this.showTechAlert = false;
    }, time);
  }

  openWhatChoices(): void {
    const element = document.getElementById("renterPopup");
    if (element) element.style.display = "block";
  }

  closeWhatChoices(): void {
    const element = document.getElementById("renterPopup");
    if (element) element.style.display = "none";
  }

  openSavingsDialog(value: string) {
    this.savingsPopup = value === "savings";
    this.showSavingsPopup = true;
  }

  closeSavingsDialog() {
    this.showSavingsPopup = false;
  }

  openHomeSettings(value: boolean) {
    this.timeoutId = setTimeout(() => {
      this.showHomeSettings = value;
    }, 2000);
  }

  closeTourGuide(): void {
    this.showTourGuide = false;
    this.dashboardAPI.showTourGuide = false;
  }

  selectedTab(event: string): void {
    this.dashboardAPI.activeRecommendationTab = this.activeTab = event;
    this.isSimpleLowCost = event === CleanChoicesTabs.SIMPLE_LOW_COST;
    this.section = "other" + this.utils.toTitleCase(event);
  }

  getErrorMessage(data: SolarDashboardUserConsModel) {
    if (data.solarBattery.solar) {
      this.showDashboard = true;
      this.showMaxPanelError = false;
      this.showMinPanelError = false;
      this.showSolarTabContent = true;
      if (data.errorMsg_1) {
        this.showErrorMessageTab1 = true;
        this.showErrorMessageTab2 = false;
        this.showSolarTabContent = false;
      } else if (data.errorMsg_2) {
        this.showErrorMessageTab2 = true;
        this.showErrorMessageTab1 = false;
        this.solarErrorMessage = data.message!;
        this.showSolarTabContent = false;
      } else {
        this.showErrorMessageTab2 = false;
        this.showErrorMessageTab1 = false;
        this.showSolarTabContent = true;
      }
    } else {
      if (data.errorMsg_1) {
        this.showMaxPanelError = true;
        this.showMinPanelError = false;
        this.errorPhrase1 =
          "Your home likely requires a solar system that is greater than 25kW." +
          " " +
          this.solarVendorDetailsModel.description +
          " will contact you to discuss a custom quote";
        this.errorPhrase2 = "";
      }
      if (data.errorMsg_2) {
        this.showMinPanelError = true;
        this.showMaxPanelError = false;
        this.errorPhrase1 = "";
        this.errorPhrase2 = data.message!;
      }
    }
    this.showAlert =
      data.showPGEAlert !== "Complete auth" &&
      data.showPGEAlert !== "No GBC" &&
      data.showPGEAlert !== "PGE under maintenance" &&
      this.dashboardAPI.showPgeAlertStatus;
    this.partialAlert =
      data.showPGEAlert === "Partial auth" &&
      this.dashboardAPI.showPgeAlertStatus;
    this.sliderAlert =
      data.showPGEAlert === "Slider" && this.dashboardAPI.showPgeAlertStatus;
    this.revokedAlert =
      data.showPGEAlert === "Revoked" && this.dashboardAPI.showPgeAlertStatus;
    if (this.errorMessageRef)
      this.errorMessageRef.nativeElement.innerHTML = this.errorMsg;
    this.showCarouselCount();
  }

  getErrorCheckingDetails(): void {
    this.dashboardAPI
      .getErrorCheckingDetails()
      .subscribe((data: errorCheckingDetailsModel) => {
        this.errorCheckingDetailsList = data;
        this.showErrorAlert =
          this.errorCheckingDetailsList.errorMessage !== false &&
          this.showErrorAlert
            ? true
            : false;
        if (this.showErrorAlert) {
          this.alertCounter += 1;
        }
        this.errorTechnologiesList.length = 0;
        if (this.errorCheckingDetailsList.errorTechnologies.length !== 0) {
          for (
            let i = 0;
            i < this.errorCheckingDetailsList.errorTechnologies.length;
            i++
          ) {
            this.errorTechnologiesList.push(
              this.errorCheckingDetailsList.errorTechnologies[i]
            );
          }
        }
      });
  }

  onCloseOfOutcomeErrorAlert() {
    this.dashboardAPI.showErrorAlertStatus = false;
    this.showErrorAlert = false;
  }

  showCarouselCount() {
    this.alertCounter = 0;
    if (this.showGreetAlert) {
      this.alertCounter += 1;
    }
    if (this.showEmailAlert) {
      this.alertCounter += 1;
    }
    if (this.showAlert) {
      this.alertCounter += 1;
    }
  }

  onChangeCount() {
    this.alertCounter -= 1;
  }

  onTour(): void {
    this.showTourGuide = true;
    this.showRedirectLoader = false;
    this.dashboardAPI.dashboardDetailsAnalytics("tourGuide");
  }

  updateRecommendationTab(): void {
    const searchParams = new URLSearchParams(window.location.search);
    const tabToSectionMapping = {
      simpleLowCost: CleanChoicesSections.OTHER_SIMPLE_LOW_COST,
      transportation: CleanChoicesSections.OTHER_TRANSPORTATION,
      appliances: CleanChoicesSections.OTHER_APPLIANCES,
    };
    if (searchParams.has("tab")) {
      this.activeTab = searchParams.get("tab")!;
      this.activeTech = searchParams.get("tech")!;
      window.history.pushState({}, "YLTN", "/dashboard/overview");
    } else if (this.dashboardAPI.activeRecommendationTab) {
      this.activeTab = this.dashboardAPI.activeRecommendationTab;
    } else {
      this.activeTab = CleanChoicesTabs.SIMPLE_LOW_COST;
    }
    this.section = tabToSectionMapping[this.activeTab];
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
    clearTimeout(this.timeoutId);
  }
}
