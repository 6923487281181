import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { LayoutService } from "./homeowner-product/shared/layout/layout.service";
import { RestAPIService } from "./rest-api/rest-api.service";
import { AppService } from "./app.service";
import { DOCUMENT } from "@angular/common";
import { LogoModel } from "./rest-api/rest-api.model";
import { take, filter } from "rxjs/operators";
import { environment } from "../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import Hotjar from "@hotjar/browser";

@Component({
  selector: "my-app",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  logoModel: LogoModel;
  alive = true;
  smd: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private restAPIService: RestAPIService,
    private appService: AppService,
    private translate: TranslateService,
    private router: Router,
    @Inject(DOCUMENT) private document: any
  ) {
    this.translate.setDefaultLang("en");
    this.translate.use("en");
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        const params = this.activatedRoute.snapshot.queryParams;
        let vendor = params["vendor"];
        let hostname = this.document.location.hostname;
        if (!vendor) {
          if (hostname) {
            if (hostname == "localhost" || hostname.includes("hp-app")) {
              vendor = "YLTN";
            } else if (hostname.includes("ups.yellowtin")) {
              vendor = "UPSS";
            } else if (hostname.includes("wsp.yellowtin")) {
              vendor = "WSPP";
            } else {
              vendor = hostname.substr(0, 4);
              vendor = vendor.toUpperCase();
            }
          }
        }
        if (params["action"]) {
          this.smd = true;
          this.layoutService.setSmdLinkSubject.next(true);
        } else {
          this.smd = false;
          this.layoutService.setSmdLinkSubject.next(false);
        }
        this.setScript(vendor);
        this.restAPIService
          .getSolarVendorDetails(vendor)
          .pipe(take(1))
          .subscribe((data) => {
            this.restAPIService.solarVendorInformation = data;
            this.logoModel = data.logo;
            this.appService.updateTheme(data.themeClasses);
            this.restAPIService.setLogo(this.logoModel);
            this.appService.dashboardlogoModel.next(this.logoModel);
            this.appService.solarVendorModel.next(data);
            this.appService.languageModel.next(data.languageTranslator);
            this.appService.currentVendorName.next(data.code);
          });
        this.appService.setVendorName(vendor);
        this.restAPIService.setVendorName(vendor);
        this.appService.setSMDLink(this.smd);
      });
  }

  activateEvent(event) {
    if (!environment.production) {
    }
  }

  deactivateEvent(event) {
    if (!environment.production) {
    }
  }

  setScript(vendor: string): void {
    const hotJarCodeData = this.appService.vendorsList;
    const siteId = hotJarCodeData[vendor].id;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  }
}
