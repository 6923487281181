import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { MarketPlaceDetailsModel } from "../learn-more.model";
import { LearnMoreService } from "../learn-more.service";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import {
  SmartPowerStripAssumptionsModel,
  SmartPowerStripDetailsModel,
  SmartPowerStripFinancialModel,
  SmartPowerStripLearnMoreModel,
  SmartPowerStripTechnicalDetailsModel,
} from "./smart-power-strip.model";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { AppService } from "../../../app.service";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-smart-power-strip-details",
  templateUrl: "./smart-power-strip-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class SmartPowerStripDetailsComponent
  implements OnInit, AfterContentChecked
{
  loader: boolean = true;
  icon: boolean = false;
  showIncentivesBreakdown = false;
  marketplaceDetails: MarketPlaceDetailsModel;
  displayMarketPlace: boolean;
  description: string;
  marketPlaceLink: string;
  linkId: string;
  readMore: boolean = true;
  readLess: boolean = false;
  totalIncentives: number;
  incentiveStatus: boolean;
  adviceCardStatus: boolean;
  powerStripDetails: SmartPowerStripDetailsModel;
  assumptionDetails: SmartPowerStripAssumptionsModel;
  financialDetails: SmartPowerStripFinancialModel;
  technicalDetails: SmartPowerStripTechnicalDetailsModel;
  activeIndex: number = 0;
  tabLabel: string;
  tabIndex: number = 0;
  isLangSpanish: boolean;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  isFAQExpanded: boolean;
  favStatus: boolean;
  favIcon: string;
  learnMoreDetails: SmartPowerStripLearnMoreModel;
  showCash: boolean = true;
  showBlackLoader: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private LearnMoreService: LearnMoreService,
    private dashboardAPI: DashboardAPIService,
    private cdRef: ChangeDetectorRef,
    private appService: AppService
  ) {}

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.dashboardAPI
      .faqDetails()
      .pipe(take(1))
      .subscribe((list: SectionListModel) => {
        this.FAQTabsList = list.smartPowerStrips.faqList!;
      });
    this.getSmartPowerStripsDetails();
    this.marketplaceAPI();
  }

  marketplaceAPI(): void {
    this.dashboardAPI
      .getMarketplaceDetails("smartPowerStrips")
      .pipe(take(1))
      .subscribe((data: MarketPlaceDetailsModel) => {
        this.marketplaceDetails = data;
        this.adviceCardStatus = data.expertAdvice;
        this.displayMarketPlace = this.marketplaceDetails.displayBox;
        this.marketPlaceLink = this.marketplaceDetails.buttonLink;
        this.linkId = this.marketplaceDetails.linkId;
        if (this.marketplaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketplaceDetails.linkId;
        }
      });
  }

  getSmartPowerStripsDetails(): void {
    this.LearnMoreService.learnMoreInfo<SmartPowerStripDetailsModel>(
      "smartPowerStrips"
    )
      .pipe(take(1))
      .subscribe((response: SmartPowerStripDetailsModel) => {
        this.powerStripDetails = response;
        this.assumptionDetails = response.assumptionDetails;
        this.financialDetails = response.financialDetails;
        this.technicalDetails = response.technicalDetails;
        this.incentiveStatus = this.financialDetails.costDetails.showIncentives;
        this.totalIncentives = this.financialDetails.costDetails.incentives;
        this.description = this.technicalDetails.smartPowerStripsMoreDetails[0];
        this.favStatus = this.powerStripDetails.learnMoreDetails.favoriteStatus;
        this.favIcon = this.LearnMoreService.updateFavoriteIcon(this.favStatus);
        this.showBlackLoader = this.loader = false;
      });
  }

  onReadContent(value: string): void {
    if (value === "readMore") {
      this.readMore = false;
      this.readLess = true;
      this.description += this.technicalDetails.smartPowerStripsMoreDetails[1];
    } else if (value === "readLess") {
      this.readMore = true;
      this.readLess = false;
      this.description = this.technicalDetails.smartPowerStripsMoreDetails[0];
    }
  }

  handleActiveValue(data: string): void {
    if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon = true;
    }
  }

  faqExpansionClick(): void {
    this.icon = !this.icon;
  }

  handleFAQClick(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("moreInfo", "smartPowerStrips");
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/smart-power-strips-faq-es/",
      "_blank"
    );
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo("smartPowerStrips", ownPlan)
      .pipe(take(1))
      .subscribe(() => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.LearnMoreService.updateFavoriteIcon(this.favStatus);
        this.getSmartPowerStripsDetails();

        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardAPI.techMessage("smartPowerStrips", action);
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }
}
