import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { RestAPIService } from "../../../../../../rest-api/rest-api.service";
import {
  EnergyStatusModel,
  UtilityProviderModel,
} from "../../../../../../shared/energy-info/energy-info.model";
import { EnergyInfoService } from "./energy-info.service";

@Component({
  selector: "yt-energy-info",
  templateUrl: "./energy-info.component.html",
  styleUrls: ["./energy-info.component.scss", "../../common-tech.scss"],
})
export class EnergyInfoComponent implements OnInit {
  energyStatusModel: EnergyStatusModel;
  energyStatusMessage: string;
  authorizationSection: boolean = true;
  revokedAuth: boolean = true;
  authorizationSectionPGE: boolean = true;
  authorizeEnergyUsageUrl: string;
  authorizedClicked: boolean;
  loader: boolean = false;
  authorizationLink: boolean = true;
  energyInfoSection: boolean = true;
  utilityProviderResponse: UtilityProviderModel;
  energyInfoStatus: EnergyStatusModel;
  pgeSuccess: boolean;
  pgeInProgress: boolean;
  isPGE: boolean;
  pgeStartStatus: boolean;
  timeoutId: any;
  @Input() utilityName: string;
  @Input() everGreenStatus: boolean;
  @Input() smdStatus: boolean;
  @Output() renewableStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() avgMonthlyBill: EventEmitter<string> = new EventEmitter();

  constructor(
    private restAPI: RestAPIService,
    private dashboardAPI: DashboardAPIService,
    private energyInfoService: EnergyInfoService
  ) {}

  ngOnInit(): void {
    this.utilityProviderResponse =
      this.energyInfoService.utilityProviderResponse;
    this.energyInfoStatus = this.energyInfoService.energyInfoStatusResponse;
    if (this.smdStatus) {
      this.getEnergyInfoStatus();
      this.getUtilityProvider();
    }
  }

  getUtilityProvider(): void {
    if (this.utilityProviderResponse) {
      this.isPGE = this.utilityProviderResponse.code === "PGE";
      if (this.isPGE) {
        if (this.utilityProviderResponse.website.oauth) {
          this.authorizationSectionPGE = true;
          this.authorizeEnergyUsageUrl =
            this.utilityProviderResponse.website.oauth +
            this.dashboardAPI.getUserDetails().userName +
            ";" +
            this.dashboardAPI.getUserDetails().solarVendorCode +
            ";" +
            this.dashboardAPI.buildType;
        } else {
          this.authorizationSectionPGE = false;
        }
      }
    }
  }

  getEnergyInfoStatus(): void {
    if (this.energyInfoStatus.message === "NO GBC") {
      this.authorizationSection = false;
      this.energyInfoSection = true;
      this.pgeInProgress = false;
      this.pgeSuccess = false;
      this.revokedAuth = true;
    } else if (this.energyInfoStatus.message === "DATA RETRIEVED") {
      this.pgeInProgress = false;
      this.pgeSuccess = true;
      this.energyInfoSection = false;
      this.revokedAuth = true;
    } else if (this.energyInfoStatus.message === "IN PROGRESS") {
      this.pgeInProgress = true;
      this.pgeSuccess = false;
      this.energyInfoSection = false;
      this.revokedAuth = true;
    } else if (this.energyInfoStatus.message === "NO AUTH") {
      this.pgeInProgress = false;
      this.pgeSuccess = false;
      this.energyInfoSection = true;
      this.revokedAuth = true;
    } else if (
      this.energyInfoStatus.message === "REVOKED AUTH" ||
      this.energyInfoStatus.message === "SINGLE DATA"
    ) {
      this.pgeInProgress = false;
      this.pgeSuccess = false;
      this.energyInfoSection = true;
      this.authorizedClicked = false;
      this.revokedAuth = false;
    }
  }

  onRefresh() {
    this.restAPI.energyStatusAPI.subscribe((data: EnergyStatusModel) => {
      const pgeCompleted: boolean = JSON.parse(
        localStorage.getItem("pgeCompleted") || "false"
      );
      if (data.message === "NO AUTH") {
        this.pgeInProgress = false;
        this.pgeSuccess = false;
        this.authorizedClicked = false;
        this.energyInfoSection = true;
        this.revokedAuth = true;
        this.pgeStartStatus = false;
      } else if (data.message === "IN PROGRESS") {
        this.authorizedClicked = true;
        this.pgeSuccess = false;
        this.pgeInProgress = true;
        this.energyInfoSection = false;
        this.pgeStartStatus = false;
      } else if (data.message === "DATA RETRIEVED") {
        this.authorizedClicked = true;
        this.pgeSuccess = true;
        this.pgeInProgress = false;
        this.energyInfoSection = false;
        this.pgeStartStatus = false;
      } else if (
        data.message === "REVOKED AUTH" ||
        data.message === "SINGLE DATA"
      ) {
        this.authorizedClicked = false;
        this.pgeSuccess = false;
        this.pgeInProgress = false;
        this.energyInfoSection = true;
        this.revokedAuth = false;
        this.pgeStartStatus = false;
      } else if (pgeCompleted) {
        this.energyInfoSection = true;
        this.authorizedClicked = false;
        this.pgeSuccess = false;
        this.pgeInProgress = false;
        this.revokedAuth = false;
        this.pgeStartStatus = true;
      }
      if (this.authorizedClicked) {
        this.loader = true;
        const loaderElement = document.getElementById("loader");
        if (loaderElement) loaderElement.style.display = "block";
      } else {
        const noAuthElement = document.getElementById("noAuth");
        if (noAuthElement) noAuthElement.style.display = "block";
      }
    });
    this.timeoutId = setTimeout(() => {
      this.loader = false;
      this.offRefresh();
    }, 5000);
  }

  offRefresh(): void {
    const loaderElement = document.getElementById("loader");
    const noAuthElement = document.getElementById("noAuth");
    if (loaderElement) loaderElement.style.display = "none";
    if (noAuthElement) noAuthElement.style.display = "none";
  }

  onAvgBill(value: string): void {
    this.avgMonthlyBill.emit(value);
  }

  onRenewable(event): void {
    this.renewableStatus.emit(event.checked);
  }

  pgeStarted(): void {
    this.authorizedClicked = false;
    this.pgeSuccess = false;
    this.pgeInProgress = false;
    this.energyInfoSection = true;
    this.pgeStartStatus = true;
    this.revokedAuth = false;
  }
  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
