import { AppService } from "../../app.service";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { RoofTopPageModel, RoofTopPostModel } from "./roof-top.model";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LifestyleService } from "../../rest-api/lifestyle.service";
import { LayoutService } from "../shared/layout/layout.service";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "yt-roof-top",
  templateUrl: "roof-top.component.html",
  styleUrls: ["./roof-top.component.scss"],
})
export class RoofTopComponent implements OnInit, OnDestroy {
  roofTopQAForm: FormGroup;
  roofTopPageModel: RoofTopPageModel;
  roofTopDetails: RoofTopPostModel;
  continueWithoutSolar: boolean = false;
  roofAgeQuestion: boolean;
  planForReRoofQuestion: boolean;
  roofSlopeQuestion: boolean;
  roofShadeQuestion: boolean;
  roofTypeQuestion: boolean;
  yesSolarQuestion: boolean = true;
  vendorBasedRoofTypes;
  updateMode: boolean;
  userRoofTopDetails: RoofTopPostModel;
  showPopup: boolean;
  buildingTypeRenter: boolean;
  buildingTypeCondo: boolean;
  installSolarPopup: boolean = false;
  initScrollId: string = "1";
  initialUserDetailsValue: RoofTopPostModel = {
    solar: "",
    planForReRoof: "",
    roofAge: "",
    roofSlopes: [],
    roofShade: "",
    roofTypes: [],
  };
  private alive = true;
  timeoutId: any;

  constructor(
    private layoutServiceV2: LayoutService,
    private restAPIService: RestAPIService,
    private fb: FormBuilder,
    private router: Router,
    private appService: AppService,
    private lifestyleService: LifestyleService,
    private route: ActivatedRoute
  ) {}

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key === "Escape") {
      this.closePopup();
    }
  }

  ngOnInit() {
    this.getRoofTopQA();
    if (!this.restAPIService.getUserProvidedRoofTopInfo()) {
      this.restAPIService.setDestination(2);
    }
    this.userRoofTopDetails = this.restAPIService.getUserProvidedRoofTopInfo();
    this.roofTopDetails =
      this.userRoofTopDetails || this.initialUserDetailsValue;
    this.updateMode = !!this.userRoofTopDetails;
    if (this.restAPIService.solarVendorInformation.solutionType === "vendor") {
      this.initialUserDetailsValue.solar = "yesPlan";
      this.yesSolarQuestion = false;
      this.initScrollId = "2";
    }
    this.hideAllQuestion();
    this.createRoofTopForm();
    if (this.userRoofTopDetails) {
      if (this.userRoofTopDetails.solar === "no") {
        this.hideAllQuestion();
        this.continueWithoutSolar = true;
      } else {
        this.showAllQuestions();
        this.continueWithoutSolar = false;
      }
    }
    if (
      this.buildingTypeValue === "renter" ||
      this.buildingTypeValue === "condominium"
    ) {
      this.showPopup = !this.lifestyleService.isRenterCondoUser;
    }
    this.buildingTypeRenter = this.buildingTypeValue === "renter";
    this.buildingTypeCondo = this.buildingTypeValue === "condominium";
  }

  getRoofTopQA() {
    const vendorName = this.appService.getVendorName();
    this.restAPIService
      .getAllRoofTopQA(vendorName)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.roofTopPageModel = data;
        this.roofTopPageModel.roofTypes =
          this.restAPIService.getSolarVendorInformation().roofTypes;
        this.timeoutId = setTimeout(() => {
          const scroller = document.getElementById(this.initScrollId);
          if (scroller)
            scroller.addEventListener("click", () =>
              this.restAPIService.scrollIntoElement(this.initScrollId)
            );
        }, 100);
      });
  }

  hideAllQuestion() {
    this.roofAgeQuestion =
      this.restAPIService.solarVendorInformation.solutionType === "vendor";
    this.planForReRoofQuestion = false;
    this.roofSlopeQuestion = false;
    this.roofShadeQuestion = false;
    this.roofTypeQuestion = false;
  }
  showAllQuestions() {
    this.roofAgeQuestion = true;
    this.planForReRoofQuestion = true;
    this.roofSlopeQuestion = true;
    this.roofShadeQuestion = true;
    this.roofTypeQuestion = true;
  }

  createRoofTopForm() {
    this.roofTopQAForm = this.fb.group({
      solar: [""],
      roofAge: ["", Validators.required],
      planForReRoof: ["", Validators.required],
      roofSlopes: [[""], Validators.required],
      roofShade: ["", Validators.required],
      roofTypes: [[""], Validators.required],
    });

    if (this.updateMode) {
      this.roofTopQAForm.patchValue(this.userRoofTopDetails);
      if (this.roofTopQAForm.value.solar === "no") {
        this.restAPIService.postRoofTopQA(this.userRoofTopDetails).subscribe();
      } else if (this.roofTopQAForm.valid) {
        if (
          this.restAPIService.solarVendorInformation.solutionType === "vendor"
        ) {
          this.roofTopQAForm.value.solar = "yesPlan";
        }
        this.restAPIService.postRoofTopQA(this.userRoofTopDetails).subscribe();
      }
    }

    this.roofTopQAForm.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        if (this.roofTopQAForm.value.solar === "no") {
          this.roofTopQAForm.value.planForReRoof = "";
          this.roofTopQAForm.value.roofAge = "";
          this.roofTopQAForm.value.roofShade = "";
          this.roofTopQAForm.value.roofSlopes = "";
          this.roofTopQAForm.value.roofTypes = "";
          this.restAPIService.postRoofTopQA(data).subscribe();
        } else if (this.roofTopQAForm.valid) {
          if (
            this.restAPIService.solarVendorInformation.solutionType === "vendor"
          ) {
            this.roofTopQAForm.value.solar = "yesPlan";
          }
          this.restAPIService.postRoofTopQA(data).subscribe();
        }
      });
  }

  onBuldingTypeQuestion(solar: string) {
    this.roofTopQAForm.controls["solar"].setValue(solar);
    if (this.roofTopQAForm.controls["solar"].value === "no") {
      this.continueWithoutSolar = true;
    } else {
      this.continueWithoutSolar = false;
    }
    if (this.roofTopQAForm.controls["solar"].value === "yes") {
      this.roofAgeQuestion = true;
    } else {
      this.roofAgeQuestion = false;
      this.roofTopQAForm.valid == true;
    }
  }

  onRoofAgeQuestion(age: string) {
    this.roofTopQAForm.controls["roofAge"].setValue(age);
    this.planForReRoofQuestion = true;
  }

  onPlanForReRoofQuestion(plan: string) {
    this.roofTopQAForm.controls["planForReRoof"].setValue(plan);
    this.roofSlopeQuestion = true;
  }

  onRoofSlopeQuestion(roofSlope: string) {
    this.roofTopQAForm.controls["roofSlopes"].setValue([roofSlope]);
    this.roofShadeQuestion = true;
  }

  onRoofShadeQuestion(roofShade: string) {
    this.roofTopQAForm.controls["roofShade"].setValue(roofShade);
    this.roofTypeQuestion = true;
  }

  onRoofTypeQuestion(roofType) {
    this.roofTopQAForm.controls["roofTypes"].setValue([roofType]);
  }

  gotoLifestyle() {
    let disableAdditionalInfo: boolean;
    let hidePoweredBy: boolean;
    true;
    let vendorType = this.restAPIService.solarVendorInformation.solutionType;
    let menuNumber: string;
    if (
      vendorType === undefined ||
      vendorType === "cce" ||
      vendorType === "broader"
    ) {
      disableAdditionalInfo = true;
    } else {
      disableAdditionalInfo = false;
    }
    hidePoweredBy =
      vendorType === "broader" || vendorType === undefined ? true : false;
    menuNumber = disableAdditionalInfo ? "4" : "5";
    this.layoutServiceV2.navRouterSubject.next({
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "1",
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "2",
      },
      lifeStyle: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
        menuNumber: "3",
      },
      additionalinfo: {
        showLink: !disableAdditionalInfo,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "4",
      },
      upgrades: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "3",
      },
      energyUsage: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
        menuNumber: menuNumber,
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "",
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      poweredbyYt: {
        showLink: !hidePoweredBy,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
    });
    this.router.navigate(["energy-choices"], { relativeTo: this.route.parent });
  }

  gotoUpdate() {
    let disableAdditionalInfo: boolean;
    let hidePoweredBy: boolean = true;
    let vendorType = this.restAPIService.solarVendorInformation.solutionType;
    let menuNumber: string;
    if (
      vendorType === undefined ||
      vendorType === "cce" ||
      vendorType === "broader"
    ) {
      disableAdditionalInfo = true;
    } else {
      disableAdditionalInfo = false;
    }
    hidePoweredBy =
      vendorType === "broader" || vendorType === undefined ? true : false;
    menuNumber = disableAdditionalInfo ? "4" : "5";
    this.layoutServiceV2.navRouterSubject.next({
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "1",
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "2",
      },
      lifeStyle: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "3",
      },
      additionalinfo: {
        showLink: !disableAdditionalInfo,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "4",
      },
      upgrades: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
        menuNumber: "3",
      },
      energyUsage: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
        menuNumber: menuNumber,
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "",
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      poweredbyYt: {
        showLink: !hidePoweredBy,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
    });
    this.router.navigate(["energy-changes"], { relativeTo: this.route.parent });
  }

  get buildingTypeValue(): string {
    return this.restAPIService.getBuildingType();
  }

  openPopup() {
    this.installSolarPopup = true;
  }

  closePopup() {
    this.installSolarPopup = false;
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutId);
    const scroller = document.getElementById(this.initScrollId);
    if (scroller) {
      scroller.removeEventListener("click", () =>
        this.restAPIService.scrollIntoElement(this.initScrollId)
      );
    }
    this.alive = false;
  }
  onContinue() {
    this.restAPIService.buildingType = this.buildingTypeValue;
    const buildingTypes = ["nonProfit", "commercial"];
    buildingTypes.includes(this.buildingTypeValue)
      ? this.gotoUpdate()
      : this.gotoLifestyle();
  }
}
