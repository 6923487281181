<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">{{ header }}</div>
      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isSavings]="false"
        [isDescription]="true"
        [isSettings]="false"
        [isDetails]="false"
        [isAssumptions]="false"
        [isFaq]="true"
        [techParameter]="'everGreen'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>
      <div>
        <div
          class="yt-text"
          [innerHTML]="
            'homeOwenerDashboard.learnMore.everGreen.paragraph' | translate
          "
        ></div>
        <div *ngIf="!isLangSpanish" id="aboutTechnology">
          <yt-evergreen-faq [showAboutTech]="true"></yt-evergreen-faq>
        </div>
        <div class="faq_mobile">
          <button
            (click)="onFavorite()"
            class="favorite_btn"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
      </div>

      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="assets/dashboard/dashboard_icons_v2/everGreen.png"
              alt="heat pump dryer"
              [ngStyle]="{ height: !displayMarketPlaceBox ? '160px' : '200px' }"
            />
          </div>
          <button
            (click)="onFavorite()"
            class="favorite_btn mt_20"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button mt_20"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
        <div class="width-box" id="cost" *ngIf="financialDetails">
          <yt-cost-rendering
            [financialDetails]="financialDetails.costDetails"
            [paymentTypeLabel]="'upfront cost'"
            [isEvergreen]="true"
          >
          </yt-cost-rendering>

          <yt-market-place
            [tech_name]="'everGreen'"
            [linkId]="linkId"
            [marketPlaceDetails]="marketPlaceDetails"
            *ngIf="marketPlaceDetails && displayMarketPlaceBox"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'everGreen'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div class="battery_bottom_section w-100">
        <div class="left_section pr_0"></div>
        <div class="right_section">
          <div class="highChart_card evergreen_text" id="description">
            <div class="details_header">DESCRIPTION</div>
            <div class="yt_text">
              We all use electricity. Why not feel great about where it comes
              from? If you’re dedicated to making a larger impact on fighting
              the climate crisis, you can receive 100% renewable electricity for
              a little bit more per month.
            </div>
            <div class="yt_text battery-pt">
              This service is a quick and easy way to reduce your carbon
              footprint<span
                class="read_link"
                *ngIf="readMore"
                (click)="onReadContent('readMore')"
              >
                Read more... </span
              ><span *ngIf="readLess"
                >, and it’s available to everyone, including renters and those
                without the ability to install solar panels.</span
              >
            </div>
            <div class="yt_text battery-pt" *ngIf="readLess">
              Even better, there’s no upfront cost for participation. Your power
              will be supplied by energy sources such as solar, wind, and
              geothermal, eliminating nearly all the greenhouse gas emissions
              from your electricity use. It’s a great way to feel good about the
              energy that's powering your life.
              <span
                class="read_link yt_read_less"
                *ngIf="readLess"
                (click)="onReadContent('readLess')"
                >Read less</span
              >
            </div>
          </div>
          <div class="faq_card" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="basicInfoPanelClick()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon1 ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-evergreen-faq
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-evergreen-faq>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div
        class="link_text back_to_top"
        (click)="scrollToTop()"
        *ngIf="!isLangSpanish"
      >
        <mat-icon class="pointer mat_color">keyboard_arrow_up</mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<!-- loader -->
<yt-loader
  [blackLoader]="showBlackLoader"
  *ngIf="loader || showBlackLoader"
  top="0"
  [relative]="true"
></yt-loader>
