import { Component, Input } from "@angular/core";

@Component({
  selector: "yt-ev-chargers-faq",
  templateUrl: "./ev-chargers-faq.component.html",
  styleUrls: ["../common-faq.scss"],
})
export class EvChargersFaqComponent {
  showReadMore: boolean = true;
  showReadLess: boolean = false;
  @Input() activeIndex: number;
  @Input() showAboutTech: boolean;

  isActive(index: number): boolean {
    return index === this.activeIndex;
  }

  onReadContent(value: string) {
    if (value === "readMore") {
      this.showReadMore = false;
      this.showReadLess = true;
    } else if (value === "readLess") {
      this.showReadMore = true;
      this.showReadLess = false;
    }
  }
}
