import { Component } from "@angular/core";
import { GoalCardsInitial } from "./goal.init";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { HomeOwnerInfoService } from "../../homeowner-info.service";
import { HPJourneyDetailsPUTModel } from "../../homeowner-info.model";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-goal",
  templateUrl: "./goal.component.html",
  styleUrls: ["../component-common.scss"],
})
export class GoalComponent {
  categoriesList: {
    img: string;
    altText: string;
    viewValue: string;
    value: string;
    subHeader: string;
    selected: boolean;
  }[];
  selectedGoals: string[] = [];
  timeoutId: any;
  constructor(
    private goalListInit: GoalCardsInitial,
    private restAPI: RestAPIService,
    private homeOwnerInfoService: HomeOwnerInfoService
  ) {
    this.categoriesList = this.goalListInit.categoryList;
  }

  onSelectGoal(event: any, goal: string, i: number): void {
    this.categoriesList[i].selected =
      this.categoriesList[i].value === goal && event.checked;
    if (event.checked) {
      this.selectedGoals.push(this.categoriesList[i].value);
    } else {
      this.selectedGoals.splice(this.selectedGoals.indexOf(goal), 1);
    }
  }

  onSubmit(onContinue: boolean): void {
    const button = document.querySelector<HTMLElement>("#loaderButton");
    if (onContinue && button)
      button.innerHTML =
        'Submitting... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
    const goalDetails: HPJourneyDetailsPUTModel = {
      goalInfo: {
        onContinue,
        goals: this.selectedGoals || [],
      },
    };
    this.homeOwnerInfoService
      .postUserDetails(goalDetails)
      .pipe(take(1))
      .subscribe(
        (response) => {
          if (onContinue && button) {
            this.timeoutId = setTimeout(() => {
              button.style.pointerEvents = "none";
              this.homeOwnerInfoService.checkAutoLogin();
            }, 1500);
          }
        },
        (error) => {
          this.restAPI.showErrorMessage(error.error.message);
          if (error.status === 409 && button) {
            button.innerHTML = "Let's Go!";
          }
        }
      );
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
