import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import {
  BatteryCashModel,
  BatteryLoanModel,
  BatteryDetailsModel,
  BatteryFinancialModel,
  BatteryTechnicalModel,
  BatteryLearnMoreModel,
  BatterySavingsModel,
  BatteryAssumptionModel,
} from "./battery-details.model";
import {
  MarketPlaceDetailsModel,
  LearnMoreRequestModel,
  BarChartDataModel,
} from "../learn-more.model";
import { LearnMoreService } from "../learn-more.service";
import { TimeFrameEnum } from "../learn-more.enum";
import * as Highcharts from "highcharts";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { AppService } from "../../../app.service";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-battery-details",
  templateUrl: "battery-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class BatteryDetailsComponent implements AfterContentChecked {
  icon: boolean = false;
  icon1: boolean = false;
  batteryViewDetails: BatteryDetailsModel;
  financialDetails: BatteryFinancialModel;
  technicalDetails: BatteryTechnicalModel;
  learnMoreDetails: BatteryLearnMoreModel;
  assumptionDetails: BatteryAssumptionModel;
  savingsDetails: BatterySavingsModel;
  marketPlaceDetails: MarketPlaceDetailsModel;
  twentyYear = false;
  oneYear = true;
  oneMonth = false;
  showIncentivesStatus: boolean;
  batteryForm: FormGroup;
  loader: boolean = true;
  showCash: boolean;
  showLoan: boolean;
  batCash: BatteryCashModel;
  batLoan: BatteryLoanModel;
  totalIncentives: number;
  financial = true;
  haveResponse = false;
  financeValue: string;
  showCompareFinancial = false;
  showIncentiveBreakdown = false;
  displayMarketPlaceBox: boolean;
  marketPlaceLink: string;
  financeList;
  linkId: string;
  selected: TimeFrameEnum;
  timeFrame: string;
  paybackPeriod: number;
  displayPayback: boolean;
  displayPaybackGTTwenty: boolean;
  payBackYear: string;
  yAxisLabel: string;
  yAxisLabel1: string;
  battery_Savings: number;
  chartOptions1: Highcharts.Options = {};
  backupHourLabel: string;
  backupHourToolTip: string;
  adviceCardStatus: boolean;
  timeSegmentList: DropDownModel[];
  showHighChart: boolean;
  tabIndex: number = 0;
  tabLabel: string;
  isLangSpanish: boolean = false;
  FAQTabsList: DropDownModel[];
  selectedSection: string;
  paymentTypeLabel: string;
  showReadMore: boolean = true;
  showReadLess: boolean = false;
  isTechnicalExpanded: boolean;
  isFAQExpanded: boolean;
  isLoadShift: boolean;
  favStatus: boolean;
  favIcon: string;
  timeoutId: any;
  timeoutsave: any;
  showBlackLoader: boolean;

  @ViewChild("loaderButton", { read: ElementRef }) saveButton: ElementRef;
  @ViewChild("container", { read: ElementRef }) container: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private fb: FormBuilder,
    private dashboardService: DashboardAPIService,
    private learnMoreService: LearnMoreService,
    private appService: AppService,
    private cdRef: ChangeDetectorRef
  ) {
    this.timeSegmentList = this.learnMoreService.timeSegmentList;
    this.financeList = this.learnMoreService.financeList.filter(
      (finance) => finance.value !== "lease"
    );
    this.batteryForm = this.fb.group({
      hrs: [
        "10",
        [
          Validators.required,
          Validators.pattern(/^(?:[1-9]|[1-2][0-9]|3[0-5])$/),
          Validators.min(1),
          Validators.max(35),
        ],
      ],
      solar: ["no", Validators.required],
      finance: ["cash", Validators.required],
    });
  }

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.selectedSection = "aboutTechnology";
    this.setTimeSelector(this.dashboardService.timeFrame);
    this.dashboardService
      .faqDetails()
      .pipe(take(1))
      .subscribe((list: SectionListModel) => {
        this.FAQTabsList = list.battery.faqList!;
      });
    this.getBatteryViewDetails(this.selected);
    this.dashboardService
      .getMarketplaceDetails("battery")
      .pipe(take(1))
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails = response;
        this.adviceCardStatus = response.expertAdvice;
        this.displayMarketPlaceBox = this.marketPlaceDetails.displayBox;
        this.marketPlaceLink = this.marketPlaceDetails.buttonLink;
        if (this.marketPlaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketPlaceDetails.linkId;
        }
      });
  }

  setTimeSelector(val: TimeFrameEnum): void {
    this.selected = val;
    if (this.selected) {
      this.timeFrame = this.learnMoreService
        .renderTimeFrame(this.selected)
        .toLowerCase();
    }
  }

  getBatteryViewDetails(val: TimeFrameEnum): void {
    this.learnMoreService
      .learnMoreInfo<BatteryDetailsModel>("battery")
      .pipe(take(1))
      .subscribe((response: BatteryDetailsModel) => {
        this.haveResponse = true;
        this.batteryViewDetails = response;
        this.financialDetails = this.batteryViewDetails.financialDetails;
        this.technicalDetails = this.batteryViewDetails.technicalDetails;
        this.learnMoreDetails = this.batteryViewDetails.learnMoreDetails;
        this.assumptionDetails = this.batteryViewDetails.assumptionDetails;
        this.isLoadShift = this.financialDetails.batterySavings.loadShiftStatus;
        this.batCash = this.financialDetails.batteryCost.compareScreen.cash;
        this.batLoan = this.financialDetails.batteryCost.compareScreen.loan;
        this.showIncentivesStatus =
          this.financialDetails.batteryCost.showIncentives;
        this.favStatus = this.learnMoreDetails.favoriteStatus;
        this.totalIncentives = this.financialDetails.batteryCost.incentives;
        this.batteryFormDetails = this.learnMoreDetails;
        this.financeValue = this.learnMoreDetails.financial;
        this.paymentTypeLabel = this.dashboardService.getPaymentTypeLabel(
          this.financeValue
        );
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.showCash = this.financeValue === "cash";
        this.showLoan = this.financeValue === "loan";
        this.backupHourLabel = "Backup hours";
        this.backupHourToolTip =
          "Hours of backup you want from a fully charged battery. This assumes no recharge from rooftop solar and that usage during an outage is your average hourly usage throughout the year. This value will determine the estimated capacity and cost of the battery system.";
        this.savingsDetails = this.financialDetails.batterySavings;
        this.paybackPeriod = this.financialDetails.batteryPayback.paybackPeriod;
        this.displayPayback =
          this.financialDetails.batteryPayback.displayPayback;
        this.displayPaybackGTTwenty =
          this.financialDetails.batteryPayback.displayPaybackGTTwenty;
        if (this.displayPayback) {
          if (this.displayPaybackGTTwenty) {
            this.payBackYear = "greater than 20 years";
          } else {
            this.payBackYear = this.paybackPeriod + " years";
          }
        }
        this.yAxisLabel = "Electricity cost";
        this.yAxisLabel1 = "with " + "battery";
        this.updateChart(val);
        this.showCash = this.financeValue === "cash";
        this.showLoan = this.financeValue === "loan";
        this.showBlackLoader = this.loader = false;
      });
  }

  updateChart(val: TimeFrameEnum): void {
    let energyCost;
    let dataInputs;

    this.setTimeSelector(val);
    if (val === TimeFrameEnum.TwentyYear) {
      energyCost = this.savingsDetails.dollarSavings.withBattery.twentyYear;
      this.battery_Savings = this.savingsDetails.dollarSavings.total.twentyYear;
      dataInputs = [
        { y: energyCost, color: "#5BCADA" },
        {
          y: this.savingsDetails.dollarSavings.withoutBattery.twentyYear,
          color: "#414141",
        },
      ];
    }
    if (val === TimeFrameEnum.OneYear) {
      energyCost = this.savingsDetails.dollarSavings.withBattery.oneYear;
      this.battery_Savings = this.savingsDetails.dollarSavings.total.oneYear;
      dataInputs = [
        { y: energyCost, color: "#5BCADA" },
        {
          y: this.savingsDetails.dollarSavings.withoutBattery.oneYear,
          color: "#414141",
        },
      ];
    }
    if (val === TimeFrameEnum.TenYear) {
      energyCost = this.savingsDetails.dollarSavings.withBattery.tenYear;
      this.battery_Savings = this.savingsDetails.dollarSavings.total.tenYear;
      dataInputs = [
        { y: energyCost, color: "#5BCADA" },
        {
          y: this.savingsDetails.dollarSavings.withoutBattery.tenYear,
          color: "#414141",
        },
      ];
    }
    if (val === TimeFrameEnum.OneMonth) {
      energyCost = this.savingsDetails.dollarSavings.withBattery.oneMonth;
      this.battery_Savings = this.savingsDetails.dollarSavings.total.oneMonth;
      dataInputs = [
        { y: energyCost, color: "#5BCADA" },
        {
          y: this.savingsDetails.dollarSavings.withoutBattery.oneMonth,
          color: "#414141",
        },
      ];
    }
    if (this.isLoadShift) {
      this.timeoutId = setTimeout(() => {
        this.onUpdateChartOptions(
          dataInputs,
          this.yAxisLabel,
          this.yAxisLabel1
        );
      }, 600);
    }
  }

  onUpdateChartOptions(
    inputData: BarChartDataModel[],
    yAxisLabel,
    yAxisLabel1
  ): void {
    let filteredInputData: BarChartDataModel[] = [];
    let i = 0;
    for (let entry of inputData) {
      if (entry.y != 0) {
        filteredInputData[i] = entry;
        i++;
      }
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    this.chartOptions1 = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        labels: {
          style: {
            color: "#333333",
            fontSize: "16px",
            fontFamily: "Open sans",
          },
        },
        categories: [
          `${yAxisLabel} <br> ${yAxisLabel1}`,
          `Electricity cost <br> without battery`,
        ],
        gridLineWidth: 0,
        lineWidth: 1,
        tickWidth: 0,
      },
      yAxis: {
        tickAmount: 8,
        tickWidth: 0,
        gridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: null,
        },
        labels: {
          style: {
            color: "#333333",
            fontSize: "12px",
            fontFamily: "Open sans",
          },
          formatter: function (this) {
            if (Number(this.value) >= 1000) {
              return "$" + Number(this.value) / 1000 + "K";
            } else {
              return "$" + this.value;
            }
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          pointWidth: 50,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "Open sans",
            },
            formatter: function (this) {
              if (this.point.y && this.point.y < 1) {
                return "$0";
              } else {
                return (
                  "$" +
                  this.point.y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }
            },
            animation: {
              duration: 20,
            },
          },
        },
        series: {
          borderWidth: 0,
          animation: {
            duration: 1500,
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                className: "small-chart",
              },
            },
          },
        ],
      },
      series: [
        {
          type: "bar",
          showInLegend: false,
          data: filteredInputData,
        },
      ],
    };
    Highcharts.chart(this.container.nativeElement, this.chartOptions1);
  }

  handleActiveValue(data: string): void {
    if (data === "details") {
      this.isTechnicalExpanded = true;
      this.icon = true;
    } else if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon1 = true;
    }
  }

  expansionPanelClick(): void {
    this.icon = !this.icon;
  }

  faqExpansionClick(): void {
    this.icon1 = !this.icon1;
  }

  onSaveDetails(finance?: string): void {
    this.haveResponse = false;
    if (finance) {
      this.batteryForm.controls["finance"].setValue(finance);
    }
    if (
      this.learnMoreService.isFormEditted(
        this.batteryData.battery!,
        this.learnMoreDetails
      )
    ) {
      this.saveButton.nativeElement.innerHTML =
        'Saving... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
      this.dashboardService.dashboardDetailsAnalytics("save", "battery");
      this.learnMoreService
        .updateLearnMoreInfo("battery", this.batteryData)
        .pipe(take(1))
        .subscribe(() => {
          this.timeoutsave = setTimeout(() => {
            this.saveButton.nativeElement.innerHTML = "SAVE DETAILS";
            this.getBatteryViewDetails(this.selected);
          }, 800);
        });
    }
  }

  get isHoursOutOfRange(): boolean {
    return (
      this.batteryForm.controls["hrs"].hasError("pattern") ||
      this.batteryForm.controls["hrs"].hasError("min") ||
      this.batteryForm.controls["hrs"].hasError("max")
    );
  }

  get isHoursRequired(): boolean {
    return this.batteryForm.controls["hrs"].hasError("required");
  }

  get batteryData(): LearnMoreRequestModel {
    return {
      battery: {
        financial: this.batteryForm.controls["finance"].value,
        hrs: this.batteryForm.controls["hrs"].value,
      },
    };
  }

  set batteryFormDetails(details: BatteryLearnMoreModel) {
    this.batteryForm.controls["finance"].setValue(details.financial);
    this.batteryForm.controls["hrs"].setValue(this.learnMoreDetails.hrs);
  }

  handleFAQClick(): void {
    this.dashboardService.dashboardDetailsAnalytics("moreInfo", "battery");
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/battery-backup-faq-es/",
      "_blank"
    );
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  onReadContent(value: string) {
    if (value === "readMore") {
      this.showReadMore = false;
      this.showReadLess = true;
    } else if (value === "readLess") {
      this.showReadMore = true;
      this.showReadLess = false;
    }
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.showBlackLoader = true;
    this.overviewService
      .updateTechSettingInfo("battery", ownPlan)
      .pipe(take(1))
      .subscribe(() => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getBatteryViewDetails(this.selected);
        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardService.techMessage("battery", action);
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }
  handleSelectionChange(value: string): void {
    // Convert string to TimeFrameEnum if necessary
    this.selected = TimeFrameEnum[value as keyof TimeFrameEnum];
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
    clearTimeout(this.timeoutsave);
  }
}
