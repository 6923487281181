import { RestAPIService } from "./../rest-api/rest-api.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router, Params } from "@angular/router";
import { take } from "rxjs/operators";

@Component({
  selector: "yt-pge-auth",
  templateUrl: "pge-auth.component.html",
  styleUrls: ["pge-auth.component.scss"],
})
export class PGEAuthComponent implements OnInit {
  solarVendorName;
  constructor(
    private activatedRoute: ActivatedRoute,
    private restAPIService: RestAPIService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(take(1))
      .subscribe((params: Params) => {
        this.saveAuthCode(params);
      });
  }

  saveAuthCode(data) {
    let authCode = {
      oAuth: {
        code: data.code,
        scope: data.scope,
        dataCustodianId: data.dataCustodianId.replace(";", ""),
      },
    };

    let states: string[] = data.state.split(";");
    let email = states[0];
    let solarVendorCode = states[1];
    let buildingType = states[2];
    let dbu = "";

    if (states.length > 3) {
      dbu = states[3];
    }
    this.getSolarVendorDescription(solarVendorCode);

    this.restAPIService.setSolarVendorInformation({
      code: solarVendorCode,
      description: this.solarVendorName,
      logo: null,
      buildingTypes: null,
      roofTypes: null,
      email: email,
    });

    this.restAPIService.setSolarVendorDetails({
      code: solarVendorCode,
      description: this.solarVendorName,
      logo: null,
      buildingTypes: null,
      roofTypes: null,
    });

    this.restAPIService.setSolarVendorDescription(this.solarVendorName);

    if (dbu === "dbu") {
      this.restAPIService.userFromDashboard = true;
    }

    this.restAPIService.buildingType = buildingType;
    this.restAPIService.homeOwnerEmail = email.toLowerCase();
    this.restAPIService.utilityProvider = "PGE";
    this.restAPIService.postAuthCode(authCode, email);
    this.router.navigateByUrl("/sign-up/thank-you");
  }

  getSolarVendorDescription(vendorCode) {
    switch (vendorCode) {
      case "SUNW":
        this.solarVendorName = "SunWork";
        break;

      case "SKYT":
        this.solarVendorName = "Skytech Solar";
        break;

      case "YESL":
        this.solarVendorName = "YourEnergySolutions";
        break;

      case "FRSO":
        this.solarVendorName = "FRSO";
        break;

      default:
        this.solarVendorName = "YellowTin";
    }
  }
}
