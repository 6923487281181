import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "yt-learn-more",
  templateUrl: "learn-more.component.html",
})
export class LearnMoreComponent implements OnInit, OnDestroy {
  dashboardElement: HTMLSpanElement;
  oldFontWeight: string;

  ngOnInit(): void {
    this.dashboardElement = document.getElementById("myDashboard")!;
    if (this.dashboardElement) {
      this.oldFontWeight = this.dashboardElement.style.fontWeight;
      this.dashboardElement.style.fontWeight = "bold";
    }
  }

  ngOnDestroy(): void {
    this.dashboardElement.style.fontWeight = this.oldFontWeight;
  }
}
